import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

import { CHROME_STORE_URL } from '../config';
import clsx from 'clsx';


const questions = [
  {
    question: 'Why is the VAT not included in the price?',
    response: (
      <p>
        VAT is not included because it varies from country to country. For example – Germany has a 19% VAT, while
        France has a 20% VAT.
      </p>
    ),
  },
  {
    question: 'I accidentally made a purchase. Can I get a refund?',
    response: (
      <p>
        Sure, we all make mistakes sometimes, right? Message <b>contact@meetlist.io</b> and we’ll review the case which will
        result in a complete refund most of the time.
      </p>
    ),
  },
  {
    question: 'Does Meet List work on mobile?',
    response: 'No. It was designed to work as a browser extension.',
  },
  {
    question: 'What about browser support?',
    response: (
      <p>
        Meet List is available for Chrome or any other Chromium based browsers. You can get it from the {' '}
        <a
          className="flat"
          href={CHROME_STORE_URL}
          target="_blank"
          rel="noreferrer"
        >
          Google Chrome Store
        </a>.
      </p>
    ),
  },
  {
    question: 'What are your supported languages?',
    response: (
      <p>
        The Premium dashboard we currently have English and we're working on other languages as we speak. Our browser
        extension <u>Google Meet Attendance List</u> has support for English, and Spanish languages.
      </p>
    ),
  },
  {
    question: 'How can I get in contact?',
    response: (
      <p>
        You can always use the <b>Feedback</b> form located on the sidebar or just send an email
        to <u>contact@meetlist.io</u>
      </p>
    )
  }
];

export default function FAQ({ className }) {
  return (
    <section
      id="faq"
      className={clsx(
        'container mx-auto px-4 py-16',
        className,
      )}
    >
      <h2 className="text-center text-2xl mb-5">
        Frequently asked questions
      </h2>
      <div className="mx-auto w-full max-w-xl rounded-2xl bg-white p-2">
        {
          questions.map((item, index) => (
            <Disclosure  as="div" className="mt-2" key={'faq-' + index}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span>{ item.question }</span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    { item.response }
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))
        }
      </div>
    </section>
  )
}
