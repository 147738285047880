/**
 * Monochromatic Color Scheme
 * @type {string[]}
 */
// export const CHART_COLORS = [
//   '#2c72f5',
//   '#668ef7',
//   '#99a9f9',
//   '#ccd4fb',
//   '#eef0fd',
// ];

/**
 * Coolors Palette:
 * https://coolors.co/palette/00487c-4bb3fd-3e6680-0496ff-027bce
 * @type {string[]}
 */
export const CHART_COLORS = [
  '#2C72F5', // '#00487c',
  '#4bb3fd',
  '#0496ff',
  '#3e6680',
  '#027bce',
  '#DF2935'
]
