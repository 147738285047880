import * as Sentry from '@sentry/react';
import logger from './logger';
import { setInProgressMeetings, setUnsyncMeetings } from '../meetings/actions';

let _isListening = false;


/**
 * Get the extension ID from the root element. This is set by the extension
 * when it is installed.
 *
 * @returns {string}
 */
export function getExtId() {
  const root = document.documentElement;
  let extId = root.dataset.gmalId;

  // TODO: remove this after the extension with root DOM is released and used by everyone
  if (!extId) {
    try {
      extId = sessionStorage.getItem('gmal_id');
    } catch (e) {
      // no op
    }
  }

  return extId;
}


/**
 * Get the extension version from the root element. This is set by the extension
 * when it is installed.
 *
 * @returns {string}
 */
export function getExtVersion() {
  const root = document.documentElement;
  return root.dataset.gmalVersion;
}


/**
 * Send a message to the extension.
 *
 * @param eventName
 * @param payload
 * @returns {(function(*): Promise<boolean|undefined>)|*}
 */
export const sendMessageAsync = (eventName, payload) => {
  return async (dispatch) => {
    const extId = getExtId();

    if (!extId) {
      console.warn('Extension is not installed!');
      return false;
    }

    if (!_isListening) {
      addExtListener(dispatch);
    }

    window.postMessage({
      source: 'ui',
      type: eventName,
      payload,
    }, process.env.REACT_APP_WEB_BASE_URL);
  }
};


function chromeSendMessage(extId, payload) {
  // TODO: remove callback and use async/await when browser supports
  // current issues with Chrome 109 and others, see sentry
  return new Promise((resolve, reject) => {
    window.chrome.runtime.sendMessage(extId, payload, (response) => {
      if (window.chrome.runtime.lastError) {
        reject(new Error(window.chrome.runtime.lastError.message));
      } else {
        resolve(response);
      }
    });
  });
}


/**
 * Send RPC message to the extension.
 *
 * @param {string} method - RPC method name
 * @param {object} [params] - RPC payload
 * @returns {Promise<boolean|*>}
 */
export async function sendExtRPCMessage(method, params = null) {
  logger.debug('[sendExtRPCMessage] request', method, params);
  const extId = getExtId();
  const extVersion = getExtVersion();

  // If the extension is adding the version and ID on the root DOM element,
  // it means it supports the RPC communication protocol
  if (!extVersion) {
    logger.warn('Extension is not installed!');
    return false;
  }

  let extRPCResponse;
  try {
    extRPCResponse = await chromeSendMessage(extId, {
      id: Date.now(),
      method,
      params,
    });
  } catch (e) {
    logger.error('[sendExtRPCMessage] Connection error', e);
    return false;
  }

  if (!extRPCResponse) {
    logger.warn('[sendExtRPCMessage] No response received from the extension.');
    Sentry.captureMessage('No response received from the extension.', {
      tags: {
        rpc_method: method,
        ext_version: extVersion,
      },
      extra: {
        extId,
        params,
      }
    });
    return false;
  }

  if (extRPCResponse?.error) {
    logger.error('[sendExtRPCMessage] Error', extRPCResponse);
    return false;
  }

  logger.debug('[sendExtRPCMessage] response', method, extRPCResponse);
  return extRPCResponse?.result;
}


/**
 * Add a listener for messages from the extension.
 *
 * @param dispatch
 */
export const addExtListener = (dispatch) => {
  window.addEventListener('message', (event) => {
    if (
      !event?.isTrusted
      || event?.origin !== process.env.REACT_APP_WEB_BASE_URL
      || event?.source !== window
      || !event?.data?.type
      || event?.data?.source !== 'ext'
    ) {
      // console.debug('[ext] Ignoring message from the following domain', event.origin, event.data);
      return false;
    }

    const eventName = event.data.type;

    switch (eventName) {
      case 'ui.ready':
        // will receive meetings.getUnsyncedWithServer
        return dispatch(setUnsyncMeetings(event.data.payload));

      case 'meetings.getInProgress':
        return dispatch(setInProgressMeetings(event.data.payload));

      case 'meetings.delete':
        return true;

      default:
        console.warn('[ext] Unknown message received:', eventName, event);
        return;
    }
  });
  _isListening = true;
}
