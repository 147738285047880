import React from 'react';
import moment from 'moment/moment';


/**
 * @param {Date} label - The date to be formatted
 */
function TooltipHeader({ label }) {
  return (
    <p className="px-2 py-1 bg-gray-100 font-bold">
      { moment(label).format('ddd, MMM DD') }
    </p>
  );
}


/**
 * @param {Date} label - The date to be formatted
 * @param {{color: string, stroke: string, fill: string, name: any}} data
 * @param {Function} formatter - The function to format the value
 * @param {Function} labelFormatter - The function to format the label
 */
function TooltipRow({ label, data, formatter, labelFormatter }) {
  return (
    <div className="px-2 pt-1 flex items-center justify-between gap-6">
      <span className="text-gray-600">
        <span
          className="inline-flex w-2 h-2 rounded-full mr-1"
          style={{
            backgroundColor: data.color || data.stroke || data.fill
          }}
        />
        <span>
          {
            data.name !== 'value' ?
              (labelFormatter ? labelFormatter(data.name) : data.name) :
              moment(label).format('ddd, MMM DD')
          }
        </span>
      </span>
      <span>
        {
          formatter ?
            formatter(data.value) :
            data.value
        }
      </span>
    </div>
  );

}


/**
 * @param {boolean} active - The visibility of the tooltip
 * @param {array} payload - The data to be displayed
 * @param {Date} label - The date to be formatted
 * @param {Function} formatter - The function to format the value
 * @param {Function} labelFormatter - The function to format the label
 */
export function CustomTooltip({ active, payload, label, formatter, labelFormatter }) {
  if (!active && !payload?.length) {
    return null;
  }

  return (
    <div className="shadow-lg rounded bg-white pb-2 border border-gray-200 text-xs min-w-32">
      <TooltipHeader
        label={label}
        labelFormatter={labelFormatter}
      />
      {
        payload.map((p, index) => (
          <TooltipRow
            key={index}
            label={label}
            data={p}
            formatter={formatter}
            labelFormatter={labelFormatter}
          />
        ))
      }
    </div>
  );
}
