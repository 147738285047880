import React, { useEffect } from 'react';
import { formatPrice } from '../../components/common/PricingCards';
import { DEFAULT_OLD_PRICE, DEFAULT_PRICE } from '../../config';


export default function EmbedPremium() {
  const oldPrice = DEFAULT_OLD_PRICE;

  useEffect(() => {
    try {
      // setOldPrice(getPremiumPrice());
      window.parent.postMessage(DEFAULT_PRICE, '*');
    } catch (e) {
      console.warn('Unable to access cookies', e);
    }
  }, []);

  return (
    <div className="flex items-center justify-center space-x-3">
      <div className="flex items-baseline text-white">
        <span className="text-3xl font-semibold">€</span>
        <span className="text-5xl font-extrabold tracking-tight">
          { formatPrice(DEFAULT_PRICE) }
        </span>
        <span className="ml-1 text-xl font-normal">/month</span>
      </div>
      {
        oldPrice > DEFAULT_PRICE &&
        <div
          className="text-center text-black leading-tight px-2 py-2 rounded"
          style={{ backgroundColor: '#F5BD44' }}
        >
          <div className="font-extrabold">-{ Math.round(100 - DEFAULT_PRICE * 100 / oldPrice) }%</div>
          <div>
            Old price
            {' '}
            <span className="font-extrabold">
              €{ formatPrice(oldPrice) }
            </span>
          </div>
        </div>
      }
    </div>
  );
}
