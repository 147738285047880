import React from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Area, Bar, CartesianGrid, ComposedChart, Legend, Line,
  ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts';
import { CustomTooltip } from './CustomTooltip';
import moment from 'moment/moment';
import { CHART_COLORS } from './constants';
import { CustomLegend } from './CustomLegend';
import { CustomAxisTick } from './CustomAxisTick';
import DemoChart from './DemoChart';


export function CustomComposedChart({
  height = 130,
  debounce = 10,
  chartDataset,
  className,
  formatter,
  labelFormatter,
  tickFormatter,
  yAxis: yAxisProps = { hide: false },
  showGrid = true,
  showLegend = false,
  demoImageSrc = null,
  children,
}) {
  // show the demo chart if the demo image source is defined
  if (demoImageSrc) {
    return <DemoChart height={height} src={demoImageSrc} />;
  }

  // show loading if the chart dataset is not defined
  if (!chartDataset?.data) {
    return 'Loading';
  }

  const firstLabel = chartDataset.data[0].label;
  const lastLabel = chartDataset.data[chartDataset.data.length - 1].label;

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height={height}
        debounce={debounce}
      >
        <ComposedChart
          className={clsx(`border-b border-b-gray-100`, className)}
          data={chartDataset.data}
        >
          {
            chartDataset.config.map((chartConfig, index) => {
              switch (chartConfig.type) {
                case 'bar':
                  return (
                    <Bar
                      key={index}
                      dataKey={chartConfig.data_key}

                      // if defined bar chart will be grouped and stacked
                      stackId={chartConfig.stack_id}

                      // the color of the bar chart
                      fill={CHART_COLORS[index]}

                      // show the bar chart with min value even when the value is 0
                      minPointSize={2}
                    />
                  );

                case 'line':
                  return (
                    <Line
                      key={index}
                      type="monotone"
                      dataKey={chartConfig.data_key}
                      stroke={CHART_COLORS[index]}
                    />
                  )

                case 'area':
                  return (
                    <Area
                      key={index}
                      type="monotone"
                      dataKey={chartConfig.data_key}
                      fill={CHART_COLORS[index]}
                      stroke={CHART_COLORS[index]}
                    />
                  )

                default:
                  return null;
              }
            })
          }

          {/*
            The x-axis of the chart (HIDDEN),
            but is required so the label will be set in the tooltip
          */}
          <XAxis
            dataKey="label"
            type="category"
            // allowDuplicatedCategory={false}
            hide={true}
          />

          <YAxis
            width={40}
            orientation="right"
            // hide the tick line of the y-axis
            tickLine={false}
            axisLine={{ stroke: '#f3f4f6' }}
            interval="preserveStartEnd"
            tick={
              <CustomAxisTick
                formatter={tickFormatter}
              />
            }
            {...yAxisProps}
          />

          {
            // TBD
            showGrid && (
              <CartesianGrid
                horizontal={false}
                stroke="#f2f2f2"
              />
            )
          }

          {/* TBD */}
          <Tooltip
            content={
              <CustomTooltip
                formatter={formatter}
                labelFormatter={labelFormatter}
              />
            }
          />

          {
            // Show legend if needed
            showLegend && (
              <Legend
                align="left"
                verticalAlign="top"
                content={
                  <CustomLegend
                    formatter={labelFormatter}
                  />
                }
              />
            )
          }

          { children }
        </ComposedChart>
      </ResponsiveContainer>
      <div className="flex justify-between px-2 text-xs text-gray-500 mt-2 mr-12">
        <span>
          { moment(firstLabel).format('MMM DD') }
        </span>
        <span>
          { moment(lastLabel).format('MMM DD') }
        </span>
      </div>
      <div className="text-[10px] text-gray-500 text-right py-2">
        <Trans i18nKey="analytics.common.updated_yesterday">
          Updated yesterday
        </Trans>
      </div>
    </>
  );
}

CustomComposedChart.propTypes = {
  height: PropTypes.number,
  debounce: PropTypes.number,

  chartDataset: PropTypes.shape({
    // the data that will be rendered
    data: PropTypes.array.isRequired,

    // a list of chart configuration that will be rendered
    config: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        data_key: PropTypes.string.isRequired,
        stack_id: PropTypes.string,
      })
    ).isRequired,
  }),

  className: PropTypes.string,

  // show the grid of the chart
  showGrid: PropTypes.bool,

  // show the legend of the chart
  showLegend: PropTypes.bool,

  // the formatter for the value
  formatter: PropTypes.func,

  // the formatter for the label
  labelFormatter: PropTypes.func,

  // the props for the y-axis
  yAxis: PropTypes.shape({
    hide: PropTypes.bool,
    domain: PropTypes.array,
    scale: PropTypes.string,
    ticks: PropTypes.array,
  }),
};
