import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/16/solid';
import useConfirm from './common/ConfirmDialog';
import { debounce } from '../misc/utils';
import TinyWandersJPG from '../assets/imgs/tinywanders.jpeg';
// import BoloFormsPNG from '../assets/imgs/boloforms-logo.png';
// import SlowTvJPG from '../assets/imgs/slowtv.jpg';
import SkillShareJPG from '../assets/imgs/skillshare.jpg';
// import ChatPlaygroundJPG from '../assets/imgs/chat-playground.jpg';


const sidebarAds = [
  // {
  //   id: 'boloforms',
  //   company: 'BoloForms Proctor',
  //   description: 'The most affordable online test taking and proctoring solution.',
  //   cta: 'Try for free!',
  //   disclaimer: '',
  //   link: 'https://www.boloforms.com/timer-classroom/?linkId=lp_209539&sourceId=meetlist&tenantId=boloforms-proctor&affS1=sidebar',
  //   imageUrl: BoloFormsPNG,
  //   pixels: [],
  //   // campaignStart: '2024-05-19T20:59:59Z', // ISO format UTC time
  //   // campaignEnd: '2024-05-23T20:59:59Z', // ISO format UTC time
  // },
  {
    id: 'skillshare',
    company: 'Skillshare',
    description: 'Explore your creativity with thousands of hands-on classes.',
    cta: 'Join Skillshare today and get 30% off',
    disclaimer: 'Sponsored',
    link: 'https://skillshare.eqcm.net/c/4920536/1020152/4650',
    imageUrl: SkillShareJPG,
    pixels: ['https://skillshare.eqcm.net/i/4920536/1020152/4650'],
  },
];

const bottomAds = [
  // {
  //   id: 'boloforms',
  //   company: 'BoloForms Proctor',
  //   description: 'The most affordable online test taking and proctoring solution.',
  //   cta: 'Try for free!',
  //   disclaimer: '',
  //   link: 'https://www.boloforms.com/timer-classroom/?linkId=lp_209539&sourceId=meetlist&tenantId=boloforms-proctor&affS1=sticky',
  //   imageUrl: BoloFormsPNG,
  //   pixels: [],
  // },
  {
    id: 'skillshare',
    company: 'Skillshare',
    description: 'Explore your creativity with thousands of hands-on classes.',
    cta: 'Join Skillshare today and get 30% off',
    disclaimer: 'Sponsored',
    link: 'https://skillshare.eqcm.net/c/4920536/1020152/4650',
    imageUrl: SkillShareJPG,
    pixels: ['https://skillshare.eqcm.net/i/4920536/1020152/4650'],
  },
  {
    id: 'tiny-wanders',
    company: 'Tiny Wanders',
    description: 'Calm your mind and wind down before you go to sleep. Take a deep breath in, now exhale.',
    cta: 'Try it now',
    disclaimer: '',
    // link: 'https://apps.apple.com/app/apple-store/id1542537461?pt=122389727&ct=meetlist&mt=8',
    link: 'https://tinywanders.com/?ref=meetlist',
    imageUrl: TinyWandersJPG,
    pixels: [],
  },
  // {
  //   id: 'slow-tv',
  //   company: 'Slow TV',
  //   description: 'Enjoy a slow calm journey in nature landscapes all over the world.',
  //   cta: 'Watch now',
  //   disclaimer: '',
  //   link: 'https://apps.apple.com/app/apple-store/id1604860782?pt=122389727&ct=meetlist&mt=8',
  //   imageUrl: SlowTvJPG,
  //   pixels: [],
  // },
];


/**
 * Select an ad from the list of ads based on the zone and other filters
 * e.g. campaign start and end times
 *
 * @param {string} zone - The zone where the ad will be displayed
 * @param {string} [creativeId] - The creative ID to select a specific ad
 * @returns {{cta: string, pixels: *[], campaignStart: string, imageUrl: {}, link: string, campaignEnd: string, description: string, company: string, id: string}|{cta: string, pixels: *[], campaignStart: string, imageUrl: {}, link: string, campaignEnd: string, description: string, company: string, id: string}|{cta: string, pixels: [string], imageUrl: {}, link: string, description: string, company: string, id: string, disclaimer: string}}
 */
function selectAd(zone, creativeId = '') {
  const now = new Date();
  let validAds = zone === 'sidebar' ? sidebarAds : bottomAds;

  // Filter ads to include only those that are valid,
  // e.g. with valid campaignStart and campaignEnd times
  validAds = validAds.filter(ad => {
    // If creativeId is provided, only return the ad with that ID
    // ignore other filters
    if (creativeId && ad.id === creativeId) {
      return true;
    }

    if (ad.campaignEnd && !ad.campaignStart) {
      // Only campaignEnd exists, ad is valid if now is before or on campaignEnd
      const end = new Date(ad.campaignEnd);
      return now <= end;
    } else if (ad.campaignStart && !ad.campaignEnd) {
      // Only campaignStart exists, ad is valid if now is after or on campaignStart
      const start = new Date(ad.campaignStart);
      return now >= start;
    } else if (ad.campaignStart && ad.campaignEnd) {
      // Both campaignStart and campaignEnd exist, ad is valid if now is between them
      const start = new Date(ad.campaignStart);
      const end = new Date(ad.campaignEnd);
      return now >= start && now <= end;
    }

    return true;
  });

  if (zone === 'sidebar') {
    return validAds[0];
  }

  // choose a random ad from the validAds array
  const randomIndex = Math.floor(Math.random() * validAds.length);
  return validAds[randomIndex];
}


function AdSlot({ zone = 'sticky', className, imageClassName }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.userReducer.user);
  const confirm = useConfirm();
  const [creative, setCreative] = useState(null);

  useEffect(() => {
    const creativeId = searchParams.get('creativeId');
    if (!creative) {
      setCreative(selectAd(zone, creativeId));
    }
  }, [zone, creative, searchParams]);

  useEffect(() => {
    if (creative && !user?.is_paid) {
      // Load pixels when creative is available and user is not paid
      const loadPixelsDebounced = debounce(() => {
        for (const pixelUrl of creative.pixels) {
          const img = new Image();
          img.src = pixelUrl;
        }
      }, 300);

      // Call the debounced function
      loadPixelsDebounced();

      // Cleanup function to cancel the debounce on component unmount
      return () => {
        clearTimeout(loadPixelsDebounced);
      };
    }
  }, [creative, user]);

  const showUpsell = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const ok = await confirm({ type: 'upsell' });
    if (ok) {
      navigate('/billing');
    }
  };

  // if user is paid, don't show the ad
  if (!creative || user?.is_paid) {
    return null;
  }

  return (
    <div
      className={clsx(
        'bg-white rounded shadow-md border border-gray-200 px-2 py-3',
        'bottom-2 left-0 right-0 z-20',
        'flex justify-center overflow-hidden',
        zone === 'sticky' ? 'max-w-md mx-auto fixed' : 'absolute mx-2',
        className,
      )}
    >
      <a
        className="flex items-center gap-2 pr-2 text-gray-500 hover:animate-pulse"
        href={creative.link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          className={clsx(
            'rounded w-10 h-10',
            imageClassName,
          )}
          src={creative.imageUrl}
          alt={creative.company}
        />
        <div className="text-xs">
          <b className="text-black">{ creative.company }</b> - { creative.description }
          {' '}
          <span className="btn-flat">
            { creative.cta }
          </span>
        </div>
      </a>
      <button
        className="uppercase text-[6px] absolute px-1 py-1 top-0 right-0 text-gray-600 hover:text-black"
        onClick={showUpsell}
      >
        <XCircleIcon className="h-4 w-4" />
      </button>
    </div>
  );
}

export default memo(AdSlot);
