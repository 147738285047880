/**
 * Wrapper over localStorage.getItem that handles access error
 * @param key {string} name of the key
 * @returns {string}
 */
export function getItem(key) {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    console.warn('Unable to read from localStorage', key, e);
  }
}


/**
 * Wrapper over localStorage.setItem that handles access error
 * @param key {string} name of the key
 * @param value {string} value to be stored
 */
export function setItem(key, value) {
  try {
    return localStorage.setItem(key, value);
  } catch (e) {
    console.warn('Unable to write to localStorage', key, e);
  }
}
