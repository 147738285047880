import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import MiddleLoader from '../components/common/MiddleLoader';
import { authenticateUser } from '../users/actions';
import notify, { NotifyType } from '../misc/notify';


const AUTH_ERROR_MSG = 'Something went wrong while trying to login. Please try again later!';

export default function AuthCallbackPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isMounted = useRef(false);

  useEffect(() => {
    const code = searchParams.get('code');
    const scope = searchParams.get('scope');
    const error = searchParams.get('error');

    // If there is no code or there is an error, notify the user and redirect to login
    if (!code || error) {
      notify(AUTH_ERROR_MSG, NotifyType.ERROR);
      navigate('/login', { replace: true });
      return;
    }

    (async () => {
      // Note: after adding the translations, this useEffect will be triggered twice
      // don't know exactly why, but I've added this check to avoid the second call
      if (isMounted.current) {
        return;
      }

      isMounted.current = true;
      const authOk = await authenticateUser(code, scope);
      const state = searchParams.get('state');
      const nextPath = state ? decodeURIComponent(state) : '/meetings';

      if (authOk) {
        navigate(nextPath, { replace: true });
      } else {
        notify(AUTH_ERROR_MSG, NotifyType.ERROR);
        navigate('/login', { replace: true });
      }
    })();
  }, [navigate, searchParams]);

  return (
    <MiddleLoader />
  );
}
