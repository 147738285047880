import React from 'react';
import SEO from '../components/common/SEO';
import Menu from '../components/common/Menu';


export default function TermsPage() {
  return (
    <>
      <SEO
        title="Terms & Conditions | MeetList.io"
      />

      <Menu />

      <section className="bg-gray-100">
        <main className="relative flex flex-1 flex-col overflow-hidden py-8 px-4 sm:px-6 lg:px-8">
          <div className="relative flex flex-1 flex-col items-center">
            <div className="max-w-screen-md bg-white px-10 py-12 rounded shadow-md flex flex-col mt-5">
              <h1 className="pb-2">Terms & Conditions</h1>
              <small className="text-gray-500 italic mb-5">Last updated December 1, 2022</small>
              <p>
                <u>MeetList.io</u> operated by <b>Bluepixel Solutions SRL</b>. Bluepixel offers MeetList.io and related services to
                you, a user, under the condition that you accept all of our set terms, including these Terms & Conditions
                and all related policies.
              </p>

              <hr className="my-5" />

              <div className="text-sm">
                <h3>Definitions</h3>
                <p className="py-1">
                  (i) "User" or "You" means the individual or the business entity to which a license is granted by
                  MeetList for the use of the Software;
                </p>
                <p className="py-1">
                  (ii) "Effective Date" means the date on which a User first installs the Licensed Software through
                  MeetList’s website or other domains such as the Chrome Web Store, Microsoft Edge Add-ons website,
                  Firefox Add-ons website or others;
                </p>
                <p className="py-1">
                  (iii) “Licensed Software” means the web-based software extension called Google Meet Attendance List,
                  which is proprietary to MeetList;
                </p>
                <p className="py-1">
                  (iv) “Licensee Data” means all electronic data or information submitted by User to the Service;
                </p>
                <p className="py-1">
                  (v) “Service” means the provision of access to the Licensed Software for the purpose of transmitting
                  Licensee Data;
                </p>
                <p className="py-1">
                  (vi) “Usage Data” means data collected about the User’s use of the Service. For example, how often the
                  User accesses the dashboard, or what meetings they have.
                </p>

                <h3 className="mt-3">Agreement to terms</h3>
                <p className="py-2">
                  These Terms and Conditions constitute a legally binding agreement made between you, whether personally
                  or on behalf of an entity (“you”) and Bluepixel Solutions SRL ("Company", “we”, “us”, or “our”),
                  concerning your access to and use of the <u>contact@meetlist.io</u> website as well as any other media form,
                  media channel, mobile website or mobile application related, linked, or otherwise connected thereto
                  (collectively, the “Site”). Bluepixel Solutions SRL, is a private company, registered in Șoseaua
                  Virtuții 19d, office A, floor 4, Sector 6, Bucharest, Romania. Our VAT number is RO37904895.
                </p>
                <p className="py-2">
                  You agree that by accessing the Site, you have
                  read, understood, and agree to be bound by all of these Terms and Conditions. IF YOU DO NOT AGREE WITH
                  ALL OF THESE TERMS AND CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
                  DISCONTINUE USE IMMEDIATELY.
                </p>

                <p className="py-2">
                  Supplemental terms and conditions or documents that may be posted on the Site from time to time are
                  hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to
                  make changes or modifications to these Terms and Conditions from time to time. We will alert you about
                  any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right
                  to receive specific notice of each such change. Please ensure that you check the applicable Terms
                  every time you use our Site so that you understand which Terms apply. You will be subject to, and will
                  be deemed to have been made aware of and to have accepted, the changes in any revised Terms and
                  Conditions by your continued use of the Site after the date such revised Terms and Conditions are
                  posted.
                </p>

                <p className="py-2">
                  The information provided on the Site is not intended for distribution to or use by any person or entity
                  in any jurisdiction or country where such distribution or use would be contrary to law or regulation or
                  which would subject us to any registration requirement within such jurisdiction or country. Accordingly,
                  those persons who choose to access the Site from other locations do so on their own initiative and are
                  solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                </p>

                <p className="py-2">
                  The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not
                  permitted to use.
                </p>

                <h3 className="mt-3">Service Availability and Quality</h3>
                <p className="py-2">
                  We are constantly evolving the Services in order to make them better for you. The Services are subject to
                  modification and change, including but not limited to the design, the algorithms used to
                  generate the attendance, and features available to the User. No guarantees are made with respect to
                  the Services’ quality, stability, uptime or reliability. Please do not create any dependencies on any
                  attributes of the Services. We will not be liable to you or your downstream customers
                  for any harm caused by your dependency on the Service.
                </p>

                <h3 className="mt-3">Limitation of Liability</h3>
                <p className="py-2">
                  The Service is provided to User on an "AS IS" BASIS, WITHOUT WARRANTIES OR
                  CONDITIONS OF ANY KIND, either express or implied, including, without limitation, any warranties or
                  conditions of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE. You are
                  solely responsible for determining the appropriateness of using and assume
                  any risks associated with use of the Service.
                </p>

                <p className="py-2">
                  MeetList reserves the right to suspend or ban your access to the Service at any time, and for any reason. You
                  may not use the Service for competitive research. You may not reverse engineer the Service. You may
                  not use automated tools to access or interact with the Service. Only one user may use the Service per registered account.
                </p>

                <p className="py-2">
                  To the greatest extent permitted by applicable law, in no event shall MeetList be liable to the User or
                  any other person for any direct, indirect, incidental, special, punitive, exemplary or consequential
                  damages whatsoever, including without limitation, legal expenses, loss of business, loss of profits,
                  loss of revenue, lost or damaged data, loss of computer time, cost of substitute goods or services, or
                  failure to realize expected savings or any other commercial or economic losses arising out of or in
                  connection with this agreement, even if MeetList has been advised of the possibility of such loss or
                  damages, or such losses or damages are foreseeable. Our liability to you or any third parties in any
                  circumstance is limited to the greater of $10 or the amount of fees, if any, you paid to us in the six (6)
                  months prior to the action that may give rise to liability.
                </p>

                <h3 className="mt-3">Dispute Resolution and Governing Law</h3>
                <p className="py-2">
                  ALL LEGAL CLAIMS ARISING OUT OF OR RELATING TO THIS AGREEMENT (INCLUDING ANY DISPUTE REGARDING THE
                  INTERPRETATION OR PERFORMANCE OF THE AGREEMENT) ("Dispute" and collectively, the “Disputes”) WILL BE
                  GOVERNED BY THE LAWS OF COUNTRY OF ROMANIA.
                </p>
                <p className="py-2">
                  The parties will try in good faith to settle any Dispute within 30 days after the Dispute arises. If
                  the Dispute is not resolved within 30 days, it shall be resolved by arbitration by Curtea de Arbitraj
                  Comercial Internațional.
                </p>

                <p className="py-2">
                  Each party will bear its own lawyer and expert fees and expenses, regardless of the arbitrator’s
                  final decision regarding the Dispute.
                </p>

                <h3 className="mt-3">Your information</h3>
                <p className="py-2">
                  By using the Service, you may provide MeetList with personal information like your email
                  address, name, public Google profile image, billing information (through Stripe), details about the
                  meetings that you enter and the participants. In case of purchasing a Premium license, the information
                  is upload to the Service and stored securely on our databases.
                </p>
                <p className="py-2">
                  We process your personal data in compliance with the European General Data Protection Regulation
                  (Regulation (EU) 2016/679) (“GDPR”), the applicable EU laws.
                </p>

                <h3 className="mt-3">Payment and Billing</h3>
                <p className="py-2">
                  You will have the opportunity to purchase products and services from MeetList.io. If you purchase a
                  subscription, it will automatically renew—and you will be charged—until you cancel.
                </p>
                <p className="py-2">
                  We may invoice you for your use of the Services through a third party payment service provider. The
                  third party service provider’s terms of service shall govern and supersede this Agreement in case of
                  conflict. You are free to cancel your plan at any time. We also reserve the right to terminate your
                  access to the Service for any reason, including for violation of the Community Guidelines or other
                  inappropriate use of the Service.
                </p>
                <p className="py-2">
                  Upon cancellation or termination, any outstanding usage fees will be
                  charged immediately. You will not be refunded for the current pay period, but you will stop being
                  charging after the current pay period has ended. Refunds requested within 14 days of purchase are
                  eligible for a full refund, please email us at <u>contact@meetlist.io</u>
                </p>
                <p className="py-2">
                  Our prices may be updated from time to time so please make sure that you check our current
                  Subscription Fees before placing your order. All payments shall be in EUR.
                </p>

                <h3 className="mt-3">Uninstalling the Software</h3>
                <p className="py-2">
                  Users can uninstall the Licensed Software at any time by following the process outlined in your
                  browser’s help documentation.
                </p>

                <h3 className="mt-3">Miscellaneous</h3>
                <p className="py-2">
                  <b>Force Majeure.</b> Neither party will be liable for failure or delay in performance to the extent caused
                  by circumstances beyond its reasonable control, including acts of God, natural disasters, terrorism,
                  riots, or war.
                </p>
                <p className="py-2">
                  <b>Severability.</b> If any provision of this Agreement is, or becomes, unenforceable, it will be severed from this
                  Agreement and the remainder of this Agreement will remain in full force and effect.
                </p>

                <h3 className="mt-3">Contact</h3>
                <p className="py-2">
                  In order to resolve a complaint regarding the Services or to receive further information regarding use
                  of the Services, please contact us at: <u>contact@meetlist.io</u>
                </p>
                <address className="mt-3">
                  Bluepixel Solutions SRL <br />
                  Șoseaua Virtuții 19d, office A, floor 4, Sector 6 <br/>
                  Bucharest, Romania. VAT RO37904895.
                </address>
              </div>
            </div>
          </div>
        </main>
      </section>
    </>
  );
}
