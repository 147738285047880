import React, { Fragment, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import DefaultProfile from '../../assets/imgs/default-profile.svg';
import { logout } from '../../users/actions';
import LanguageSelector from '../LanguageSelector';


function TopBar({ user, dispatch }) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [pictureUrl, setPictureUrl] = useState(DefaultProfile);

  // for some wierd reason the profile image retrieved from Google sometimes returns 403, so we need
  // to show a default image instead of a broken one.
  useEffect(() => {
    if (user?.picture_url) {
      setPictureUrl(user.picture_url);
    }
  }, [user]);

  return (
    <div className="fixed z-10 top-0 right-0 w-full h-16 bg-white border-b drop-shadow-sm print:hidden">
      <div
        className="relative h-full flex items-center justify-between space-x-4"
        style={{ marginLeft: 263 }}
      >
        <div className="ml-5 flex-1">
          {
            params.meetingLocalKey &&
            <button
              className="text-blue-1 transition-opacity hover:opacity-75 duration-300 ease-in-out whitespace-nowrap"
              onClick={() => {
                const params = {};
                if (location?.state?.meetingsPageNumber > 1) {
                  params.page = location.state.meetingsPageNumber;
                }
                if (location?.state?.searchText) {
                  params.search = location.state.searchText;
                }

                // compose query params and append them to path, if not empty
                const query = new URLSearchParams(params).toString();
                return navigate(`/meetings${query ? `?${query}` : ''}`);
              }}
            >
              <span aria-hidden="true">←</span> <Trans i18nKey="topbar.back">Go back</Trans>
            </button>
          }
        </div>
        <div className="inset-y-0 right-0 mr-5 flex items-center">
          <a
            href="/#faq"
            target="_blank"
            className="text-gray-1 mr-3"
          >
            <QuestionMarkCircleIcon className="w-7 h-7" />
          </a>

          <LanguageSelector />

          {/* divider */}
          <div className="w-px h-14 mx-5 bg-black bg-opacity-10" />

          {/* Top right UserProfile */}
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full items-center justify-between text-left rounded-md space-x-4 px-4 py-2 text-sm font-medium hover:bg-gray-100">
                <div className="w-12">
                  <img
                    className="rounded-full w-full"
                    src={pictureUrl}
                    alt="profile"
                    onError={() => {
                      setPictureUrl(DefaultProfile);
                    }}
                  />
                </div>
                <div>
                  <div className="text-black font-bold">{ user.name }</div>
                  <div className="text-sm text-gray-1">
                    { user.is_paid ? 'Pro' : 'Free' } Plan
                  </div>
                </div>
                <div className="rounded-full bg-gray-300 p-1 ml-2 -mr-1 text-black">
                  <ChevronDownIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/terms-and-conditions/"
                        target="_blank"
                        className={`
                          group flex w-full items-center rounded-md px-5 py-3 text-sm text-gray-900
                          ${active ? 'hover:bg-gray-100' : ''}
                        `}
                      >
                        <Trans i18nKey="topbar.terms">
                          Terms & Conditions
                        </Trans>
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/privacy-policy/"
                        target="_blank"
                        className={`
                          group flex w-full items-center rounded-md px-5 py-3 text-sm text-gray-900
                          ${active ? 'hover:bg-gray-100' : ''}
                        `}
                      >
                        <Trans i18nKey="topbar.privacy">
                          Privacy Policy
                        </Trans>
                      </a>
                    )}
                  </Menu.Item>
                </div>
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => { user.is_anonymous ? navigate('/login') : dispatch(logout()) }}
                        className={`
                          group flex w-full items-center rounded-md px-5 py-3 text-sm text-gray-900
                          ${active ? 'hover:bg-gray-100' : ''}
                        `}
                      >
                        {
                          user.is_anonymous ?
                            <Trans i18nKey="topbar.login">Log in</Trans> :
                            <Trans i18nKey="topbar.logout">Sign out</Trans>
                        }
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          {/* End UserProfile */}
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({
  user: state.userReducer.user,
}))(TopBar);
