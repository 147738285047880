import React from 'react';
import { Helmet } from 'react-helmet-async';


const defaultUrl = 'https://meetlist.io';
const defaultTitle = 'MeetList.io - The easiest way to save your google meet attendance list';
const defaultDescription = 'Your hub for taking attendances professionally and efficiently of your meetings, eliminating the hassle of surveys or name-calling, so you can get back to work.';
const defaultImage = `${defaultUrl}/social-banner2.png`;

export default function SEO({ title, description, image, url, noIndex = false }) {
  return (
    <Helmet>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      {/*<link rel="canonical" href={url || defaultUrl}/>*/}

      {
        noIndex ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )
      }

      {/* Open Graph Tags */}
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:image:alt" content="Auto attendance for Google Meet" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:site" content="@site"/>
      <meta name="twitter:creator" content="@handle"/>
    </Helmet>
  );
}
