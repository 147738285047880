import { createReducer } from '@reduxjs/toolkit';
import { SET_ANON_USER, SET_USER, SET_USER_PENDING, UPDATE_EXT_SETTINGS } from './actionTypes';

const initialState = {
  status: 'idle',
  authError: false,
  user: {},
  settings: {
    auto_save: true,
    open_meeting_tab: true,
    time_format: 'LT',
  }
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_USER_PENDING, (state) => {
      state.status = 'pending';
      state.authError = false;
    })
    .addCase(SET_USER, (state, action) => {
      const { payload, extPayload, authError } = action;
      state.status = 'complete';
      state.authError = !!authError;
      state.user = payload;
      state.settings = {
        ...initialState.settings,
        auto_save: extPayload?.auto_save,
        open_meeting_tab: extPayload?.open_meeting_tab,
        // hour24_format: extPayload?.hour24_format,
      };
    })
    .addCase(SET_ANON_USER, (state, action) => {
      const { extPayload, authError } = action;
      state.status = 'complete';
      state.authError = !!authError;
      state.user = {
        email: `${extPayload?.device_id}@anonymous`,
        name: 'Anonymous',
        is_paid: Boolean(extPayload?.api_key),
        is_anonymous: true,
        locale: navigator.language,
      };
      state.settings = {
        ...initialState.settings,
        auto_save: extPayload?.auto_save,
        open_meeting_tab: extPayload?.open_meeting_tab,
        // hour24_format: extPayload?.hour24_format,
      };
    })
    .addCase(UPDATE_EXT_SETTINGS, (state, action) => {
      const { payload } = action;
      state.settings = {
        ...state.settings,
        ...payload,
      };
    })
})

export default userReducer;
