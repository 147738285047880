
// Chrome Store URLs
export const CHROME_STORE_URL = 'https://chrome.google.com/webstore/detail/google-meet-attendance-li/appcnhiefcidclcdjeahgklghghihfok';
export const CHROME_STORE_REVIEWS_URL = `${CHROME_STORE_URL}/reviews`;

// G Forms
export const FEEDBACK_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSf2Xb-QLOtYpwJ3voiUHq8uxLHzGK0NCj_lWwPTNIwOdtvvwg/viewform?usp=sf_link';

// Pricing
export const DEFAULT_PRICE = 399;
export const DEFAULT_OLD_PRICE = 700;
export const DEFAULT_INTERVAL = 'monthly';

// Google OAuth2 URLs
export const GOOGLE_REDIRECT_URI = `${process.env.REACT_APP_WEB_BASE_URL}/auth/callback`;

// Sentry
export const SENTRY_DSN = 'https://89f63d46428cace24909aa1ec4f8785a@o4504151513694208.ingest.us.sentry.io/4506896632315904';

// When you run npm start, it is always equal to 'development', when you run npm test it is always equal to 'test',
// and when you run npm run build to make a production bundle, it is always equal to 'production'.
// !!! You cannot override NODE_ENV manually.
export const isProd = process.env.NODE_ENV === 'production';

// Max meeting duration in milliseconds, used to prevent meetings stuck in progress
// 8 hours in milliseconds
export const MAX_MEETING_DURATION_MS = 8 * 60 * 60 * 1000;

// Google Analytics
export const GA_MEASUREMENT_ID = 'G-NBDZE3DJMN';
