import { createContext, useCallback, useContext, useRef, useState } from 'react';
import Alert from './modals/Alert';
import Upsell from './modals/Upsell';

const ConfirmDialog = createContext({});

const MODAL_TYPES_TO_COMPONENTS = {
  default: Alert,
  success: Alert,
  error: Alert,
  upsell: Upsell,
}


export function ConfirmDialogProvider({ children }) {
  const [state, setState] = useState({ isOpen: false });
  const fn = useRef();

  const confirm = useCallback((data) => {
    return new Promise((resolve) => {
      setState({ ...data, isOpen: true });
      fn.current = (choice) => {
        resolve(choice);
        setState({ isOpen: false });
      }
    })
  }, [setState]);

  const ModalComponent = MODAL_TYPES_TO_COMPONENTS[state?.type] || MODAL_TYPES_TO_COMPONENTS.default;
  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      {
        state.isOpen ? (
          <ModalComponent
            {...state}
            onClose={() => fn.current(false)}
            onConfirm={() => fn.current(true)}
          />
        ) : null
      }
    </ConfirmDialog.Provider>
  )
}

export default function useConfirm() {
  return useContext(ConfirmDialog);
}
