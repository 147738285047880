import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getCurrentUser } from '../../users/actions';
import MiddleLoader from '../../components/common/MiddleLoader';
import notify, { NotifyType } from '../../misc/notify';


function RequireAuth({
  dispatch,
  status,
  user,
  authError,
  redirectTo = '/login',
  premiumRequired = false,
  children
}) {
  const location = useLocation();

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getCurrentUser());
    }

    if (authError) {
      notify('Something went wrong while trying to login. Please try again later!', NotifyType.ERROR);
    }
  }, [dispatch, status, authError]);

  if (status !== 'complete') {
    return <MiddleLoader />
  }

  if (status === 'complete' && (!user?.email || (premiumRequired && !user?.is_paid))) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }

  return children;
}

export default connect((state) => ({
  status: state.userReducer.status,
  user: state.userReducer.user,
  authError: state.userReducer.authError,
}))(RequireAuth);
