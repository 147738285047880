import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Cookies from 'universal-cookie';

import { DEFAULT_INTERVAL, DEFAULT_OLD_PRICE, DEFAULT_PRICE } from '../../config';


export const PlanTypes = {
  FREE: 1,
  PREMIUM: 2,
};

const features = [
  {
    name: 'Access to Chrome browser extension',
    i18nKey: 'billing_plan.features.browser_extension',
    plan: PlanTypes.FREE
  },
  {
    name: 'Automatically attendance recording',
    i18nKey: 'billing_plan.features.auto_attendance',
    plan: PlanTypes.FREE
  },
  {
    name: <span><b>Remove ads</b> and popups to enter a distraction-free zone.</span>,
    i18nKey: 'billing_plan.features.remove_ads',
    plan: PlanTypes.PREMIUM
  },
  {
    name: <span><b>Backup</b> unlimited meetings and participants</span>,
    i18nKey: 'billing_plan.features.backup',
    plan: PlanTypes.PREMIUM
  },
  {
    name: <span>Unlock <b>additional features</b> like search and analytics</span>,
    i18nKey: 'billing_plan.features.get_more',
    plan: PlanTypes.PREMIUM
  },
  {
    name: <span>Get <b>advanced export</b> options like XLSX, CSV and PDF</span>,
    i18nKey: 'billing_plan.features.advanced_export',
    plan: PlanTypes.PREMIUM
  },
  // {
  //   name: <span>Access history from <b>multiple devices</b></span>,
  //   plan: PlanTypes.PREMIUM
  // },
];

/**
 * Get old A/B test price from cookies or fallback on 5
 * @returns {*|number}
 */
export function getPremiumPrice() {
  let oldPrice;

  try {
    const cookies = new Cookies();
    oldPrice = cookies.get('ab_price', {doNotParse: false});
  } catch (e) {
    console.warn('Unable to access cookies', e);
  }

  return oldPrice > DEFAULT_PRICE ? oldPrice : DEFAULT_OLD_PRICE;
}


/**
 * Format price to string
 * e.g. 3 -> '3', 3.00 -> '3, 3.56754 -> '3.57'
 *
 * @param {number} price
 * @returns {string}
 */
export function formatPrice(price) {
  // Convert cents to euros
  price = price / 100;

  // If price is integer, return it as string
  if (Number.isInteger(price)) {
    return price.toString();
  }

  // If price has more than 2 decimal digits, round it to 2
  return price.toFixed(2);
}


/**
 *
 * @param planType {number}
 * @param price {number|string}
 * @param oldPrice {number}
 * @param interval {string}
 * @param features {array<string>}
 * @param className {string}
 * @param children {JSX.Element}
 * @returns {JSX.Element}
 * @constructor
 */
function BasePlanCard({
  planType = PlanTypes.FREE,
  price = '0',
  oldPrice = 0,
  interval = DEFAULT_INTERVAL,
  features = [],
  className = '',
  children
}) {
  return (
    <div className={`w-full max-w-sm p-4 border border-slate-700 rounded-lg shadow-md sm:p-8 ${className}`}>
      <h5 className="mb-4 text-xl font-medium">
        { planType === PlanTypes.FREE ? 'Free' : 'Pro' } plan
      </h5>
      <div className="flex items-center space-x-3">
        <div className="flex items-baseline">
          <span className="text-3xl font-semibold">€</span>
          <span className="text-5xl font-extrabold tracking-tight">
            { formatPrice(price) }
          </span>
          <span className="ml-1 text-xl font-normal">
            /<Trans i18nKey="billing_plan.per_month">month</Trans>{ planType === PlanTypes.PREMIUM && '*' }
          </span>
        </div>
        {
          planType === PlanTypes.PREMIUM && oldPrice > DEFAULT_PRICE &&
          <div
            className="text-center text-black leading-tight px-2 py-2 rounded"
            style={{ backgroundColor: '#F5BD44' }}
          >
            <div className="font-extrabold">
              -{ Math.round(100 - DEFAULT_PRICE * 100 / oldPrice) }%
            </div>
            <div>
              <Trans i18nKey="billing_plan.old_price">
                Old price
              </Trans>
              {' '}
              <span className="font-extrabold">
                €{ formatPrice(oldPrice) }
              </span>
            </div>
          </div>
        }
      </div>
      <div className={`my-1 text-md ${interval !== 'yearly' ? 'invisible' : ''}`}>
        billed annually
      </div>
      <ul className="space-y-4 my-2">
        {
          features.map((item, index) => (
            <li
              key={index}
              className={`flex space-x-3 ${item.plan > planType ? 'line-through' : ''}`}
            >
              <CheckCircleIcon
                className={`flex-shrink-0 w-5 h-5 ${ planType !== PlanTypes.PREMIUM && item.plan === PlanTypes.FREE ? 'text-blue-500' : '' }`}
                aria-hidden="true"
              />
              <span className="text-base font-normal leading-tight">
                <Trans i18nKey={item.i18nKey}>
                  { item.name }
                </Trans>
              </span>
            </li>
          ))
        }
      </ul>

      { children }
    </div>
  );
}

export function FreeCard({ className = '', children }) {
  return (
    <BasePlanCard
      className={className}
      planType={PlanTypes.FREE}
      price={0}
      features={features}
    >
      { children }
    </BasePlanCard>
  )
}


export function PremiumCard({className = '', interval = DEFAULT_INTERVAL, children}) {
  const [price, setPrice] = useState(DEFAULT_PRICE);

  useEffect(() => {
    // apply 2 months off, if yearly billed
    if (interval === 'yearly') {
      let newPrice = DEFAULT_PRICE * 10 / 12;
      setPrice(newPrice);
    } else {
      setPrice(DEFAULT_PRICE);
    }

    // Get old price from A/B test
    // setOldPrice(getPremiumPrice());
  }, [interval]);

  return (
    <BasePlanCard
      className={className}
      planType={PlanTypes.PREMIUM}
      price={price}
      oldPrice={DEFAULT_OLD_PRICE}
      interval={interval}
      features={features}
    >
      { children }
    </BasePlanCard>
  )
}
