import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';


export default function CTA({ className = '' }) {
  return (
    <section className={`bg-blue-600 ${className}`}>
      <div className="text-center text-white py-16">
        <h1 className="text-3xl max-w-3xl mx-auto py-2">
          {/*Are you doing your meeting attendance manually?*/}
          Automating your meeting attendance tracking has never been so easy.
        </h1>
        {/*<h2 className="text-3xl mb-2">*/}
        {/*  Get started today*/}
        {/*</h2>*/}
        <p className="text-gray-100">
          We take the work out of your meetings so you can accomplish more.
        </p>
        <Link
          to="/signup"
          className="primary inline-block px-10 py-3 bg-white text-black text-lg mt-10"
        >
          Get started for free
          <ArrowRightIcon className="w-5 h-5 ml-2 inline-block" aria-hidden="true" />
        </Link>
      </div>
    </section>
  );
}