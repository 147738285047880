import React from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import DemoChart from './DemoChart';


const COLORS = {
  'recurring': '#2C72F5',
  '1_1': '#4bb3fd',
  'overrun': '#DF2935',
  'unlabeled': '#3e6680',
}

function LegendItem({ label, value, percentage, color }) {
  return (
    <div
      className={clsx(
        'flex justify-between px-2 text-xs text-gray-500 py-2 rounded',
        'cursor-pointer transition-colors hover:bg-gray-100 hover:text-black'
      )}
    >
      <div className="flex gap-2 items-center">
        <div
          className={clsx('w-3 h-3 rounded')}
          style={{
            backgroundColor: color,
          }}
        />
        <Trans i18nKey={`analytics.charts.meetings_breakdown.${label}`} />
      </div>
      <span>
        <b>{ value }</b> ({ percentage }%)
      </span>
    </div>
  )
}

export function BreakdownBarChart({ chartDataset, className, demoImageSrc = null }) {
  let data = chartDataset?.data;

  // show the demo chart if the demo image source is defined
  if (demoImageSrc) {
    return <DemoChart src={demoImageSrc} />;
  }

  if (!data?.length) {
    return 'Loading';
  }

  const total = data.reduce((acc, { value }) => acc + value, 0);

  function getPercentage(value, total) {
    if (total === 0) {
      return 0; // To avoid division by zero
    }
    const percentage = (value / total * 100).toFixed(2);
    return percentage.endsWith('.00') ? parseInt(percentage) : parseFloat(percentage);
  }

  return (
    <div className={className}>
      <div className="flex gap-0.5 rounded overflow-hidden mb-2">
        {
          data.map((item, index) => {
            if (item.value === 0) {
              return null;
            }

            return (
              <div
                key={item.label}
                className="h-5"
                style={{
                  width: `${getPercentage(item.value, total)}%`,
                  backgroundColor: COLORS[item.label],
                }}
              />
            )
          })
        }
      </div>
      {
        data.map((item) => (
          <LegendItem
            key={item.label}
            label={item.label}
            color={COLORS[item.label]}
            value={item.value}
            percentage={getPercentage(item.value, total)}
          />
        ))
      }
      <div className="text-[10px] text-gray-500 text-right py-2">
        Updated yesterday
      </div>
    </div>
  );
}
