import { isProd } from '../config';


const logger = {
  debug: (...args) => {
    if (!isProd) {
      console.debug(...args);
    }
  },

  log: (...args) => {
    if (!isProd) {
      console.log(...args);
    }
  },

  warn: (...args) => {
    console.warn(...args);
  },

  error: (...args) => {
    console.error(...args);
  }
};

export default logger;
