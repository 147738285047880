import React, { useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { ArrowDownIcon } from '@heroicons/react/20/solid';
import MeetingRow from './MeetingRow';
import FooterPagination from './common/FooterPagination';


const MeetingsTable = ({ settings, meetings, activePage, page, selectedMeetingIds, setSelectedMeetingIds }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    // reset the indeterminate state if no meetings are selected
    if (inputRef.current && selectedMeetingIds.size === 0) {
      inputRef.current.indeterminate = false;
    }
  }, [selectedMeetingIds]);

  /**
   * Main switch for controlling all meeting checkboxes. Has 3 states: unchecked, indeterminate and checked
   * @param e
   * @returns {Promise<void>}
   */
  const handleSelectAll = async (e) => {
    const newSet = e.target.checked ? new Set(meetings.map(m => m.local_key)) : new Set();
    setSelectedMeetingIds(newSet);
  }

  if (meetings.length === 0) {
    return (
      <p>
        No meetings found.
      </p>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full border rounded shadow">
          <div className="overflow-hidden border-b">
            <table className="min-w-full divide-y">
              <thead className="bg-gray-100">
                <tr className="text-gray-500">
                  <th className="w-5 cursor-default">
                    <input
                      type="checkbox"
                      className="inline-block align-middle mx-2 my-2 w-4 h-4 rounded focus:ring-0 checked:bg-blue-500 indeterminate:bg-blue-500"
                      onClick={handleSelectAll}
                      ref={inputRef}
                    />
                  </th>
                  <th className="px-6 py-1 text-left text-xs font-medium uppercase tracking-wider w-1/3 max-w-xs">
                    <Trans i18nKey="meetings.table_columns.name">
                      Name
                    </Trans>
                  </th>
                  <th className="px-6 py-1 text-left text-xs font-medium uppercase tracking-wider">
                    <span className="align-middle">
                      <Trans i18nKey="meetings.table_columns.date">
                        Date
                      </Trans>
                    </span>
                    <ArrowDownIcon className="inline-block align-middle h-4 w-4" aria-hidden="true" />
                  </th>
                  <th className="px-6 py-1 text-left text-xs font-medium uppercase tracking-wider">
                    <Trans i18nKey="meetings.table_columns.attendees">
                      Attendees
                    </Trans>
                  </th>
                  <th className="px-6 py-1 text-left text-xs font-medium uppercase tracking-wider">
                    <div>
                      <Trans i18nKey="meetings.table_columns.duration">
                        Duration
                      </Trans>
                    </div>
                    <small>
                      <Trans i18nKey="meetings.table_columns.duration_format">
                        (hh:mm:ss)
                      </Trans>
                    </small>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {
                meetings.map((item) => (
                  <MeetingRow
                    key={item.local_key}
                    settings={settings}
                    meetingDetails={item}
                    activePage={activePage}
                    searchText={page.search}
                    isSelected={selectedMeetingIds.has(item.local_key)}
                    onSelected={(checked, localKey) => {
                      const newSet = new Set(selectedMeetingIds);
                      checked ? newSet.add(localKey) : newSet.delete(localKey);
                      setSelectedMeetingIds(newSet);

                      // reset the indeterminate state if no meetings are selected
                      inputRef.current.indeterminate = newSet.size > 0 && newSet.size !== meetings.length;
                    }}
                  />
                ))
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <FooterPagination
        className="mb-10"
        total={page.count}
        pageSize={page.limit}
        activePage={activePage}
        search={page.search}
      />
    </div>
  );
};

export default MeetingsTable;
