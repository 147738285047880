import React from 'react';
import { Trans } from 'react-i18next';
import Tippy from '@tippyjs/react/headless';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Badge from '../../common/Badge';


function ChartHeader({ children }) {
  return (
    <div className="text-gray-500">
      { children }
    </div>
  );
}


function Title({ i18nKey, defaultText, children }) {
  return (
    <div className="flex items-center gap-2 text-gray-500">
      <Trans i18nKey={i18nKey}>
        { defaultText }
      </Trans>

      {
        children && (
          <Tippy
            placement="right-start"
            render={(attrs) => (
              <div
                tabIndex="-1"
                className="bg-white px-3 py-3 rounded shadow-lg border border-gray-200 text-xs text-gray-500 w-64 z-50"
                {...attrs}
              >
                { children }
              </div>
            )}
          >
            <button className="focus:outline-none">
              <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Tippy>
        )
      }
    </div>
  );
}


function CurrentValue({ value, growthPercentage, isDemo = false }) {
  if (isDemo) {
    return null;
  }

  return (
    <div className="flex items-center mt-2">
      <span className="text-black font-bold text-xl mr-2">
        { value }
      </span>
      {
        typeof growthPercentage !== 'undefined' && (
          <Badge
            className="bg-green-200 text-green-800"
            defaultText={`${growthPercentage > 0 && '+'}${growthPercentage}%`}
          />
        )
      }
    </div>
  );
}


ChartHeader.Title = Title;
ChartHeader.CurrentValue = CurrentValue;
export { ChartHeader };
