import { toast } from 'react-toastify';


export const NotifyType = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const notify = (message, type = NotifyType.SUCCESS) =>  (toast[type] || toast)(message);

export default notify;
