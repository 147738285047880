import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import PublicLayout from './common/PublicLayout';
import BlogSidebar from '../components/BlogSidebar';
import MiddleLoader from '../components/common/MiddleLoader';
import SEO from '../components/common/SEO';


function BlogItem({ title, path, date, imageUrl }) {
  return (
    <div className="">
      <Link to={path}>
        <div
          className="p-4 rounded bg-gray-100 h-64 w-full overflow-hidden bg-cover	bg-center bg-no-repeat"
          style={{
            backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
          }}
        />
      </Link>
      <Link
        to={path}
        className="hover:text-blue-500"
      >
        <h2 className="text-2xl mt-3 mb-1">
          { title }
        </h2>
      </Link>
      <p className="text-gray-500">
        { moment(date).format('LL') }
      </p>
    </div>
  );
}

export default function BlogPage() {
  const { tagName } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const module = await import('../blog-posts.json');
        const filteredPosts = [...module.default].filter((post) => {
          // if no tagName is provided, return all posts
          if (!tagName || tagName === 'all') {
            return true;
          }

          // Check if post.keywords is not undefined and contains tagName
          if (post.keywords && post.keywords.indexOf(tagName) !== -1) {
            return true;
          }

          return false;
        });
        setPosts(filteredPosts);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [tagName]);

  if (loading) {
    return <MiddleLoader />;
  }

  return (
    <PublicLayout className="my-16">
      <SEO
        title={
          tagName ?
            `MeetList.io Blog - Articles on ${tagName}`
            : 'MeetList.io Blog - Insights and Updates'
        }
        description={
          tagName
            ? `Read the latest articles and updates about ${tagName} on the MeetList.io Blog. Stay informed and up-to-date with our expert insights.`
            : 'Explore the latest articles, updates, and insights from MeetList.io. Stay informed about virtual meeting management trends and tips.'
        }
      />
      <section className="container mx-auto px-2">
        <div className="sm:flex gap-5">
          <BlogSidebar
            tagName={tagName}
            className="flex-none max-w-full sm:max-w-64 mb-10"
          />
          <div className="flex-1">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
              {
                posts.length === 0 && (
                  <p className="text-gray-500">
                    No posts found.
                  </p>
                )
              }
              {
                posts.map((post, index) => (
                  <BlogItem key={index} {...post} />
                ))
              }
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
}
