import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useConfirm from '../components/common/ConfirmDialog';
import { createPaymentSession, createPortalSession } from '../billing/actions';
import Button from '../components/common/Button';
import { PremiumCard } from '../components/common/PricingCards';
import PriceIntervalSelection from '../components/common/PriceIntervalSelection';
import { DEFAULT_INTERVAL, DEFAULT_PRICE } from '../config';
import SEO from '../components/common/SEO';


function BillingPage({ user }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [searchParams] = useSearchParams();
  const [processingPayment, setProcessingPayment] = useState(false);
  const [processingManage, setProcessingManage] = useState(false);
  const [interval, setInterval] = useState(DEFAULT_INTERVAL);

  useEffect(() => {
    const load = async () => {
      if (searchParams.get('success')) {
        const ok = await confirm({
          type: 'success',
          title: (
            <Trans i18nKey="billing.success.title">
              Payment successful
            </Trans>
          ),
          description: (
            <Trans i18nKey="billing.success.description">
              Your payment has been successfully submitted. We’ve sent you an email with all of
              the details of your order.
            </Trans>
          ),
          confirmBtnLabel: (
            <Trans i18nKey="billing.success.cta_button">
              Go to meetings
            </Trans>
          ),
          showCancelBtn: false,
        });

        if (ok) {
          navigate('/meetings');
        }

      } else if (searchParams.get('canceled')) {
        await confirm({
          type: 'error',
          title: (
            <Trans i18nKey="billing.failed.title">
              Payment failed
            </Trans>
          ),
          description: (
            <Trans i18nKey="billing.failed.description">
              Your payment didn't go through. Please use a different payment method or try again later.
            </Trans>
          ),
          confirmBtnLabel: (
            <Trans i18nKey="billing.failed.cta_button">
              Ok, got it!
            </Trans>
          ),
          showCancelBtn: false,
        });
      }
    }
    load();
  }, [confirm, navigate, searchParams]);

  const handleGetPremium = async () => {
    setProcessingPayment(true);

    // If the user is not logged in, redirect to login page.
    if (user.is_anonymous) {
      navigate(`/login?next=${encodeURIComponent('/billing')}`);
      return;
    }

    const paymentURL = await createPaymentSession(DEFAULT_PRICE);
    if (paymentURL) {
      window.location.href = paymentURL;
    }
    setProcessingPayment(false);
  };

  const handleManageSubscription = async () => {
    setProcessingManage(true);
    const portalURL = await createPortalSession();
    if (portalURL) {
      window.location.href = portalURL;
    }
    setProcessingManage(false);
  }

  if (user.is_paid) {
    const managedByEmail = user.managed_by_email;
    return (
      <>
        <SEO
          title={`${t('billing.title')} - ${t('billing.pricing.title')} | MeetList.io`}
        />
        <h2 className="mb-2">
          <Trans i18nKey="billing.title">
            Billing
          </Trans>
        </h2>
        {
          managedByEmail ? (
            <p className="text-gray-500 max-w-screen-md">
              <Trans i18nKey="billing.managed_by_email">
                Your subscription is managed by someone else from your team. Please contact them for any billing
                modifications at <i className="text-gray-700">{{ managedByEmail }}</i>
              </Trans>
            </p>
          ) :
          (
            <>
              <p className="text-gray-500">
                <Trans i18nKey="billing.subtitle">
                  Manage your subscription and billing details.
                </Trans>
              </p>
              <Button
                className="primary mt-5"
                onClick={handleManageSubscription}
                isProcessing={processingManage}
              >
                <Trans i18nKey="billing.manage_button">
                  Manage Subscription
                </Trans>
              </Button>
            </>
          )
      }
      </>
    );
  }

  return (
    <div className="mx-auto text-black">
      <SEO
        title={`${t('billing.title')} - ${t('billing.pricing.title')} | MeetList.io`}
      />
      <h2 className="text-3xl text-center mt-5 mb-2">
        <Trans i18nKey="billing.pricing.title">
          Simple pricing, for everyone
        </Trans>
      </h2>
      <p className="text-center text-gray-500 mb-10">
        <Trans i18nKey="billing.pricing.subtitle">
          Choose a plan tailored to your needs.
        </Trans>
      </p>

      <PriceIntervalSelection
        onChange={(newInterval) => setInterval(newInterval)}
      />

      <div className="flex flex-wrap-reverse lg:flex-wrap gap-10 justify-center">
        <PremiumCard
          className="bg-blue-600 text-white"
          interval={interval}
        >
          <Button
            className="primary w-full justify-center inline-flex px-10 py-3 bg-white text-blue-600 text-lg mt-10"
            onClick={handleGetPremium}
            isProcessing={processingPayment}
          >
            <Trans i18nKey="billing.pricing.cta_button">
              Choose plan
            </Trans>
          </Button>
        </PremiumCard>
      </div>
      <div className="text-center text-gray-500 py-5">
        <Trans i18nKey="billing.pricing.vat_notice">
          * VAT may apply to the listed prices.
        </Trans>
      </div>
    </div>
  );
}

export default connect((state) => ({
  user: state.userReducer.user,
}))(BillingPage);
