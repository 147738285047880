import React from 'react';
import { SortOrder } from './index';
import SortIndicator from './SortIndicator';


export default function SortButton({ sortProperty, sortOrder = SortOrder.NONE, onSortChange, children }) {
  return (
    <button
      className="inline-flex justify-center items-center px-3 py-2 rounded-md hover:bg-gray-200 hover:text-black focus:outline-none"
      onClick={() => {
        const nextSortOrder = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange(sortProperty, nextSortOrder);
      }}
    >
      <div className="font-medium uppercase tracking-wider text-center mr-1">
        { children }
      </div>
      <SortIndicator order={sortOrder} />
    </button>
  );
}
