import moment from 'moment/moment';


export const tickFormatter = (value) => {
  const duration = moment.duration(value * 1000);

  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (hours) {
    return `${Math.round(duration.asHours())} h`;
  } else if (minutes) {
    return `${Math.round(duration.asMinutes())} min`;
  }

  return `${seconds} sec`;
}
