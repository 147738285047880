import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import { SortOrder, SortProperty } from './index';
import SortIndicator from './SortIndicator';
import ProFeatureAction from '../common/ProFeatureAction';


export default function SortByNameButton({ sortOrder = SortOrder.NONE, onSortChange }) {
  const nextSortOrder = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
  return (
    <Menu as="div" className={clsx(`relative inline-block text-left`)}>
      <Menu.Button
        className="inline-flex w-full justify-center focus:outline-none px-3 py-2 rounded-md hover:bg-gray-200 hover:text-black">
        <span className="align-middle text-xs font-medium uppercase tracking-wider">
          <Trans i18nKey="meetings.table_columns.name">
            Name
          </Trans>
        </span>
        <SortIndicator order={sortOrder}/>
        <span className="relative flex h-2 w-2 ml-1">
          <span
            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"/>
          <span className="relative inline-flex rounded-full h-2 w-2 bg-sky-500"/>
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={clsx(
                    `group flex w-full items-center rounded-md px-5 py-3 text-sm font-normal text-gray-900`,
                    active && 'hover:bg-gray-100',
                  )}
                  onClick={() => onSortChange(SortProperty.FULL_NAME, nextSortOrder)}
                >
                  <SortIndicator
                    className="mr-1"
                    order={nextSortOrder}
                  />
                  <Trans i18nKey="meetings.table_columns.sort_full_name">
                    Sort by Full Name
                  </Trans>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <ProFeatureAction
                  as="button"
                  className={clsx(
                    `group flex w-full items-center rounded-md px-5 py-3 text-sm font-normal text-gray-900`,
                    active && 'hover:bg-gray-100',
                  )}
                  onClick={() => onSortChange(SortProperty.FIRST_NAME, nextSortOrder)}
                >
                  <SortIndicator
                    className="mr-1"
                    order={nextSortOrder}
                  />
                  <Trans i18nKey="meetings.table_columns.sort_first_name">
                    Sort by First Name
                  </Trans>
                  <ProFeatureAction.Badge />
                </ProFeatureAction>
              )}
            </Menu.Item>
            <Menu.Item>
              {({active}) => (
                <ProFeatureAction
                  as="button"
                  className={clsx(
                    `group flex w-full items-center rounded-md px-5 py-3 text-sm font-normal text-gray-900`,
                    active && 'hover:bg-gray-100',
                  )}
                  onClick={() => onSortChange(SortProperty.LAST_NAME, nextSortOrder)}
                >
                  <SortIndicator
                    className="mr-1"
                    order={nextSortOrder}
                  />
                  <Trans i18nKey="meetings.table_columns.sort_last_name">
                    Sort by Last Name
                  </Trans>
                  <ProFeatureAction.Badge />
                </ProFeatureAction>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
