import {
  ADD_BULK_MEETINGS, ADD_MEETING,
  EMPTY_UNSYNCED_MEETINGS,
  INIT_MEETINGS_SYNC, REMOVE_MEETINGS, RENAME_MEETING,
  SET_MEETINGS, SET_MEETINGS_IN_PROGRESS,
  SET_MEETINGS_PENDING
} from './actionTypes';
import { client } from '../misc/api';
import { getExtVersion, sendExtRPCMessage, sendMessageAsync } from '../misc/ext';
import notify, { NotifyType } from '../misc/notify';


/**
 * Transform the meeting name from "All hands" to "All hands - xxx-xxx-xxx"
 *
 * @param {{name: string, code: string}} meeting - Meeting object
 * @returns {string}
 */
export function getMeetingDisplayName(meeting) {
  let displayName = meeting.name || 'Meeting details';
  if (meeting?.code && displayName.indexOf(meeting.code) === -1) {
    displayName = `${displayName} - ${meeting.code}`;
  }

  return displayName;
}


/**
 * Fetch meeting by local_key from API.
 *
 * @param localKey {string} chrome.storage.local key used by the Extension
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export function fetchMeetingById(localKey) {
  return async (dispatch, getStore) => {
    const user = getStore().userReducer.user;

    if (getExtVersion()) {
      const extResponse = await sendExtRPCMessage('meetings.getByKey', { key: localKey });

      if (extResponse && !extResponse?.endedAt) {
        // meeting in progress
        return dispatch(setInProgressMeetings([extResponse]));
      } else if (extResponse && (user?.is_anonymous || !user?.is_paid || !extResponse?.syncedAt)) {
        // if the used is anonymous or something happened with the backup and is not synced with the backend
        return dispatch({
          type: ADD_MEETING,
          payload: extResponse,
          shouldTransform: true, // transform the meeting received from extension to the API format
        });
      }
    } else {
      // TODO: deprecated, remove after the extension with root DOM is released and used by everyone
      // fetch meetings that are in progress, from the Extension
      dispatch(sendMessageAsync('meetings.getInProgress'));
    }

    // don't attempt to fetch from API if user is anon
    // TODO: notify the user if unable to fetch the meeting
    if (user?.is_anonymous) {
      return;
    }

    try {
      const res = await client.get(`/meetings/${localKey}/`);
      if (res.ok) {
        return dispatch({
          type: ADD_MEETING,
          payload: res.data,
        });
      } else {
        console.error(`Failed to get meeting.`, localKey, res);
        throw new Error(`Failed to get meeting. HTTP status=${res.status}`);
      }
    } catch (e) {
      console.error('Error while getting the meeting', e);
    }
  };
}


/**
 * Fetch meetings list from the API, paginated.
 *
 * @param limit {number} How many meetings should be per page. Default to 50
 * @param offset {number} How many meetings to skip
 * @param search {string} Search string that filters meeting name, code
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export function fetchMeetings({ limit, offset , search = ''}) {
  return async (dispatch, getStore) => {
    const params = { limit, offset, search };

    dispatch({ type: SET_MEETINGS_PENDING });

    if (getExtVersion()) {
      const extResponse = await sendExtRPCMessage('meetings.getInProgress');
      if (extResponse) {
        dispatch(setInProgressMeetings(extResponse));
      }
    } else {
      // TODO: deprecated, remove after the extension with root DOM is released and used by everyone
      // fetch meetings that are in progress, from the Extension
      dispatch(sendMessageAsync('meetings.getInProgress'));
    }

    const user = getStore().userReducer.user;

    // If the user is anonymous or not paid, fetch meetings from the Extension.
    // There is a corner case here, if the user downgraded to free but reinstalled the extension,
    // the backed up meetings will not be showed. This is a limitation for now,
    // and TODO in the future if any user complains.
    if (user?.is_anonymous || !user?.is_paid) {
      const extResponse = await sendExtRPCMessage('meetings.getPage', { limit, offset, search });
      if (extResponse) {
        return dispatch({
          type: SET_MEETINGS,
          payload: extResponse,
          shouldTransform: true, // transform the meeting received from extension to the API format
        });
      }

      // no meetings found, on local storage
      return dispatch({
        type: SET_MEETINGS,
        payload: {
          count: 0,
          offset,
          search,
        },
      });
    }

    try {
      let queryString;
      if (params && Object.keys(params).length) {
        queryString = new URLSearchParams(params).toString();
      }

      const res = await client.get(`/meetings/${queryString ? `?${queryString}` : ''}`);

      // set `offset` so we know for which page this data belongs to
      res.data.offset = offset;

      // set `search` so we know the search phrase when changing the pages, if multiple
      res.data.search = search;

      return dispatch({
        type: SET_MEETINGS,
        payload: res.data,
      });
    } catch (e) {
      console.error('Error while fetching meetings', params, e);
      return dispatch({
        type: SET_MEETINGS,
        payload: {
          count: 0,
          offset,
          search,
        },
      });
    }
  }
}


/**
 * Fetches the list of meetings that are in progress, from the Extension
 *
 * @param meetings {array}
 * @returns {(function(*): Promise<void>)|*}
 */
export function setInProgressMeetings(meetings) {
  return async (dispatch) => {
    dispatch({
      type: SET_MEETINGS_IN_PROGRESS,
      payload: meetings,
    });
  }
}


/**
 * When UI is loaded and ready to receive data from the Extension it fires `ui.ready` event,
 * and the Extension responds with the list of meetings that were not synced yet.
 *
 * @param meetings {array}
 * @returns {(function(*): Promise<void>)|*}
 */
export function setUnsyncMeetings(meetings) {
  return async (dispatch) => {
    dispatch({
      type: INIT_MEETINGS_SYNC,
      payload: meetings,
    });
  }
}


/**
 * Bulk create meetings
 *
 * @param meetings
 * @returns {function(*): Promise<*>}
 */
export function syncMeetings(meetings) {
  return async (dispatch) => {
    const chunkSize = 25;
    for (let i = 0; i < meetings.length; i += chunkSize) {
      const chunk = meetings.slice(i, i + chunkSize);

      try {
        const res = await client.post('/meetings/', chunk);
        if (res.ok) {
          dispatch({
            type: ADD_BULK_MEETINGS,
            payload: res.data,
          });

          dispatch(sendMessageAsync('meetings.bulkSync', {
            keys: chunk.map((i) => i.local_key),
          }));
        } else {
          console.error('Add bulk meetings failed.', res);
          throw new Error(`Add bulk meetings failed. HTTP status=${res.status}`);
        }
      } catch (e) {
        console.error('!!!!!!!!!!', e);
        notify('An error occurred while syncing the meetings! Please try again later.', NotifyType.ERROR);
      }
    }

    return dispatch({
      type: EMPTY_UNSYNCED_MEETINGS
    })
  }
}


/**
 * Renames a meeting by local_key.
 * Case 1: anon rename -> ext rename
 * Case 2: free rename -> ext rename
 * Case 3: pro rename -> ext rename + API rename
 *
 * @param localKey {string} chrome.storage.local key used by the Extension
 * @param newName {string} The new name of the meeting
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export function renameMeeting(localKey, newName) {
  return async (dispatch, getStore) => {
    const user = getStore().userReducer.user;

    const extResponse = await sendExtRPCMessage('meetings.renameMeeting', {
      key: localKey,
      newName,
    });

    // if the user is anonymous or free, we only update the meeting in the Extension
    if (user?.is_anonymous || !user?.is_paid) {
      if (extResponse) {
        notify('Successfully updated the meeting.', NotifyType.SUCCESS);
        return dispatch({
          type: RENAME_MEETING,
          payload: {
            local_key: localKey,
            name: newName,
          }
        })
      }

      notify('Failed to updated the meeting! Please try again later.', NotifyType.ERROR);
      return;
    }

    try {
      const res = await client.patch(`/meetings/${localKey}/`, {
        name: newName,
      });

      if (res.ok) {
        notify('Successfully updated the meeting.', NotifyType.SUCCESS);
        return dispatch({
          type: RENAME_MEETING,
          payload: {
            local_key: localKey,
            name: newName,
          }
        })
      } else {
        console.error(`Failed to rename meeting.`, res);
        throw new Error(`Failed to rename meeting. HTTP status=${res.status}`);
      }
    } catch (e) {
      console.error('Error while renaming the meeting', e);
      notify('Failed to updated the meeting! Please try again later.', NotifyType.ERROR);
    }
  }
}


/**
 * Deletes the meeting from db and notifies the Extension about deletion, as well.
 *
 * @param localKeys {string|string[]} - chrome.storage.local key used by the Extension
 * @param hasMeetingsInProgress {boolean} - If any of the meetings is still in progress
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export function bulkDeleteMeetingsByIds(localKeys, hasMeetingsInProgress) {
  return async (dispatch, getStore) => {
    const user = getStore().userReducer.user;

    // convert to array if it's a string
    if (typeof localKeys === 'string') {
      localKeys = [localKeys];
    }

    // takes advantage of the storage.remove method, which accepts a string or array of strings
    const extResponse = await sendExtRPCMessage('meetings.deleteByKey', { key: localKeys });

    // if the user is anonymous or free, we only update the meeting in the Extension
    if (user?.is_anonymous || !user?.is_paid || hasMeetingsInProgress) {
      if (extResponse) {
        notify('Meeting has been deleted.', NotifyType.SUCCESS);
        return dispatch({
          type: REMOVE_MEETINGS,
          payload: localKeys,
        })
      }

      notify('Failed to delete the meeting! Please try again later.', NotifyType.ERROR);
      return;
    }

    try {
      const res = await client.delete(`/meetings/bulk-delete/`, {
        local_keys: localKeys,
      });

      // TODO: deprecated, remove after the extension with root DOM is released and used by everyone
      // send delete event to the Extension, to clean up the storage
      if (!getExtVersion()) {
        dispatch(sendMessageAsync('meetings.delete', {
          key: localKeys,
        }));
      }

      if (res.ok) {
        notify('Meeting has been deleted.', NotifyType.SUCCESS);
        return dispatch({
          type: REMOVE_MEETINGS,
          payload: localKeys,
        })
      } else {
        console.error(`Failed to delete meeting.`, res);
        throw new Error(`Failed to delete meeting. HTTP status=${res.status}`);
      }
    } catch (e) {
      console.error('Error while deleting the meeting', e);
      notify('Failed to delete the meeting! Please try again later.', NotifyType.ERROR);
    }
  }
}
