import React from 'react';
import { ReactComponent as LoaderSVG } from '../../assets/imgs/loader.svg';


export default function MiddleLoader() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="flex flex-1 flex-col items-center justify-center">
          <LoaderSVG className="animate-spin h-10 w-10 text-blue-600" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}
