import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RocketLaunchIcon, WindowIcon } from '@heroicons/react/24/outline';
import ToggleButton from '../components/common/ToggleButton';
import { updateExtSettings } from '../users/actions';
import ExtNotInstalledBanner from '../components/ExtNotInstalledBanner';
import SEO from '../components/common/SEO';


const SettingItem = ({ title, description, enabled, onChange, ...props }) => (
  <div className="flex items-center bg-gray-100 text-gray-600 text-sm rounded max-w-3xl p-3 mb-2">
    <div className="flex-none bg-gray-200 rounded-full p-3">
      { props.icon && <props.icon className="w-7 h-7 text-blue-500" aria-hidden="true" /> }
    </div>

    <div className="flex-1 ml-5">
      <b>{ title }</b>
      <p className="mt-1">
        { description }
      </p>
    </div>

    <ToggleButton
      className="flex-none ml-2"
      checked={enabled}
      onChange={onChange}
    />
  </div>
);


function PreferencesPage({ dispatch, settings }) {
  const { t } = useTranslation();

  const handleChange = async (name, value) => {
    return dispatch(updateExtSettings(name, value));
  };

  return (
    <>
      <SEO
        title="Preferences | MeetList.io"
      />
      <div>
        <ExtNotInstalledBanner />

        {/*<h3 className="text-black">*/}
        {/*  Date & Time*/}
        {/*</h3>*/}
        {/*<SettingItem*/}
        {/*  icon={ClockIcon}*/}
        {/*  title="Time Format"*/}
        {/*  description="Use a 24-hour clock format."*/}
        {/*  enabled={settings.hour24_format}*/}
        {/*  onChange={(value) => handleChange('hour24_format', value)}*/}
        {/*/>*/}

        <h3 className="text-black text-lg font-bold mb-2">
          <Trans t={t} i18nKey="preferences.extension_preferences">
            Browser Extension Preferences
          </Trans>
        </h3>

        <SettingItem
          icon={WindowIcon}
          title={
            <Trans t={t} i18nKey="preferences.new_tab_title">
              New Tab Report
            </Trans>
          }
          description={
            <Trans t={t} i18nKey="preferences.new_tab_description">
              Open a new tab with the attendance report as soon as your meeting ends.
            </Trans>
          }
          enabled={settings.open_meeting_tab}
          onChange={(value) => handleChange('open_meeting_tab', value)}
        />

        <SettingItem
          icon={RocketLaunchIcon}
          title={
            <Trans t={t} i18nKey="preferences.auto_save_title">
              Automatic Attendance Tracking
            </Trans>
          }
          description={
            <Trans t={t} i18nKey="preferences.auto_save_description">
              Automatically monitor meeting attendance as participants join or leave.
            </Trans>
          }
          enabled={settings.auto_save}
          onChange={(value) => handleChange('auto_save', value)}
        />
      </div>
    </>
  );
}

export default connect((state) => ({
  settings: state.userReducer.settings,
}))(PreferencesPage);
