import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';


export default function SearchInput({ defaultValue, placeholder, onChange, className = '' }) {
  return (
    <div className={`relative ${className}`}>
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
      </div>
      <input
        type="search"
        name="search"
        defaultValue={defaultValue}
        className="block w-full max-w-xl p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-500 focus:outline-none"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}
