import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import PublicLayout from './common/PublicLayout';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import CTA from '../components/CTA';
import { getMetadataAndContent } from '../misc/utils';
import MiddleLoader from '../components/common/MiddleLoader';
import NoMatch from './NoMatch';
import SEO from '../components/common/SEO';


const components = {
  // eslint-disable-next-line
  h1: (props) => <h1 className="text-black text-4xl font-semibold mt-7 mb-3" {...props} />,

  // eslint-disable-next-line
  h2: (props) => <h2 className="text-black text-2xl font-semibold mt-7 mb-3" {...props} />,

  // eslint-disable-next-line
  h3: (props) => <h2 className="text-black text-lg font-bold mt-7 mb-3" {...props} />,

  // eslint-disable-next-line
  p: (props) => <p className="py-2" {...props} />,

  // eslint-disable-next-line
  a: (props) => <a target="_blank" rel="follow" className="text-blue-500 hover:underline" {...props} />,

  // Use the title attribute to add inline styles to images
  // eslint-disable-next-line
  img: ({ alt, title, ...props}) => {
    let style;
    if (title) {
      style = title.split(/;\s*/g).reduce((acc, curr) => {
        const [key, value] = curr.split(/:\s*/g);
        if (!key || !value) {
          return acc;
        }
        return { ...acc, [key]: value };
      }, {});
    }

    return (
      <img
        className="max-w-full h-auto mx-auto"
        alt={alt}
        title={alt}
        style={style}
        {...props}
      />);
  },

  // eslint-disable-next-line
  ul: (props) => <ul className="list-disc list-inside pl-5" {...props} />,

  // eslint-disable-next-line
  ol: (props) => <ol className="list-decimal list-inside pl-5" {...props} />,
}


export default function BlogPostPage() {
  const { slug } = useParams();
  const [postContent, setPostContent] = useState('');
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchPostContent = async () => {
      try {
        const module = await import(`../blog/${slug}.md`);
        const response = await fetch(module.default);
        const text = await response.text();
        const [metadata, content] = getMetadataAndContent(text);

        setPostContent(content);
        setMetadata(metadata);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchPostContent();
  }, [slug]);

  if (loading) {
    return <MiddleLoader />;
  }

  if (postContent === '') {
    return <NoMatch />;
  }

  return (
    <PublicLayout className="my-5">
      <SEO
        title={metadata.title}
        description={metadata.description}
      />

      <article className="max-w-screen-md mx-auto mb-16 text-gray-500 px-2">
        <Link
          to="/blog"
          className="btn-flat flex items-center gap-2"
        >
          <ArrowLeftIcon className="w-5 h-5" aria-hidden="true" />
          Blog home
        </Link>

        <h1 className="text-black text-5xl py-5">{ metadata.title }</h1>
        <ReactMarkdown
          children={postContent}
          components={components}
          // remarkPlugins={[remarkGfm]}
        />
      </article>

      {/*<Pricing/>*/}
      <CTA />
    </PublicLayout>
  );
}
