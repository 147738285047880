import { client } from '../misc/api';
import { SET_ANON_USER, SET_USER, SET_USER_PENDING, UPDATE_EXT_SETTINGS } from './actionTypes';
import { GOOGLE_REDIRECT_URI } from '../config';
import { getExtVersion, sendExtRPCMessage } from '../misc/ext';
import notify, { NotifyType } from '../misc/notify';


function setAuthFlag() {
  try {
    localStorage.setItem('auth', '1');
  } catch (e) {
    // ignore errors
  }
}


function removeAuthFlag() {
  try {
    localStorage.removeItem('auth');
  } catch (e) {
    // ignore errors
  }
}

export function hasAuthFlag() {
  try {
    return localStorage.getItem('auth');
  } catch (e) {
    // ignore errors
  }

  return false;
}


/**
 * Does a POST request to the API login endpoint, in order to authenticate the user.
 *
 * @param {string} code - The code received from the OAuth provider
 * @param {string} [scope] - The scope received from the OAuth provider
 * @returns {Promise<boolean|*>}
 */
export async function authenticateUser(code, scope) {
  try {
    const res = await client.post('/auth/login/', {
      redirect_uri: GOOGLE_REDIRECT_URI,
      code,
      scope,
    });

    // Mark the user as authenticated, so getCurrentUser know where to look for the user details
    setAuthFlag();

    // return the result of the authentication, boolean
    return res.ok;
  } catch (e) {
    console.error('An error occurred while authenticating the user', e);

    // remove the auth flag, so getCurrentUser will not try to fetch the user details
    removeAuthFlag();
  }

  return false;
}


/**
 * Fetch current logged in user, aka /me
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export function getCurrentUser() {
  return async (dispatch) => {
    dispatch({ type: SET_USER_PENDING });

    // Case 1. Anonymous user -> fetch from extension
    let extResponse;
    const extVersion = getExtVersion();
    if (extVersion) {
      extResponse = await sendExtRPCMessage('user.getUserAndSettings');

      // If there is no auth flag, and the extension user is not registered as paid,
      // we can continue as anonymous user
      const is_paid = Boolean(extResponse?.api_key);
      if (!hasAuthFlag() && !is_paid) {
        return dispatch({
          type: SET_ANON_USER,
          extPayload: extResponse,
        });
      }
    }

    // Case 2. Free user -> fetch from API + settings from extension
    // Case 3. Paid user -> fetch from API + settings from extension
    try {
      const res = await client.get('/users/me/');
      setAuthFlag();
      return dispatch({
        type: SET_USER,
        payload: res.data,
        extPayload: extResponse,
      });
    } catch (e) {
      removeAuthFlag();
      return dispatch({
        type: SET_USER,
        payload: {},
        authError: true,
      });
    }
  }
}


export function updateExtSettings(name, value) {
  return async (dispatch) => {
    const extResponse = await sendExtRPCMessage('user.updateSettings', {
      name,
      value,
    });

    if (!extResponse) {
      notify('Failed to update extension settings. Please check the extension is installed and enabled, ' +
        'then try again.', NotifyType.ERROR);
      return;
    }

    notify('Extension settings updated successfully.', NotifyType.SUCCESS);
    return dispatch({
      type: UPDATE_EXT_SETTINGS,
      payload: {
        [name]: value,
      }
    });
  }
}


/**
 * Does a POST request to the API logout endpoint, in order to delete the jwt_token cookie.
 * Redirects to /login right after
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export function logout() {
  return async (dispatch) => {
    try {
      await client.post('/auth/logout/');
      removeAuthFlag();
      window.location.replace('/login');
    } catch (e) {
      console.error('Unable to logout', e);
    }
  }
}
