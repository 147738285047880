import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { handleLoginWithGoogle } from '../misc/utils';
import { ReactComponent as LogoSVG } from '../assets/imgs/logo.svg';
import { ReactComponent as GoogleSVG } from '../assets/imgs/google.svg';
import { hasAuthFlag } from '../users/actions';
import { getExtVersion } from '../misc/ext';
import LanguageSelector from '../components/LanguageSelector';
import SEO from '../components/common/SEO';


function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const nextPath = searchParams.get('next');
  const [isLogin, setIsLogin] = useState(window.location.pathname.indexOf('login') > -1);

  // if we have authentication flag in localStorage, redirect to dashboard
  if (hasAuthFlag()) {
    return <Navigate to='/meetings' replace />;
  }

  return (
    <>
      <SEO
        title={`${isLogin ? t('auth.sign_in') : t('auth.sign_up')} | MeetList.io`}
      />

      <section className="flex flex-col min-h-screen bg-gray-100">
        <main className="relative flex flex-1 flex-col overflow-hidden py-8 px-4 sm:px-6 lg:px-8">
          <div className="relative flex flex-1 flex-col items-center justify-center">
            <div className="max-w-sm bg-white px-10 py-12 rounded shadow-md flex flex-col items-center text-center">
              <Link to="/">
                <LogoSVG className="h-14" aria-hidden="true" />
              </Link>

              <h1 className="mt-6 mb-12">
                {
                  isLogin ? (
                    <Trans i18nKey="auth.welcome_back">
                      Welcome back
                    </Trans>
                  ) : (
                    <Trans i18nKey="auth.join">
                      Join Meet List
                    </Trans>
                  )
                }
              </h1>
              <div className="flex flex-col justify-center">
                <button
                  className="secondary flex items-center justify-center px-10 py-3 text-md"
                  onClick={handleLoginWithGoogle}
                >
                  <GoogleSVG className="w-7 h-7 mr-2" aria-hidden="true" />
                  {
                    isLogin ? (
                      <Trans i18nKey="auth.sign_in_with_google">
                        Sign in with Google
                      </Trans>
                    ) : (
                      <Trans i18nKey="auth.sign_up_with_google">
                        Sign up with Google
                      </Trans>
                    )
                  }
                </button>
                {
                  getExtVersion() && !nextPath ? (
                    <button
                      className="secondary px-10 py-3 mt-3 text-md"
                      onClick={() => navigate('/meetings')}
                    >
                      <Trans i18nKey="auth.continue_as_guest">
                        Continue as Guest
                      </Trans>
                    </button>
                  ) : null
                }
                <p className="mt-5 text-gray-600">
                  {
                    isLogin ? (
                      <Trans i18nKey="auth.no_account">
                        No account?
                      </Trans>
                    ) : (
                      <Trans i18nKey="auth.existing_account">
                        Already have an account?
                      </Trans>
                    )
                  }
                  {' '}
                  <button
                    className="btn-flat"
                    onClick={() => setIsLogin(!isLogin)}
                  >
                    {
                      isLogin ? (
                        <Trans i18nKey="auth.sign_in">
                          Sign in
                        </Trans>
                      ) : (
                        <Trans i18nKey="auth.sign_up">
                          Sign up
                        </Trans>
                      )
                    }
                  </button>
                </p>
              </div>

              <p className="text-xs text-gray-600 mt-12">
                <Trans i18nKey="auth.agree_terms">
                  By continuing, you agree to MeetList.io’s <Link to="/terms-and-conditions" className="flat">Terms and
                  Conditions</Link> and acknowledge that MeetList.io’s <Link to="/privacy-policy" className="flat">Privacy
                  Policy</Link> applies to you.
                </Trans>
              </p>

              <LanguageSelector className="mt-5" />
            </div>
          </div>
        </main>
      </section>
    </>
  );
}

export default LoginPage;
