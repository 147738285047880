import React from 'react';
import { ChartHeader, CustomComposedChart } from './shared';
import { getTimeString } from '../../misc/utils';
import { Trans, useTranslation } from 'react-i18next';
import { ReferenceLine } from 'recharts';
import { tickFormatter } from './shared/helpers';
import PunctualityChartPNG from '../../assets/imgs/stats-punctuality-demo.png';


export function PunctualityChart({ stats, isDemo = false }) {
  const { t } = useTranslation();
  const chartDataset = stats?.punctuality;

  // format the value to time string
  const formatter = (value) => {
    return (
      <>
        { getTimeString(Math.abs(value * 1000)) }
        {' '}
        {
          value !== 0 && (
            value > 0 ?
              <Trans i18nKey="meetings.insights.punctuality_late" /> :
              <Trans i18nKey="meetings.insights.punctuality_early" />
          )
        }
      </>
    )
  }

  return (
    <>
      <ChartHeader>
        <ChartHeader.Title
          i18nKey="analytics.charts.punctuality.title"
          defaultText="Your punctuality"
        >
          <p className="text-xs">
            <Trans i18nKey="analytics.charts.punctuality.description">
              This chart represents your average punctuality for each day over the past week.
            </Trans>
            {' '}
            <Trans i18nKey="analytics.common.time_format" />
          </p>
        </ChartHeader.Title>

        <ChartHeader.CurrentValue
          isDemo={isDemo}
          value={formatter(chartDataset?.avg_punctuality)}
          // growthPercentage={20}
        />
      </ChartHeader>

      <CustomComposedChart
        className="mt-3"
        chartDataset={chartDataset}
        showYAxis={true}
        formatter={formatter}
        tickFormatter={tickFormatter}
        yAxis={{
          scale: 'sqrt',
        }}
        demoImageSrc={isDemo ? PunctualityChartPNG : null}
      >
        <ReferenceLine
          y={0}
          stroke="#17a34a"
          strokeDasharray="3 3"
          className="text-[10px]"
          label={t('meetings.insights.punctuality_on_time')}
        />
        {/*<ReferenceArea*/}
        {/*  y1={-60}*/}
        {/*  y2={60}*/}
        {/*  fill="#17a34a"*/}
        {/*  fillOpacity={0.03}*/}
        {/*  stroke="#17a34a"*/}
        {/*  strokeDasharray="3 3"*/}
        {/*  // ifOverflow="extendDomain"*/}
        {/*  ifOverflow="visible"*/}
        {/*  // label={*/}
        {/*  //   <text*/}
        {/*  //     x={266}*/}
        {/*  //     y={65}*/}
        {/*  //     fill="#17a34a"*/}
        {/*  //     textAnchor="middle"*/}
        {/*  //     dominantBaseline="middle"*/}
        {/*  //     fontSize="10"*/}
        {/*  //   >*/}
        {/*  //     On time*/}
        {/*  //   </text>*/}
        {/*  // }*/}
        {/*/>*/}
      </CustomComposedChart>
    </>
  );
}
