export const SET_MEETINGS_PENDING = 'SET_MEETINGS_PENDING';

export const SET_MEETINGS = 'SET_MEETINGS';
export const RENAME_MEETING = 'RENAME_MEETING';
export const ADD_MEETING = 'ADD_MEETING';
export const REMOVE_MEETINGS = 'REMOVE_MEETINGS';

export const INIT_MEETINGS_SYNC = 'INIT_MEETINGS_SYNC';
export const EMPTY_UNSYNCED_MEETINGS = 'EMPTY_UNSYNCED_MEETINGS';
export const ADD_BULK_MEETINGS = 'ADD_BULK_MEETINGS';

export const SET_MEETINGS_IN_PROGRESS = 'SET_MEETINGS_IN_PROGRESS';
