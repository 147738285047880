import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/16/solid';
import ParticipantsList from '../components/ParticipantsList';
import { bulkDeleteMeetingsByIds, fetchMeetingById } from '../meetings/actions';
import { exportMeetings } from '../misc/utils';
import MeetingName from '../components/MeetingName';
import { ReactComponent as LoaderSVG } from '../assets/imgs/loader.svg';
import ActionBar from '../components/ActionBar';
import ExtNotInstalledBanner from '../components/ExtNotInstalledBanner';
import AdSlot from '../components/AdSlot';
import MeetingInsights from '../components/MeetingInsights';
import SEO from '../components/common/SEO';
import useConfirm from '../components/common/ConfirmDialog';
import { DEFAULT_PRICE, MAX_MEETING_DURATION_MS } from '../config';
import { formatPrice } from '../components/common/PricingCards';


function MeetingDetailsPage({ dispatch, user, settings, meetings, meetingsInProgress }) {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { meetingLocalKey } = useParams();
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    // if there are no meetings in the state, fetch them from API.
    // e.g. the user refreshed the page
    if (!meetings.length) {
      dispatch(fetchMeetingById(meetingLocalKey));
    }
  }, [dispatch, meetingLocalKey, meetings]);

  useEffect(() => {
    // meeting details should be displayed from meetingsInProgress, if meeting has not ended,
    // so that it shows accurate `time_in_call` if in progress
    const foundItem = [
      ...meetingsInProgress,
      ...meetings,
    ].find((item) => item.local_key === meetingLocalKey);

    if (foundItem) {
      setSelectedMeeting(foundItem);
    }
  }, [meetingLocalKey, meetings, meetingsInProgress]);

  if (!selectedMeeting) {
    return (
      <div className="flex items-center justify-center mt-10">
        <LoaderSVG className="animate-spin h-10 w-10 text-blue-600" aria-hidden="true" />
      </div>
    );
  }

  const [day, monthName] = moment(selectedMeeting.created_at).format('D MMM').split(' ');
  const timeStart = moment(selectedMeeting.created_at).format(settings.time_format);
  const timeEnd = selectedMeeting.ended_at ?
    moment(selectedMeeting.ended_at).format(settings.time_format) :
    t('meetings.tags.in_progress');

  const endedAt = new Date(selectedMeeting.ended_at || Date.now()).getTime();
  const createdAt = new Date(selectedMeeting.created_at).getTime();
  const meetingDuration = endedAt - createdAt;
  const price = formatPrice(DEFAULT_PRICE);

  const onDelete = async (e) => {
    e.stopPropagation();

    const meetingsCount = 1;
    const ok = await confirm({
      type: 'error',
      title: t('modals.remove_meeting.title'),
      description: (
        <Trans i18nKey="modals.bulk_remove_meeting.description">
          Are you sure you want to delete <b>{{ meetingsCount }} meetings</b>? This action cannot be undone.
        </Trans>
      ),
      confirmBtnLabel: t('modals.remove_meeting.cta_button'),
    });

    if (ok) {
      const resp = await dispatch(bulkDeleteMeetingsByIds(selectedMeeting.local_key, !!selectedMeeting.ended_at));
      if (resp) {
        navigate('/meetings', { replace: true });
      }
    }
  }

  return (
    <div className="mb-16">
      <SEO
        title={`${selectedMeeting.name} | MeetList.io`}
      />
      {/*<SyncMeetings />*/}

      <AdSlot
        zone="sticky"
        className="hidden md:block"
      />

      {/* show extension not installed banner, if no ext ID was injected in DOM */}
      <ExtNotInstalledBanner />

      <div className="mb-10 flex items-start justify-between gap-5">
        <div>
          <div className="flex flex-1 items-center mb-5">
            <div className="bg-gray-200 inline-block text-gray-500 text-center px-5 py-2 rounded-lg">
              <div className="text-xl font-bold">{day}</div>
              <div className="text-sm">{monthName}</div>
            </div>
            <div className="ml-2">
              <MeetingName
                meeting={selectedMeeting}
              />
              <div className="text-gray-500">
                <span>{timeStart}</span>
                {
                  selectedMeeting.is_auto ? (
                    <>
                      {' - '}
                      <span key={timeEnd} className={selectedMeeting.ended_at ? '' : 'text-green-600'}>
                        {timeEnd}
                      </span>
                    </>
                  ) : null
                }
              </div>
            </div>
          </div>
          <MeetingInsights
            user={user}
            meeting={selectedMeeting}
          />
        </div>
        {
          user && !user.is_paid && (
            <div
              className="flex-none px-5 py-10 bg-local bg-gray-500 upsell-bg bg-center bg-no-repeat bg-cover rounded-lg bg-blend-soft-light w-80">
              <p className="max-w-xl mb-5 font-extrabold leading-tight tracking-tight text-white">
                <Trans i18nKey="modals.upsell.title">
                  Go Pro to unlock all the features.
                </Trans>
              </p>
              <Link
                to="/billing"
                className="inline-flex items-center px-2.5 py-1.5 text-xs font-medium text-center text-white border border-white rounded-lg hover:bg-white hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-700">
                <Trans i18nKey="modals.upsell.cta_button">
                  Upgrade for just €{{price}}/mo
                </Trans>
                <ArrowRightIcon className="w-3 h-3 ms-2" aria-hidden="true"/>
              </Link>
            </div>
          )
        }
      </div>

      <ActionBar
        searchPlaceholder={t('meetings.search_participants.placeholder')}
        searchOnChange={(event) => setSearchText(event.target.value)}
        onExportCSV={() => exportMeetings([selectedMeeting], 'csv')}
        onExportXLSX={() => exportMeetings([selectedMeeting], 'xlsx')}
        onExportPDF={() => window.print()}
        exportButtonVisible={true}
        onDelete={onDelete}
        deleteButtonVisible={selectedMeeting.ended_at || meetingDuration >= MAX_MEETING_DURATION_MS}
      />

      <ParticipantsList
        settings={settings}
        meeting={selectedMeeting}
        searchText={searchText}
      />
    </div>
  );
}

export default connect((state) => ({
  user: state.userReducer.user,
  settings: state.userReducer.settings,
  meetingsInProgress: state.meetingsReducer.meetingsInProgress,
  meetings: state.meetingsReducer.meetings,
}))(MeetingDetailsPage);
