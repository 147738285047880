import React from 'react';
import SEO from '../components/common/SEO';
import Menu from '../components/common/Menu';


export default function PrivacyPage() {
  return (
    <>
      <SEO
        title="Privacy Policy | MeetList.io"
      />

      <Menu />

      <section className="bg-gray-100">
        <main className="relative flex flex-1 flex-col overflow-hidden py-8 px-4 sm:px-6 lg:px-8">
          <div className="relative flex flex-1 flex-col items-center">
            <div className="max-w-screen-md bg-white px-10 py-12 rounded shadow-md flex flex-col mt-5">
              <h1 className="pb-2">Privacy Policy</h1>
              <small className="text-gray-500 italic mb-5">Last updated December 1, 2022</small>
              <p>
                At Meetlist, your privacy and the protection of your data is our top priority. All data
                collected, both personal and non-personal, is protected with the highest global standards.
                This privacy policy applies to our browser extension (Google Meet Attendance List), our website (https://meetlist.io),
                any subdomains of meetlist.io.
              </p>
              <p className="py-2">
                The information we gather or process is used solely for core functionality of MeetList and to improve
                the quality and security of our service. <b>Your information isn’t and has never been sold to third
                parties.</b>
              </p>

              <p className="py-2">
                MeetList.io operated by Bluepixel Solutions SRL.
              </p>

              <hr className="my-5" />

              <div className="text-sm">
                <h3 className="py-2">What information is being stored, or accessed?</h3>
                <b className="block py-2">MeetList account information</b>
                <p className="py-2">
                  Your name, email, profile picture, account settings, and extension data (such as meetings and participants) are
                  transferred and stored securely, solely for your usage within our app and not shared with any other
                  third parties, except as specified in this policy.
                </p>

                <b className="block py-2">Payment information</b>
                <p className="py-2">
                  To upgrade to MeetList Premium, your credit card number, credit card expiration date, security code,
                  name, country and postal code are required. All payment processing is done through a PCI compliant
                  third party (Stripe, Inc.). When paying by a credit card, the card details do not pass through our
                  servers, they are sent directly to Stripe and are stored securely, as per
                  their <a href="https://stripe.com/privacy" className="flat" target="_blank"
                           rel="noreferrer">Privacy</a> and <a href="https://stripe.com/security" className="flat"
                                                               target="_blank" rel="noreferrer">Security</a> policies.
                </p>

                <b className="block py-2">
                  Data accessible through WebExtensions API
                </b>
                <p className="py-2">
                  WebExtension APIs used within Chrome Extensions, Safari Extensions, Edge Add-ons, and Firefox Add-ons
                  have fine-grained permission levels that are enforced by the Web Browser, restricting information that
                  our extension has access to within your Browser. The Google Meet Attendance List extension can only
                  access specific information that you have explicitly granted permission for. <b>We do not
                  track your browsing history.</b>
                </p>

                <b className="block py-2">
                  Analytics
                </b>
                <p className="">
                  To improve the content, features and overall experience of the extension and website, we gather and
                  log data on how our users access and use MeetList Dashboard and extension. For example, we may log
                  actions like clicking on a button or completing a meeting (not the meeting details, just the action of
                  completing it).
                </p>

                <p className="py-2">
                  Some of this usage data is sent to Google Analytics. In these cases, we do not send any identifying
                  information that could be correlated with your account. We also make use of their IP anonymization
                  feature to prevent your IP from being associated with your usage data.
                </p>

                <h3 className="py-2">
                  Vendors & Sub-Processors
                </h3>
                <p className="">
                  MeetList uses certain sub-processors (including third parties, as listed below) and content delivery
                  networks to assist it in providing the service.
                </p>

                <div className="overflow-x-auto relative mt-5">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Entity name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Purpose
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="bg-white border-b">
                      <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                        DigitalOcean
                      </th>
                      <td className="py-4 px-6">
                        Cloud Computing/Data Center Hosting
                      </td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                        Cloudflare
                      </th>
                      <td className="py-4 px-6">
                        Public website content served to website visitors may be stored with Cloudflare, Inc., and
                        transmitted by Cloudflare, Inc., to website visitors, to expedite transmission.
                      </td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                        Google Identity
                      </th>
                      <td className="py-4 px-6">
                        Sign In With Google helps to quickly and easily manage user authentication and sign-in to our
                        website.
                      </td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                        Stripe
                      </th>
                      <td className="py-4 px-6">
                        Credit card data, payments and renewals are processed by Stripe.
                      </td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                        Sentry
                      </th>
                      <td className="py-4 px-6">
                        Third party service to track errors and monitor performance.
                      </td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                        Youtube
                      </th>
                      <td className="py-4 px-6">
                        Used to serve videos on our website. A call is made from your IP address to load
                        videos.
                      </td>
                    </tr>
                    <tr className="bg-white border-b">
                      <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                        Google Analytics
                      </th>
                      <td className="py-4 px-6">
                        Google Analytics is an industry standard third-party analytics service that we use to better
                        understand the usage and performance of our services. The information is anonymized so that it
                        is not tied to your IP address.
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <h3 className="mt-10 pb-3">
                  What rights do you have?
                </h3>
                <ul className="list-disc pl-3">
                  <li className="py-1">
                    Receive information about the personal data processed by us and how we process your data as well
                    as to gain access to such data.
                  </li>
                  <li className="py-1">
                    Rectify inaccurate personal data and restrict details.
                  </li>
                  <li className="py-1">
                    Receive all your personal data in a structured, commonly used and machine-readable format, as well
                    as having such data transmitted to another controller.
                  </li>
                  <li className="py-1">
                    Request erasure of your data, unless such data needs to be retained for legal purposes.
                  </li>
                  <li className="py-1">
                    Object to the processing of your data.
                  </li>
                  <li className="py-1">
                    Withdraw your consent at any time, when you have provided us with your consent to the processing
                    of your personal data.
                  </li>
                  <li className="py-1">
                    Lodge a complaint with the respective supervisory authority.
                  </li>
                </ul>
                <p className="py-2">
                  Through your use of the MeetList Dashboard, you can access and amend your own data at any time, if
                  that feature is available. This includes changing your email, name, and payment information on your
                  Billing page. As well as adding, editing and deleting other MeetList data like your meetings,
                  participants, etc.
                </p>
                <p className="py-2">
                  If such action is not available through the MeetList Dashboard, you may write us
                  at <u>contact@meetlist.io</u> clearly describing your request. After submitting a request, we will try to
                  reply within five (5) business days to confirm that we have received your request. After receiving
                  your request, we will investigate it and provide you with our response within two (2) weeks.
                </p>

                <h3 className="py-3">
                  Data security
                </h3>
                <p className="py-2">
                  Security is a priority at all times. We use a Tier 1 cloud provider to run our operations
                  (DigitalOcean). All data communication in transit to and from our servers is secured using HTTPS/TLS.
                  All MeetList domains have HTTP Strict Transport Security (HSTS) enabled and are in the HSTS Preload
                  list on the major browsers supporting this feature.
                </p>
                <p className="py-2">
                  All data in our databases and their associated backups are encrypted.
                </p>

                <h3 className="mt-3">Questions?</h3>
                <p className="py-2">
                  If you have any other questions, you can drop us a line here: <u>contact@meetlist.io</u>
                </p>
              </div>
            </div>
          </div>
        </main>
      </section>
    </>
  );
}
