import React from 'react';
import { ChartHeader, CustomComposedChart } from './shared';
import { Trans } from 'react-i18next';


export function MeetingsSummaryChart({ stats }) {
  const chartDataset = stats?.meetings_summary;
  const labelFormatter = (label) => <Trans i18nKey={`analytics.charts.meetings_per_day.${label}`} />;

  return (
    <>
      <ChartHeader>
        <ChartHeader.Title
          i18nKey="analytics.charts.meetings_per_day.title"
          defaultText="Total meetings per day"
        >
          <p className="text-xs pb-1">
            <Trans i18nKey="analytics.charts.meetings_per_day.description" />
          </p>
          <ul className="list-disc pl-3">
            <li className="mt-1">
              <b>
                <Trans i18nKey="analytics.charts.meetings_per_day.scheduled" />
              </b> - <Trans i18nKey="analytics.charts.meetings_per_day.scheduled_description" />
            </li>
            <li className="mt-1">
              <b>
                <Trans i18nKey="analytics.charts.meetings_per_day.adhoc"/>
              </b> - <Trans i18nKey="analytics.charts.meetings_per_day.adhoc_description" />
            </li>
          </ul>
        </ChartHeader.Title>

        <ChartHeader.CurrentValue
          value={chartDataset?.total_meetings}
          // growthPercentage={20}
        />
      </ChartHeader>

      <CustomComposedChart
        className="mt-3"
        chartDataset={chartDataset}
        showGrid={false}
        showLegend={true}
        labelFormatter={labelFormatter}
        yAxis={{
          hide: false,
        }}
      />
    </>
  );
}
