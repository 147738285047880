import { client } from '../misc/api';
import notify, { NotifyType } from '../misc/notify';
import { DEFAULT_INTERVAL } from '../config';
import * as storage from '../misc/storage';


/**
 * Requests a Stripe Checkout session URL from the server
 *
 * @returns {Promise<null|string>}
 */
export async function createPaymentSession(price) {
  try {
    const interval = storage.getItem('plan_interval') || DEFAULT_INTERVAL;
    const res = await client.post(`/billing/session/`, {
      plan_interval: interval,
      price,
    });

    if (res.ok) {
      return res.data.url;
    } else {
      console.error(`Failed to create payment URL`, res);
      throw new Error(`Failed to create payment URL. HTTP status=${res.status}`);
    }
  } catch (e) {
    console.error('Error while creating the payment URL', e);
    notify('Failed to create a payment URL! Please try again later.', NotifyType.ERROR);
  }

  return null;
}


/**
 * Request a Stripe Portal session URL from the server. User will be able to manage subscriptions.
 *
 * @returns {Promise<null|string>}
 */
export async function createPortalSession() {
  try {
    const res = await client.post(`/billing/portal/`);

    if (res.ok) {
      return res.data.url;
    } else {
      console.error(`Failed to create portal URL`, res);
      throw new Error(`Failed to create portal URL. HTTP status=${res.status}`);
    }
  } catch (e) {
    console.error('Error while creating the portal URL', e);
    notify('Failed to open the manage subscription URL! Please try again later.', NotifyType.ERROR);
  }

  return null;
}
