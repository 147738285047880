import React from 'react';
import ProFeatureAction from '../../common/ProFeatureAction';
import { Trans } from 'react-i18next';


export default function DemoChart({ height, src }) {
  return (
    <div
      className="relative w-full h-56 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${src})`,
      }}
    >
      {/*backdrop-blur-md*/}
      <div className="absolute inset-0 bg-white bg-opacity-40 rounded flex flex-col items-center justify-center">
        <ProFeatureAction
          as="button"
          className="primary"
        >
          <Trans i18nKey="common.unlock">
            Unlock
          </Trans>
          <ProFeatureAction.Badge/>
        </ProFeatureAction>
        <div className="text-black text-xs my-2 max-w-64 text-center">
          <Trans i18nKey="analytics.unlock_description">
            This chart is available with our Pro plan. Upgrade to unlock it.
          </Trans>
        </div>
      </div>
    </div>
  );
}