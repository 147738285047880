import React from 'react';
import { Link } from 'react-router-dom';

import PublicLayout from './common/PublicLayout';
import PageNotFoundSVG from '../assets/imgs/page-not-found.svg';
import SEO from '../components/common/SEO';


export default function NoMatch() {
  return (
    <PublicLayout className="my-16">
      <SEO
        title="404 - Page Not Found | MeetList.io"
        description="The page you are looking for does not exist. Please check the URL or return to the homepage."
        noIndex={true}
      />

      <section className="container px-3 mx-auto flex items-center justify-between">
        <div className="max-w-xl">
          <h1 className="my-2">
            Ooops! Page Not Found
          </h1>
          <p className="text-gray-600">
            This page doesn't exist or was removed! We suggest you go back to the home page.
          </p>

          <Link to="/" className="inline-block primary my-5">
            Back to Home
          </Link>
        </div>
        <div className="mx-auto hidden md:block">
          <img
            src={PageNotFoundSVG}
            className="w-4/5 mx-auto"
            alt="Attendance for Google Meet"
            width="80%"
          />
        </div>
      </section>

    </PublicLayout>
  );
}