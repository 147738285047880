import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useConfirm from './ConfirmDialog';


const ProFeatureAction = React.forwardRef(({ as: Element = 'button', onClick, children, ...props }, ref) => {
  const user = useSelector(state => state.userReducer.user);
  const confirm = useConfirm();
  const navigate = useNavigate();

  const handleClick = async (e) => {
    // Check if the user is allowed to perform the action
    if (!user.is_paid) {
      e.preventDefault();
      e.stopPropagation();

      const ok = await confirm({ type: 'upsell' });

      if (ok) {
        navigate('/billing');
      }
      return;
    }

    // If the user is allowed, call the original onClick handler
    if (onClick) {
      onClick(e);
    }
  }

  return (
    <Element
      ref={ref}
      onClick={handleClick}
      {...props}
    >
      { children }
    </Element>
  );
});


/**
 * Special component that adds the pro label
 * Usage:
 * <ProFeatureAction>
 *   <ProFeatureAction.Badge />
 * </ProFeatureAction>
 *
 * @param className
 * @returns {JSX.Element|null}
 * @constructor
 */
const Badge = ({ className }) => {
  const user = useSelector(state => state.userReducer.user);
  if (user.is_paid) {
    return null;
  }

  // return (
  //   <LockClosedIcon
  //     className={clsx('h-4 inline-block ml-2 mb-[3px]', className) }
  //     aria-hidden="true"
  //   />
  // )

  return (
    <span
      className={clsx(
        'ml-2 px-2 inline-flex text-[10px] leading-5 font-semibold rounded-full bg-black text-white',
        className,
      )}
    >
      PRO
    </span>
  );
}

ProFeatureAction.Badge = Badge;
export default ProFeatureAction;
