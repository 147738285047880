import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  unstable_HistoryRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';

import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';
import NoMatch from './containers/NoMatch';
import LoginPage from './containers/LoginPage';
import Layout from './containers/common/Layout';
import MeetingsPage from './containers/MeetingsPage';
import MeetingDetailsPage from './containers/MeetingDetailsPage';
import SyncMeetings from './containers/SyncMeetings';
import ScrollToTop from './containers/ScrollToTop';
import BillingPage from './containers/BillingPage';
import RequireAuth from './containers/common/RequireAuth';
import HomePage from './containers/HomePage';
import TermsPage from './containers/TermsPage';
import PrivacyPage from './containers/PrivacyPage';
import EmbedPremiumCTA from './containers/embed/EmbedPremium';
import AuthCallbackPage from './containers/AuthCallbackPage';
import { sendMessageAsync } from './misc/ext';
import PreferencesPage from './containers/PreferencesPage';
import AnalyticsPage from './containers/AnalyticsPage';
import BlogPage from './containers/BlogPage';
import BlogPostPage from './containers/BlogPostPage';
import { GA_MEASUREMENT_ID, isProd } from './config';
import PricingPage from './containers/PricingPage';


// Initialize Google Analytics, will automatically send page views
ReactGA.initialize(GA_MEASUREMENT_ID, {
  testMode: !isProd,
  gaOptions: {
    anonymizeIp: true,
    cookieExpires: 60 * 24 * 7, // 7 days in seconds
  }
});

const contextClass = {
  success: 'bg-emerald-100 text-emerald-600',
  warning: 'bg-amber-100 text-amber-600',
  error: 'bg-red-100 text-red-600',
};

function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);

  if (user?.is_paid) {
    // Send a message to the extension to let it know that the UI is ready
    // and fetch the unsynced meetings
    // TODO: deprecate this method and use the RPC protocol
    dispatch(sendMessageAsync('ui.ready'));
  }

  return (
    <Router history={createBrowserHistory({ window })}>
      <ScrollToTop>
        <Routes>
          {/* Landing routes */}
          <Route index element={<HomePage />} />
          <Route path="/pricing" element={<PricingPage />} />

          {/* App routes */}
          <Route
            path="/meetings"
            element={
              <RequireAuth redirectTo="/billing">
                <Layout />
              </RequireAuth>
            }
          >
            <Route index element={<MeetingsPage />} />
            <Route path="import" element={<SyncMeetings />} />
            <Route path=":meetingLocalKey" element={<MeetingDetailsPage />} />
          </Route>
          <Route
            path="/stats"
            element={
              <RequireAuth redirectTo="/billing">
                <Layout />
              </RequireAuth>
            }
          >
            <Route index element={<AnalyticsPage />} />
          </Route>
          <Route
            path="/billing"
            element={
              <RequireAuth redirectTo="/login">
                <Layout />
              </RequireAuth>
            }
          >
            <Route index element={<BillingPage />} />
          </Route>
          <Route
            path="/preferences"
            element={
              <RequireAuth redirectTo="/login">
                <Layout />
              </RequireAuth>
            }
          >
            <Route index element={<PreferencesPage />} />
          </Route>

          {/* Auth routes */}
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signup" element={<LoginPage />} />
          <Route exact path="/auth/callback" element={<AuthCallbackPage />} />

          {/* Privacy & Legal routes */}
          <Route exact path="/terms-and-conditions" element={<TermsPage />} />
          <Route exact path="/privacy-policy" element={<PrivacyPage />} />

          {/* Blog routes */}
          <Route path="/blog">
            <Route index element={<BlogPage />} />
            <Route path="tags/:tagName" element={<BlogPage />} />
            <Route path=":slug" element={<BlogPostPage />} />
          </Route>

          {/* Embed routes */}
          <Route path="/embed">
            <Route path="premium" element={<EmbedPremiumCTA />} />
          </Route>

          {/* 404 */}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </ScrollToTop>
      <ToastContainer
        position="top-center"
        theme="light"
        hideProgressBar={true}
        autoClose={3000}
        toastClassName={({ type }) => (contextClass[type || 'default'] +
          ` relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer w-96 mb-1`
        )}
        bodyClassName={() => 'flex items-center text-sm font-white font-med block p-3'}
      />
    </Router>
  );
}

export default withTranslation()(App);
