import React from 'react';

export default function ToggleButton({
  checked = false,
  onChange,
  className,
  defaultClassName = 'bg-slate-900/10 ring-slate-900/5',
  checkedClassName = 'bg-blue-500 ring-black/20'
}) {
  return (
    <div
      className={`pointer-events-auto cursor-pointer h-6 w-10 rounded-full p-1 ring-1 ring-inset transition duration-200 ease-in-out ${checked ? checkedClassName : defaultClassName} ${className}`}
      onClick={() => onChange?.(!checked)}
    >
      <div className={`h-4 w-4 rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out ${checked ? 'translate-x-4' : 'translate-x-0'}`} />
    </div>
  );
};
