import React from 'react';
import { BreakdownBarChart, ChartHeader } from './shared';
import { Trans } from 'react-i18next';
import BreakdownChartPNG from '../../assets/imgs/stats-breakdown-demo.png';

export function MeetingsBreakdownChart({ stats, isDemo = false }) {
  const chartDataset = stats?.meetings_breakdown;

  return (
    <>
      <ChartHeader>
        <ChartHeader.Title
          i18nKey="analytics.charts.meetings_breakdown.title"
          defaultText="Meetings breakdown"
        >
          <p className="text-xs">
            <Trans i18nKey="analytics.charts.meetings_breakdown.description" />
          </p>
          <ul className="list-disc pl-3">
            <li className="mt-1">
              <b>
                <Trans i18nKey="analytics.charts.meetings_breakdown.recurring"/>
              </b> - <Trans i18nKey="analytics.charts.meetings_breakdown.recurring_description"/>
            </li>
            <li className="mt-1">
              <b>
                <Trans i18nKey="analytics.charts.meetings_breakdown.1_1"/>
              </b> - <Trans i18nKey="analytics.charts.meetings_breakdown.1_1_description"/>
            </li>
            <li className="mt-1">
              <b>
                <Trans i18nKey="analytics.charts.meetings_breakdown.overrun"/>
              </b> - <Trans i18nKey="analytics.charts.meetings_breakdown.overrun_description"/>
            </li>
            <li className="mt-1">
              <b>
                <Trans i18nKey="analytics.charts.meetings_breakdown.unlabeled"/>
              </b> - <Trans i18nKey="analytics.charts.meetings_breakdown.unlabeled_description"/>
            </li>
          </ul>
        </ChartHeader.Title>
      </ChartHeader>

      <BreakdownBarChart
        className="mt-3"
        chartDataset={chartDataset}
        demoImageSrc={isDemo ? BreakdownChartPNG : null}
      />
    </>
  );
}
