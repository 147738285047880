import React from 'react';

import Menu from '../../components/common/Menu';
import Footer from '../../components/common/Footer';


function PublicLayout({ className, children }) {
  return (
    <>
      <div className="flex flex-col">
        <Menu />

        <div className={className}>
          { children }
        </div>

        <Footer />
      </div>
    </>
  );
}

export default PublicLayout;
