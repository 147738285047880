import { createReducer } from '@reduxjs/toolkit';
import {
  ADD_BULK_MEETINGS,
  ADD_MEETING, EMPTY_UNSYNCED_MEETINGS,
  INIT_MEETINGS_SYNC,
  REMOVE_MEETINGS, RENAME_MEETING,
  SET_MEETINGS, SET_MEETINGS_IN_PROGRESS, SET_MEETINGS_PENDING
} from './actionTypes';


export const PAGE_SIZE = 50;
const initialState = {
  status: 'idle',
  meetings: [],
  meetingsInProgress: [],

  syncStatus: 'idle',
  unsyncedMeetings: [],
  syncProgressCounter: 0,

  page: {
    count: -1,
    limit: PAGE_SIZE,
    offset: 0,
    search: '',
  },
};


/**
 * Transforms participants array format from extension to API.
 *
 * @param detailsList
 * @returns {*}
 */
function transformParticipants(detailsList) {
  return detailsList.map((details) => ({
    display_name: details.displayName,
    picture_url: details.avatarUrl,
    first_seen_at: details.firstSeen,
    time_in_call: details.timeInCall,
  }));
}


/**
 * Transforms meeting object format from extension to API.
 *
 * @param details
 * @returns {{is_auto, code: *, local_key, name, created_at: string, ended_at: (string|undefined), participants}}
 */
function transformMeeting(details) {
  const participants = transformParticipants(details.participants || []);

  return {
    local_key: details.id,
    name: details.name || `Meet - ${details.meetCode}`,
    code: details.meetCode,
    calendar_event_id: details.calendarEventId,
    calendar_id: details.calendarId,
    recurring_event_id: details.recurringEventId,

    is_auto: details.auto,

    created_at: new Date(details.createdAt).toISOString(),
    ended_at: details.endedAt ? new Date(details.endedAt).toISOString() : undefined,

    scheduled_start_at: details.scheduledStartAt ? new Date(details.scheduledStartAt).toISOString() : undefined,
    scheduled_end_at: details.scheduledEndAt ? new Date(details.scheduledEndAt).toISOString() : undefined,

    participants,
  }
}


const meetingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ADD_MEETING, (state, action) => {
      const meeting = action.shouldTransform ? transformMeeting(action.payload) : action.payload;
      const found = state.meetings.find((item) => item.local_key === meeting.local_key);
      if (!found) {
        state.meetings.push(meeting);
      }
    })
    .addCase(RENAME_MEETING, (state, action) => {
      const { local_key } = action.payload;
      const meeting = state.meetings.find((m) => m.local_key === local_key);
      if (meeting) {
        // "mutate" the object by overwriting a field
        meeting.name = action.payload.name;
      }
    })
    .addCase(REMOVE_MEETINGS, (state, action) => {
      const removedLocalKeys = new Set(action.payload);
      state.meetings = state.meetings.filter((m) => !removedLocalKeys.has(m.local_key));
      state.meetingsInProgress = state.meetingsInProgress.filter((m) => !removedLocalKeys.has(m.local_key));
      state.unsyncedMeetings = state.unsyncedMeetings.filter((m) => !removedLocalKeys.has(m.local_key));
    })
    .addCase(SET_MEETINGS_PENDING, (state, action) => {
      state.status = 'pending';
    })
    .addCase(SET_MEETINGS, (state, action) => {
      const results = action.payload.results || [];
      state.meetings = action.shouldTransform ? [] : results;

      if (action.shouldTransform) {
        for (const meetingDetails of results) {
          // filter out meetings that are in progress since there is a separate list for that
          if (meetingDetails.endedAt) {
            state.meetings.push(transformMeeting(meetingDetails));
          }
        }
      }

      // update count and offset, so we know on what page we are
      state.page.count = action.payload.count;
      state.page.offset = action.payload.offset;
      state.page.search = action.payload.search;

      state.status = 'idle';
    })
    .addCase(INIT_MEETINGS_SYNC, (state, action) => {
      state.unsyncedMeetings = [];
      for (const meetingDetails of action.payload) {
        state.unsyncedMeetings.push(transformMeeting(meetingDetails));
      }
      state.syncStatus = 'pending';
    })
    .addCase(ADD_BULK_MEETINGS, (state, action) => {
      for (const meetingDetails of action.payload) {
        const exists = state.meetings.find((item) => item.local_key === meetingDetails.local_key);
        if (!exists) {
          state.meetings.push(meetingDetails);
        }
      }

      // re-sort meetings as the order might be messed up by local meetings
      state.meetings = state.meetings.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

      // update count and offset, so after sync to show accurate pagination footer
      state.page.count = state.meetings.length;
      state.page.offset = 0;

      // keep track of sync progress
      state.syncProgressCounter += action.payload.length;
    })
    .addCase(EMPTY_UNSYNCED_MEETINGS, (state, action) => {
      state.syncStatus = 'idle';
      state.unsyncedMeetings = [];
      state.syncProgressCounter = 0;
    })
    .addCase(SET_MEETINGS_IN_PROGRESS, (state, action) => {
      state.meetingsInProgress = [];
      for (const meetingDetails of action.payload) {
        state.meetingsInProgress.push(transformMeeting(meetingDetails));
      }
    })
});

export default meetingsReducer;
