// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = { 'Content-Type': 'application/json' }

  const config = {
    method: body ? 'POST' : 'GET',
    credentials: 'include',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let response, data;
  try {
    response = await window.fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, config);

    // do not try to read body where there's none
    if (response.status !== 204) {
      data = await response.json();
    }

    if (response.ok) {
      // Return a result object similar to Axios
      return {
        ok: response.ok,
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject({
      status: response.status,
      error: err.message,
      data,
    })
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'POST', body })
}

client.patch = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'PATCH', body })
}

client.delete = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'DELETE', body })
}
