import React from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { calculatePunctuality, getTimeString } from '../misc/utils';
import Badge from './common/Badge';


export default function MeetingInsights({ user, meeting }) {
  const createdAt = new Date(meeting.created_at).getTime();
  const endedAt = new Date(meeting.ended_at || Date.now()).getTime();
  const meetingDuration = endedAt - createdAt;

  let punctuality;
  if (meeting.scheduled_start_at) {
    punctuality = calculatePunctuality(meeting.created_at, meeting.scheduled_start_at);
  }

  // Calculate the average time in call for all participants
  const avgTimeInCallMs = meeting.participants
    .reduce((acc, p) => acc + p.time_in_call, 0) / meeting.participants.length;

  // calculate overtime
  let isOvertime = false;
  if (meeting.scheduled_end_at) {
    const overtimeMs = endedAt - new Date(meeting.scheduled_end_at).getTime();
    // more than 15 minutes overtime
    isOvertime = overtimeMs > 15 * 60 * 1000;
  }

  // check if meeting tags should be displayed
  const hasTags = !meeting.is_auto
    || meeting.recurring_event_id
    || meeting.participants.length === 2
    || isOvertime;

  return (
    <div className="flex flex-wrap items-center gap-5 divide-x mb-5">
      {/*
        Calculate the duration of the meeting by subtracting the "Joined At" time from the "Ended At" time.
        This will give users an idea of how long the meeting lasted.
      */}
      <div className="px-2">
        <div className="text-gray-500 text-[10px] font-medium uppercase tracking-wider mb-1 whitespace-nowrap">
          <Trans i18nKey="meetings.insights.meeting_duration">
            Meeting duration
          </Trans>
        </div>
        <div className="text-black text-sm">
          { meeting.is_auto ? getTimeString(meetingDuration) : '-' }
        </div>
      </div>

      {/*
        The number of participants in the meeting can be displayed to give users an idea of how many people
        were involved in the meeting.
      */}
      <div className="px-4">
        <div className="text-gray-500 text-[10px] font-medium uppercase tracking-wider mb-1 whitespace-nowrap">
          <Trans i18nKey="meetings.insights.participants">
            Participants
          </Trans>
        </div>
        <div className="text-black text-sm">
          { meeting.participants?.length || 0 }
        </div>
      </div>

      {/*
        Calculate the average time in call for all participants. This will give users an idea of how long
        each participant spent in the meeting on average.
       */}
      <div className="px-4">
        <div className="text-gray-500 text-[10px] font-medium uppercase tracking-wider mb-1 whitespace-nowrap">
          <Trans i18nKey="meetings.insights.avg_time_in_call">
            Avg. Time in Call
          </Trans>
        </div>
        <div className="text-black text-sm">
          { avgTimeInCallMs ? getTimeString(avgTimeInCallMs) : '-' }
        </div>
      </div>

      {/*
        Compare the "Joined At" time with the "Scheduled At" time to show if the meeting started on time,
        was early, or delayed. Similarly, compare the "Ended At" time with the "Scheduled Ended At" time
        to show if the meeting ended on time or ran over.
      */}
      {
        meeting.scheduled_start_at &&
        <div className="px-4">
          <div className="text-gray-500 text-[10px] font-medium uppercase tracking-wider mb-1 whitespace-nowrap">
            <Trans i18nKey="meetings.insights.punctuality">
              Your punctuality
            </Trans>
          </div>
          <div className={clsx('text-sm', punctuality.isLate ? 'text-red-600' : 'text-green-600')}>
            { punctuality.displayText || <Trans i18nKey="meetings.insights.punctuality_on_time" /> }
            {' '}
            {
              punctuality.displayText && (
                punctuality.isLate ?
                  <Trans i18nKey="meetings.insights.punctuality_late" /> :
                  <Trans i18nKey="meetings.insights.punctuality_early" />
              )
            }
          </div>
        </div>
      }

      {/*
        Calculate the difference between the scheduled duration (difference between "Scheduled Ended At" and
        "Scheduled At") and the actual duration (difference between "Ended At" and "Joined At"). This will
        show users if the meeting was more efficient than planned or if it took longer than expected.
       */}
      {/*<div className="px-4 max-w-40">*/}
      {/*  <div className="text-gray-500 text-[10px] font-medium uppercase tracking-wider mb-1 whitespace-nowrap">*/}
      {/*    Efficiency*/}
      {/*  </div>*/}
      {/*  <div className="text-black text-sm">*/}
      {/*    80%*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* Tags */}
      {
        hasTags ? (
          <div className="px-4">
            <div className="text-gray-500 text-[10px] font-medium uppercase tracking-wider mb-1 w-full max-w-40 px-2">
              <Trans i18nKey="meetings.insights.tags">
                Tags
              </Trans>
            </div>
            <div className="text-black flex flex-wrap gap-2">
              {
                !meeting.is_auto ? (
                  <Badge
                    className="bg-orange-200 text-orange-800"
                    i18nKey="meetings.tags.manual"
                    defaultText="Manual"
                  />
                ) : null
              }
              {
                meeting.recurring_event_id ? (
                  <Badge
                    className="bg-green-100 text-green-800"
                    i18nKey="meetings.tags.recurring"
                    defaultText="Recurring"
                  />
                ) : null
              }
              {
                meeting.participants?.length === 2 ? (
                  <Badge
                    className="bg-purple-100 text-purple-800"
                    defaultText="1:1"
                  />
                ) : null
              }
              {
                isOvertime ? (
                  <Badge
                    className="bg-red-100 text-red-800"
                    i18nKey="meetings.tags.long_meeting"
                    defaultText="Long meeting"
                  />
                ) : null
              }
            </div>
          </div>
        ) : null
      }
    </div>
  );
}
