import React from 'react';
import { Trans } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { getExtId } from '../misc/ext';
import { CHROME_STORE_URL } from '../config';


export default function ExtNotInstalledBanner() {
  // hide the banner if the extension is installed
  if (getExtId()) {
    return null;
  }

  return (
    <div className="flex gap-2 text-sm text-blue-800 bg-blue-50 rounded-lg p-4 mb-7">
      <InformationCircleIcon className="w-5 h-5" aria-hidden="true" />
      <div>
        <span className="font-medium">
          <Trans i18nKey="banner.ext_not_installed.title">
            Browser extension is <span className="text-red-500">not installed</span> or unable to connect.
          </Trans>
        </span>
        <ul className="mt-1.5 list-disc list-inside">
          <li>
            <Trans i18nKey="banner.ext_not_installed.line_1">
              Verify that <b>Google Meet Attendance List</b> browser extension is installed and enabled.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="banner.ext_not_installed.line_2">
              Check your browser's extensions page (<span className="font-mono">chrome://extensions</span>) for any
              error messages related to the Google Meet Attendance List.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="banner.ext_not_installed.line_3">
              Ensure that you are using the latest browser version.
            </Trans>
          </li>
        </ul>

        <a
          href={ CHROME_STORE_URL }
          className="btn-primary inline-block mt-5"
          target="_blank"
          rel="noreferrer"
        >
          <Trans i18nKey="banner.ext_not_installed.cta_button">
            Install now &ndash; <small className="font-normal">it's free!</small>
          </Trans>
        </a>
      </div>
    </div>
  );
}
