import React from 'react';
import { SortOrder } from './index';
import { ArrowDownIcon, ArrowUpIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';


/**
 *
 * @param {string|null} order - The order of the sort ASC, DESC, or NONE
 * @param {string} className - The class properties to apply to the element
 * @returns {Element}
 * @constructor
 */
export default function SortIndicator({ className, order = SortOrder.NONE }) {
  return (
    order === SortOrder.ASC ?
      <ArrowUpIcon className={clsx('h-4 w-4', className)} aria-hidden="true" /> :
      order === SortOrder.DESC ?
        <ArrowDownIcon className={clsx('h-4 w-4', className)} aria-hidden="true" /> :
        <ChevronUpDownIcon className={clsx('h-4 w-4', className)} aria-hidden="true" />
  );
}
