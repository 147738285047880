import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from '../../components/common/Sidebar';
import TopBar from '../../components/common/TopBar';


function Layout() {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Sidebar />
        <TopBar />

        <div className="px-5 mt-20 ml-[263px] print:ml-0">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Layout;
