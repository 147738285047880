import React from 'react';
import { Link } from 'react-router-dom';


export default function Footer() {
  return (
    <footer
      className="container mx-auto flex flex-col-reverse sm:flex-row justify-center items-center sm:justify-between py-10">
      <div className="flex-shrink-0 text-gray-1 mt-8 sm:mt-0">
        &copy; { new Date().getFullYear() } BluePixel. All rights reserved.
      </div>
      <div className="flex items-center">
        <Link to="/terms-and-conditions/" className="text-gray-1 mr-5">
          Terms and Conditions
        </Link>
        <Link to="/privacy-policy/" className="text-gray-1">
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
}
