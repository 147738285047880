import React from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';


export default function Badge({ defaultText, i18nKey = null, className = '' }) {
  return (
    <span className={clsx('px-2 inline-flex text-xs leading-5 font-semibold rounded-full', className)}>
      {
        i18nKey ? (
          <Trans i18nKey={i18nKey}>
            { defaultText }
          </Trans>
        ) : defaultText
      }
    </span>
  );
}

Badge.propTypes = {
  i18nKey: PropTypes.string,
  defaultText: PropTypes.string.isRequired,
  className: PropTypes.string,
};
