import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { PencilIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { getMeetingDisplayName, renameMeeting } from '../meetings/actions';


function MeetingName({ meeting }) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [isEdit, setEdit] = useState(false);
  const inProgress = !meeting.ended_at;

  const handleRename = async (e) => {
    e.preventDefault();

    const newName = inputRef.current.value;

    if (newName !== meeting.name) {
      await dispatch(renameMeeting(meeting.local_key, newName));
    }

    setEdit(false);
  };

  if (!isEdit) {
    return (
      <div className="flex items-center">
        <h2>{ getMeetingDisplayName(meeting) }</h2>
        {
          !isEdit && !inProgress &&
          <button
            className="flat flex items-center ml-10 print:hidden"
            onClick={() => setEdit(true)}
          >
            <PencilIcon className="w-4 h-4 mr-1" aria-hidden="true"/>
            <Trans i18nKey="common.edit">
              Edit
            </Trans>
          </button>
        }
      </div>
    );
  }

  return (
    <form
      className="flex items-center"
      onSubmit={handleRename}
    >
      <input
        type="text"
        className="border border-gray-300 px-3 py-2 rounded-md w-96"
        autoFocus
        defaultValue={meeting.name}
        placeholder="Meeting name"
        ref={inputRef}
        required={true}
      />
      <button
        type="submit"
        className="primary ml-2"
      >
        <Trans i18nKey="common.save">
          Save
        </Trans>
      </button>
      <button
        className="secondary ml-1"
        onClick={() => setEdit(false)}
      >
        <Trans i18nKey="common.cancel">
          Cancel
        </Trans>
      </button>
    </form>
  )
}

export default MeetingName;
