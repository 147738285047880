import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckBadgeIcon, XCircleIcon } from '@heroicons/react/16/solid';
import UpsellPeoplePNG from '../../../assets/imgs/upsell-people.png';
import { ReactComponent as UpsellBadgeSVG } from '../../../assets/imgs/upsell-badge.svg';
import { Trans } from 'react-i18next';
import { DEFAULT_PRICE } from '../../../config';
import { formatPrice } from '../PricingCards';


function UpsellFeature({ children }) {
  return (
    <div className="flex items-start gap-2 mb-1">
      <CheckBadgeIcon className="h-5 w-5 text-blue-500 mt-[2px] flex-none" aria-hidden="true" />
      <span className="flex-1">
        { children }
      </span>
    </div>
  );
}


export default function Upsell({
  isOpen = false,           // Whether the dialog is open
  onClose = () => {},               // Event when user closes the dialog or clicks on 'Cancel'
  onConfirm = () => {},             // Event when user "confirms" the action
}) {
  const cancelButtonRef = useRef(null);
  const price = formatPrice(DEFAULT_PRICE);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-out duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                <button
                  className="absolute top-4 right-4"
                  onClick={onClose}
                >
                  <XCircleIcon className="h-6 w-6" />
                </button>
                <div className="grid grid-cols-2 gap-2">
                  <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <h1>
                      <Trans i18nKey="modals.upsell.title">
                        Go Pro to unlock all the features.
                      </Trans>
                    </h1>

                    <div className="mt-5">
                      <UpsellFeature>
                        <Trans i18nKey="billing_plan.features.remove_ads">
                          <span>
                            <b>Remove ads</b> and popups to enter a distraction-free zone.
                          </span>
                        </Trans>
                      </UpsellFeature>
                      <UpsellFeature>
                        <Trans i18nKey="billing_plan.features.backup">
                          <span>
                            <b>Backup</b> unlimited meetings and participants
                          </span>
                        </Trans>
                      </UpsellFeature>
                      <UpsellFeature>
                        <Trans i18nKey="billing_plan.features.advanced_export">
                          <span>
                            Get <b>advanced export</b> options like XLSX, CSV and PDF
                          </span>
                        </Trans>
                      </UpsellFeature>
                      <UpsellFeature>
                        <Trans i18nKey="billing_plan.features.get_more">
                          <span>
                            Unlock <b>additional features</b> like search and analytics
                          </span>
                        </Trans>
                      </UpsellFeature>
                      <UpsellFeature>
                        <Trans i18nKey="billing_plan.features.multiple_devices">
                          Access history from <b>multiple devices</b>
                        </Trans>
                      </UpsellFeature>
                      <UpsellFeature>
                        <Trans i18nKey="billing_plan.features.indie_dev">
                          <b>Support me</b> as an indie{' '}
                          <a
                            className="underline text-black"
                            href="https://twitter.com/intent/follow?screen_name=92alexm"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            developer
                          </a>
                        </Trans>
                      </UpsellFeature>
                    </div>

                    <div className="mt-10">
                      <Trans i18nKey="modals.upsell.cancel_subscription_reminder">
                        <b>Cancel your subscription anytime.</b> We'll send you reminders 3 days before renewals.
                      </Trans>
                    </div>

                    <button
                      className="primary w-full mt-5"
                      onClick={onConfirm}
                    >
                      <Trans i18nKey="modals.upsell.cta_button">
                        Upgrade for just €{{price}}/mo
                      </Trans>
                    </button>
                  </div>
                  <div className="bg-blue-500/10 flex items-center justify-center upsell-bg">
                    <UpsellBadgeSVG
                      className="h-24 upsell-badge-animate bg-white p-2 rounded-full"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="bg-gray-100 px-3 py-5 sm:flex sm:flex-row items-center sm:px-6">
                  <span className="text-xs max-w-48 mr-5">
                    <Trans i18nKey="modals.upsell.people_count">
                      Used by 400,000+ people, all over the world.
                    </Trans>
                  </span>
                  <img
                    src={UpsellPeoplePNG}
                    className="h-8"
                    alt="Go Pro to unlock all the features"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
