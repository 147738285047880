import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';
import { ReactComponent as LoaderSVG } from '../assets/imgs/loader.svg';
import { syncMeetings } from '../meetings/actions';
import { usePrompt } from '../misc/hooks';


function SyncMeetings({ dispatch, unsyncedMeetings, syncProgressCounter, syncStatus }) {
  let [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    // sync meetings, if any
    if (syncStatus !== 'idle' && unsyncedMeetings.length > 0) {
      setIsBlocking(true);
      dispatch(syncMeetings(unsyncedMeetings));
    }

    if (!unsyncedMeetings.length) {
      setIsBlocking(false);
    }
  }, [dispatch, unsyncedMeetings, syncStatus]);

  usePrompt( 'Backup in progress! Are you sure you want to leave the screen?', isBlocking);

  if (!unsyncedMeetings.length) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center text-center mt-10">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <h1 className="py-2">
          Your meetings are safe!
        </h1>
        <Link
          to="/meetings"
          className="primary mt-2"
        >
          Go to meetings!
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center text-center mt-10">
      <LoaderSVG className="animate-spin h-10 w-10 text-blue-600" aria-hidden="true" />
      <h1 className="py-2">
        Backup in progress ({ syncProgressCounter }/{ unsyncedMeetings.length })
      </h1>
      <p className="text-gray-500 max-w-xs text-center">
        Please stay connected to Wi-Fi and wait for the backup to complete.
      </p>
    </div>
  )
}

export default connect((state) => ({
  syncStatus: state.meetingsReducer.syncStatus,
  syncProgressCounter: state.meetingsReducer.syncProgressCounter,
  unsyncedMeetings: state.meetingsReducer.unsyncedMeetings,
  meetings: state.meetingsReducer.meetings,
}))(SyncMeetings);
