import React, { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';

import { getTimeString } from '../misc/utils';
import DefaultProfile from '../assets/imgs/default-profile.svg';
import { SortOrder, SortProperty } from './sorting';
import SortByNameButton from './sorting/SortByNameButton';
import SortButton from './sorting/SortButton';


function ParticipantsList({settings, meeting, searchText = ''}) {
  const {is_auto, participants} = meeting;
  const [sortOptions, setSortOptions] = useState({
    property: SortProperty.FULL_NAME,
    order: SortOrder.ASC,
  });

  const filteredParticipants = useMemo(() => {
    if (!searchText) {
      return participants;
    }

    return participants.filter(p => p.display_name.toLowerCase().includes(searchText.toLowerCase()));
  }, [participants, searchText]);

  const sortedParticipants = useMemo(() => {
    return [...filteredParticipants].sort((a, b) => {
      try {
        if (sortOptions.property === SortProperty.FULL_NAME) {
          return sortOptions.order === SortOrder.ASC ?
            a.display_name.localeCompare(b.display_name) :
            b.display_name.localeCompare(a.display_name);
        } else if (sortOptions.property === SortProperty.FIRST_NAME || sortOptions.property === SortProperty.LAST_NAME) {
          const [aFirst, ...aRest] = a.display_name.trim().split(/\s+/);
          const [bFirst, ...bRest] = b.display_name.trim().split(/\s+/);
          const aLast = aRest.length > 0 ? aRest[aRest.length - 1] : '';
          const bLast = bRest.length > 0 ? bRest[bRest.length - 1] : '';

          const aValue = sortOptions.property === SortProperty.FIRST_NAME ? aFirst : aLast;
          const bValue = sortOptions.property === SortProperty.FIRST_NAME ? bFirst : bLast;

          return sortOptions.order === SortOrder.ASC ?
            aValue.localeCompare(bValue) :
            bValue.localeCompare(aValue);
        } else if (sortOptions.property === SortProperty.FIRST_SEEN_AT) {
          return sortOptions.order === SortOrder.ASC ?
            a.first_seen_at - b.first_seen_at :
            b.first_seen_at - a.first_seen_at;
        } else if (sortOptions.property === SortProperty.TIME_IN_CALL) {
          return sortOptions.order === SortOrder.ASC ?
            a.time_in_call - b.time_in_call :
            b.time_in_call - a.time_in_call;
        }
      } catch (e) {
        console.warn('Issue occurred while sorting participants');
      }

      return 0;
    });
  }, [filteredParticipants, sortOptions]);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full border rounded shadow">
          <div className="overflow-hidden border-b">
            <table className="min-w-full divide-y">
              <thead className="bg-gray-100 text-gray-500">
              <tr>
                <th className="px-6 py-1 text-xs text-left">
                  <SortByNameButton
                    sortOrder={
                      sortOptions.property.indexOf('_name') !== -1 ?
                        sortOptions.order :
                        SortOrder.NONE
                    }
                    onSortChange={(nextSortProperty, nextSortOrder) => {
                      setSortOptions({
                        property: nextSortProperty,
                        order: nextSortOrder,
                      });
                    }}
                  />
                </th>
                <th className="px-6 py-1 text-xs">
                  <SortButton
                    sortProperty={SortProperty.FIRST_SEEN_AT}
                    sortOrder={sortOptions.property === SortProperty.FIRST_SEEN_AT ? sortOptions.order : SortOrder.NONE}
                    onSortChange={(nextSortProperty, nextSortOrder) => {
                      setSortOptions({
                        property: nextSortProperty,
                        order: nextSortOrder,
                      });
                    }}
                  >
                    <Trans i18nKey="meetings.table_columns.first_seen_at">
                      First seen at
                    </Trans>
                  </SortButton>
                </th>
                {
                  meeting.is_auto &&
                  <th className="px-6 py-1 text-xs font-medium uppercase tracking-wider text-center">
                    <SortButton
                      sortProperty={SortProperty.TIME_IN_CALL}
                      sortOrder={sortOptions.property === SortProperty.TIME_IN_CALL ? sortOptions.order : SortOrder.NONE}
                      onSortChange={(nextSortProperty, nextSortOrder) => {
                        setSortOptions({
                          property: nextSortProperty,
                          order: nextSortOrder,
                        });
                      }}
                    >
                      <div>
                        <Trans i18nKey="meetings.table_columns.time_in_call">
                          Time in call
                        </Trans>
                      </div>
                      <small>
                        <Trans i18nKey="meetings.table_columns.duration_format">
                          (hh:mm:ss)
                        </Trans>
                      </small>
                    </SortButton>
                  </th>
                }
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {
                sortedParticipants.map((item) => (
                  <tr key={item.display_name} className="transition-colors duration-300 ease-in-out hover:bg-slate-100">
                    <td className="px-3 py-2">
                      <div className="flex items-center">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={item.picture_url ? item.picture_url : DefaultProfile}
                          alt={item.display_name}
                        />
                        <div className="ml-3">
                          { item.display_name }
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-2 text-center">
                      { moment(item.first_seen_at).format(settings.time_format) }
                    </td>
                    {
                      is_auto &&
                      <td className="px-3 py-2 text-center">
                        { getTimeString(item.time_in_call) }
                      </td>
                    }
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParticipantsList;
