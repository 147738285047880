import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import MeetingsTable from '../components/MeetingsTable';
import { bulkDeleteMeetingsByIds, fetchMeetings } from '../meetings/actions';
import { PAGE_SIZE } from '../meetings/meetingsReducer';
import { ReactComponent as LoaderSVG } from '../assets/imgs/loader.svg';
import ActionBar from '../components/ActionBar';
import { exportMeetings } from '../misc/utils';
import ExtNotInstalledBanner from '../components/ExtNotInstalledBanner';
import MeetingsBackupBanner from '../components/MeetingsBackupBanner';
import AdSlot from '../components/AdSlot';
import SEO from '../components/common/SEO';
import useConfirm from '../components/common/ConfirmDialog';
import { MAX_MEETING_DURATION_MS } from '../config';


function MeetingsPage({ dispatch, user, settings, meetings, unsyncedMeetings, meetingsInProgress, page, status }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(true);
  const activePage = Math.max(1, parseInt(searchParams.get('page') || 0));
  const searchText = searchParams.get('search') || '';
  const [selectedMeetingIds, setSelectedMeetingIds] = useState(new Set());
  const confirm = useConfirm();

  useEffect(() => {
    const stateActivePage = page.offset / page.limit + 1;

    if (status === 'idle' && (page.count === -1 || stateActivePage !== activePage || searchText !== page.search)) {
      // fetch meetings from API
      dispatch(fetchMeetings({
        limit: PAGE_SIZE,
        offset: PAGE_SIZE * (activePage - 1),
        search: searchText,
      }));

      // reset selected meeting IDs
      setSelectedMeetingIds(new Set());
    }
  }, [dispatch, activePage, searchText, page, status]);

  useEffect(() => {
    setLoading(status === 'pending');
  }, [status]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-10">
        <LoaderSVG className="animate-spin h-10 w-10 text-blue-600" aria-hidden="true" />
      </div>
    )
  }

  const totalUnsyncedMeetings = user.is_paid ? unsyncedMeetings.length : page.count;
  const sortedMeetings = [
    // filter out in progress meetings by search text
    ...(searchText ? meetingsInProgress.filter((m) => JSON.stringify(m).toLowerCase().includes(searchText.toLowerCase())) : meetingsInProgress),

    // API meetings
    ...meetings
  ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  /**
   * Handler that filters the selected meetings and exports CSVs as ZIP archive
   * @returns {Promise<*>}
   */
  const bulkDownloadCSV = async () => {
    const meetingsToExport = sortedMeetings.filter((m) => selectedMeetingIds.has(m.local_key));
    return exportMeetings(meetingsToExport, 'csv');
  }

  const bulkDownloadXLSX = async () => {
    const meetingsToExport = sortedMeetings.filter((m) => selectedMeetingIds.has(m.local_key));
    return exportMeetings(meetingsToExport, 'xlsx');
  }

  const bulkDelete = async (e) => {
    e.stopPropagation();

    let hasMeetingsInProgress = false;
    const meetingIdsToDelete = sortedMeetings.filter((m) => {
      // only delete selected meetings
      if (!selectedMeetingIds.has(m.local_key)) {
        return false;
      }

      // check if there are in progress meetings
      if (!m.ended_at) {
        hasMeetingsInProgress = true;
      }

      const endedAt = new Date(m.ended_at || Date.now()).getTime();
      const createdAt = new Date(m.created_at).getTime();
      const meetingDuration = endedAt - createdAt;

      // should be able to delete a meeting if is ended or if it takes longer than 8 hours,
      // e.g. got stuck in progress
      return m.ended_at || meetingDuration >= MAX_MEETING_DURATION_MS;
    }).map(m => m.local_key);
    const meetingsCount = meetingIdsToDelete.length;

    const ok = await confirm({
      type: 'error',
      title: t('modals.remove_meeting.title'),
      description: (
        <Trans i18nKey="modals.bulk_remove_meeting.description">
          Are you sure you want to delete <b>{{ meetingsCount }} meetings</b>? This action cannot be undone.
        </Trans>
      ),
      confirmBtnLabel: t('modals.remove_meeting.cta_button'),
    });

    if (ok) {
      const resp = dispatch(bulkDeleteMeetingsByIds(meetingIdsToDelete, hasMeetingsInProgress));
      if (resp) {
        setSelectedMeetingIds(new Set());
      }
    }
  }

  return (
    <div className="mb-5">
      <SEO
        title="Meetings History | MeetList.io"
      />
      {/*<SyncMeetings />*/}

      {/* show extension not installed banner, if no ext ID was injected in DOM */}
      <ExtNotInstalledBanner />

      {
        totalUnsyncedMeetings > 0 ? (
          <MeetingsBackupBanner
            totalMeetings={totalUnsyncedMeetings}
          />
        ) : null
      }

      <ActionBar
        searchPlaceholder={t('meetings.search_all.placeholder')}
        onExportCSV={bulkDownloadCSV}
        onExportXLSX={bulkDownloadXLSX}
        exportButtonVisible={selectedMeetingIds.size > 0}
        onDelete={bulkDelete}
        deleteButtonVisible={selectedMeetingIds.size > 0}
        selectedMeetingsCount={selectedMeetingIds.size}
      />

      <MeetingsTable
        settings={settings}
        meetings={sortedMeetings}
        activePage={activePage}
        page={page}
        selectedMeetingIds={selectedMeetingIds}
        setSelectedMeetingIds={setSelectedMeetingIds}
      />

      <AdSlot
        zone="sticky"
        className="hidden md:block"
      />
    </div>
  );
}

export default connect((state) => ({
  user: state.userReducer.user,
  settings: state.userReducer.settings,
  meetingsInProgress: state.meetingsReducer.meetingsInProgress,
  unsyncedMeetings: state.meetingsReducer.unsyncedMeetings,
  status: state.meetingsReducer.status,
  page: state.meetingsReducer.page,
  meetings: state.meetingsReducer.meetings,
}))(MeetingsPage);
