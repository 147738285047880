export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: null,
};


export const SortProperty = {
  FULL_NAME: 'full_name',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  FIRST_SEEN_AT: 'first_seen_at',
  TIME_IN_CALL: 'time_in_call',
}
