import React from 'react';
import SEO from '../components/common/SEO';
import PublicLayout from './common/PublicLayout';
import Pricing from '../components/Pricing';
import FAQ from '../components/FAQ';
import CTA from '../components/CTA';


export default function PricingPage() {
  return (
    <PublicLayout className="my-2">
      <SEO
        title="Simple Pricing: Start for free, upgrade anytime for advanced features | MeetList.io"
        description="Explore the pricing options, including Free and Pro plans. The Pro plan offers features like backup, advanced exports, analytics, and more for efficient Google Meet attendance tracking."
      />

      <Pricing />
      <FAQ />
      <CTA />
    </PublicLayout>
  );
}
