import React from 'react';


/**
 *
 * @param {[{color:string, value: any}]} payload - The data to be displayed in the legend
 * @param {Function} formatter - Format the legend name e.g. i18n
 * @returns {Element}
 */
export function CustomLegend({ payload, formatter }) {
  return (
    <div className="flex items-center pb-2">
      {
        payload.map((entry, index) => (
          <div
            key={`legend-${index}`}
            className="flex gap-1 items-center text-xs text-gray-500 pr-5"
          >
            <div
              className="w-3 h-3 rounded"
              style={{
                backgroundColor: entry.color,
              }}
            />
            {
              formatter ?
                formatter(entry.value) :
                entry.value
            }
          </div>
        ))
      }
    </div>
  );
}
