import { client } from '../misc/api';
import { SET_STATS } from './actionTypes';
import { sendExtRPCMessage } from '../misc/ext';
import { PAGE_SIZE } from '../meetings/meetingsReducer';


/**
 * Fetch the stats for the meetings
 *
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export function fetchStats() {
  return async (dispatch) => {
    try {
      const res = await client.get('/stats/');
      if (res.ok) {
        return dispatch({
          type: SET_STATS,
          payload: res.data,
        });
      } else {
        console.error(`Failed to get stats.`, res);
        throw new Error(`Failed to get stats. HTTP status=${res.status}`);
      }
    } catch (e) {
      console.error('Error while getting the stats', e);
    }
  };
}


/**
 * Get the last 7 days, excluding today
 * @returns {*[]}
 */
function getLast7Days() {
  const last7Days = [];

  for (let i = 7; i >= 1; i--) {
    let date = new Date();
    date.setDate(date.getDate() - i);
    let dateString = date.toISOString().split('T')[0];
    last7Days.push(dateString);
  }

  return last7Days;
}


/**
 * Generate the summary data for the meetings
 *
 * @param {string[]} days - The days for which the data is generated
 * @param {[{createdAt: number, calendarEventId: string}]} meetings - The meetings array received from GMAL
 * @returns {{unit: string, data: *[], total_meetings: number, config: [{stack_id: string, type: string, data_key: string},{stack_id: string, type: string, data_key: string}]}}
 */
function getSummaryData(days, meetings) {
  const data = [];
  let totalMeetings = 0;
  const meetingsByDay = {};

  // Breakdown the meetings by day and type: scheduled or adhoc
  for (const meeting of meetings) {
    let createdAt = new Date(meeting.createdAt);
    createdAt = createdAt.toISOString().split('T')[0];
    meetingsByDay[createdAt] = meetingsByDay[createdAt] ?? { scheduled: 0, adhoc: 0 };
    if (meeting.calendarEventId) {
      meetingsByDay[createdAt].scheduled += 1;
    } else {
      meetingsByDay[createdAt].adhoc += 1;
    }
  }

  // Iterate over the days and generate the data points and total,
  // if there are no meetings for a day, add 0
  for (let day of days) {
    const scheduled = meetingsByDay[day]?.scheduled ?? 0;
    const adhoc = meetingsByDay[day]?.adhoc ?? 0;
    totalMeetings += scheduled + adhoc;

    data.push({
      label: day,
      scheduled: scheduled,
      adhoc: adhoc,
    });
  }

  return {
    unit: 'day',
    total_meetings: totalMeetings,
    data,
    config: [
      {
        type: 'bar',
        data_key: 'scheduled',
        stack_id: 'a'
      },
      {
        type: 'bar',
        data_key: 'adhoc',
        stack_id: 'a'
      },
    ]
  };
}


/**
 * Fetch the demo stats for the meetings. Only the meetings summary is fetched,
 * the other charts are not available and the upsell message is shown
 *
 * @returns {function(*): Promise<*>}
 */
export function fetchDemoStats() {
  return async (dispatch) => {
    const payload = {};
    const last7Days = getLast7Days();
    const extResponse = await sendExtRPCMessage('meetings.getPage', {
      limit: PAGE_SIZE,
      offset: 0,
      search: ''
    });

    // Generate the summary data for the meetings or empty state
    payload.meetings_summary = getSummaryData(last7Days, extResponse?.results || []);

    return dispatch({
      type: SET_STATS,
      payload,
    });
  };
}
