import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { GOOGLE_REDIRECT_URI } from '../config';


export const handleLoginWithGoogle = () => {
  const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  const scope = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile'
  ].join(' ');

  const params = {
    response_type: 'code',
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: GOOGLE_REDIRECT_URI,
    prompt: 'select_account',
    access_type: 'offline',
    scope
  };

  try {
    const searchParams = new URLSearchParams(window.location.search);
    const nextPath = searchParams.get('next');

    // Pass the next path to the Google OAuth2 url, using the state parameter.
    // This exact value will be sent back to the callback URL
    if (nextPath) {
      params.state = encodeURIComponent(nextPath);
    }
  } catch (e) {
    // no-op
  }

  const urlParams = new URLSearchParams(params).toString();
  window.location = `${googleAuthUrl}?${urlParams}`;
};


/**
 * Wait until milliseconds passed, before continuing
 *
 * @param ms Number of milliseconds to wait
 * @returns {Promise<unknown>}
 */
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


/**
 * Choose a random value from given array
 *
 * @param arr {array}
 * @returns {*}
 */
export function randChoice(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}


/**
 * Convert Milliseconds to hh:mm:ss format.
 *
 * @param ms {number}
 * @param delim
 * @returns {string}
 */
export function getTimeString(ms, delim = ':') {
  const showWith0 = value => (value < 10 ? `0${value}` : value);
  const hours = showWith0(Math.floor((ms / (1000 * 60 * 60)) % 60));
  const minutes = showWith0(Math.floor((ms / (1000 * 60)) % 60));
  const seconds = showWith0(Math.floor((ms / 1000) % 60));
  return `${parseInt(hours) ? hours : '00'}${delim}${minutes}${delim}${seconds}`;
}


/**
 * Calculate punctuality of the meeting.
 *
 * @param {string} createdAt - The ISO date string when the meeting was created
 * @param {string} scheduledStartAt - The ISO date string when the meeting was scheduled to start
 * @returns {{isLate: boolean}|{displayText: string, isLate: boolean}}
 */
export function calculatePunctuality(createdAt, scheduledStartAt) {
  const difference = moment.duration(moment(scheduledStartAt).diff(moment(createdAt)));

  const hoursDifference = Math.abs(difference.hours());
  const minutesDifference = Math.abs(difference.minutes());
  const secondsDifference = Math.abs(difference.seconds());

  const isLate = difference.asMinutes() < 0;

  if (difference.asMinutes() !== 0) {
    if (hoursDifference > 0) {
      return {
        displayText: `${hoursDifference}h ${minutesDifference}m`,
        isLate,
      }
    } else if (minutesDifference > 0) {
      return {
        displayText: `${minutesDifference}m ${secondsDifference}s`,
        isLate,
      };
    }
  }

  return {
    isLate: false,
  };
}


/**
 * Convert Date object into YYYY-MM-DD_hh-mm-ss format.
 *
 * @param {Date} now
 * @returns {string}
 */
function getFormattedDateString(now) {
  const date = now.toLocaleDateString().replace(/[/]/g, '-');
  const time = now.toLocaleTimeString().replace(/:/g, '-');
  return `${date}_${time}`;
}


/**
 * Create an array of certain length and set the elements within it from
 * start value to end value.
 *
 * @param start {number}
 * @param end {number}
 * @returns {unknown[]}
 */
export function range(start, end) {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
}


/**
 * Create a blob with CSV data
 *
 * @param {object} meeting - The meeting object
 * @returns {{filename: string, blob: Blob}}
 */
function createBlobCSV(meeting) {
  const { name, code, is_auto, participants, created_at, ended_at } = meeting;
  const rows = [
    [`*     ${name}`],
    [`*     Meeting code: ${code}`],
    [`*     Created on ${moment(created_at).format('YYYY-MM-DD HH:mm:ss')}`],
    [`*     Ended on ${moment(ended_at).format('YYYY-MM-DD HH:mm:ss')}`],
    is_auto ? ['Full Name', 'First Seen', 'Time in Call'] : ['Full Name'],
  ];

  for (const participant of participants) {
    if (is_auto) {
      rows.push([
        participant.display_name,
        moment(participant.first_seen_at).format('YYYY-MM-DD HH:mm:ss'),
        getTimeString(participant.time_in_call)
      ]);
    } else {
      rows.push([
        participant.display_name,
      ])
    }
  }

  let content = '';
  for (const row of rows) {
    content += `"${row.join('","')}"\r\n`;
  }

  // Create the blob CSV data
  // Note: the browser will automatically convert UTF-16 U+FEFF to EF BB BF use String.fromCharCode instead
  // See: https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js#L29
  const BOM = String.fromCharCode(0xFEFF);
  const blob = new Blob([BOM, content], { type: 'text/csv;charset=utf-8' });

  // generate filename
  const filename = `meeting_${ getFormattedDateString(new Date(created_at)) }_${code}.csv`;

  return {
    filename,
    blob,
  }
}


/**
 * Create Blob object of meeting XLSX file
 *
 * @param {object} meeting
 * @returns {any}
 */
function createBlobXLSX(meeting) {
  const workbook = utils.book_new();

  // Sheet 1 contains participants
  const participantRows = meeting.participants.map((p) => {
    if (meeting.is_auto) {
      return {
        'Full Name': p.display_name,
        'First Seen': moment(p.first_seen_at).format('YYYY-MM-DD HH:mm:ss'),
        'Time in Call': getTimeString(p.time_in_call),
      }
    }

    return {
      'Full Name': p.display_name,
    };
  });
  const sheet1 = utils.json_to_sheet(participantRows);
  utils.book_append_sheet(workbook, sheet1, 'Participants');

  /* calculate column width for Sheet1 */
  const maxWidth = meeting.participants.reduce((w, p) => Math.max(w, p.display_name.length), 10);
  sheet1['!cols'] = meeting.is_auto ? [{ wch: Math.max(10, maxWidth) }, { wch: 20 }, { wch: 12 }] : [{ wch: maxWidth }];

  // Sheet 2 contains details about the meeting e.g. meeting name and code
  const sheet2 = utils.aoa_to_sheet([
    ['Name', meeting.name],
    ['Code', meeting.code],
    ['Auto', meeting.is_auto],
    ['Created at', moment(meeting.created_at).format('YYYY-MM-DD HH:mm:ss')],
    ['Ended at', moment(meeting.ended_at).format('YYYY-MM-DD HH:mm:ss')],
  ]);
  utils.book_append_sheet(workbook, sheet2, 'Meeting_Details');

  /* calculate column width for Sheet2 */
  sheet2['!cols'] = [{ wch: 10 }, { wch: Math.max(20, meeting.name.length) }]

  // create the blob XLSX data
  const u8 = write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([u8], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  // generate filename
  const filename = `meeting_${ getFormattedDateString(new Date(meeting.created_at)) }_${ meeting.code }.xlsx`;

  return {
    filename,
    blob,
  }
}


/**
 * Export CSV/XLSX files as ZIP archive
 *
 * @param {array} meetings
 * @param {("csv"|"xlsx")} [contentFormat="csv"]
 * @returns {Promise<*>}
 */
async function exportZip(meetings, contentFormat = 'csv') {
  const zip = new JSZip();
  for (const m of meetings) {
    // create the blob with data
    const { filename, blob } = contentFormat === 'csv' ? createBlobCSV(m) : createBlobXLSX(m);

    // append file to ZIP archive
    zip.file(filename, blob);
  }

  // download ZIP archive
  const blob = await zip.generateAsync({ type: 'blob' });
  return saveAs(blob, 'meetings-history.zip');
}


/**
 * Export CSV/XLSX content or ZIP. If meetings length is 1, export a single CSV/XLSX file else export ZIP archive.
 *
 * @param {array} meetings - List of meetings to export
 * @param {("csv"|"xlsx")} [contentFormat="csv"]
 * @returns {Promise<void|*>}
 */
export async function exportMeetings(meetings, contentFormat = 'csv') {
  if (meetings.length === 1) {
    // create XLSX blob and export as single file
    const meeting = meetings[0];
    const { filename, blob } = contentFormat === 'csv' ? createBlobCSV(meeting) : createBlobXLSX(meeting);
    return saveAs(blob, filename);
  }

  // export as ZIP
  return exportZip(meetings, contentFormat);
}


/**
 * Debounce function to limit the rate of execution of a function.
 *
 * @param {Function} func - The function to debounce
 * @param {Number} waitMs - The number of milliseconds to wait before executing the function
 * @returns {(function(...[*]): void)|*}
 */
export function debounce(func, waitMs) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), waitMs);
  };
}


/**
 * Similar to frontmatter, extract metadata and content from text
 * e.g.
 * ---
 * title: "Hello World"
 * date: "2021-09-01"
 * author: "John Doe"
 * ---
 * Lorem ipsum dolor sit amet, consectetur adipiscing elit.
 *
 * @param {string} text - The text to extract metadata from
 * @returns {{[p: string]: any}}
 */
export function getMetadataAndContent(text) {
  const [rawMetadata, content] = text.split('---\n').slice(1);
  const metadata = Object.fromEntries(
    rawMetadata.trim().split('\n').map(line => line.split(': '))
  );
  return [metadata, content]
}
