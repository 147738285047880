import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';


const LANGUAGES = {
  en: '🇬🇧 English',
  es: '🇪🇸 Español',
  tr: '🇹🇷 Türkçe',
  uk: '🇺🇦 Українська',
};

export default function LanguageSelector({ className }) {
  const { i18n } = useTranslation();
  const currentLanguage = LANGUAGES[i18n.resolvedLanguage] || i18n.resolvedLanguage;

  return (
    <Menu as="div" className={clsx('relative inline-block text-left', className)}>
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm whitespace-nowrap font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          { currentLanguage }
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {
              Object.entries(LANGUAGES).map(([code, name]) => (
                <Menu.Item key={code}>
                  {({ active }) => (
                    <div
                      className={clsx(
                        'block px-4 py-2 text-sm cursor-pointer',
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      )}
                      onClick={() => i18n.changeLanguage(code)}
                    >
                      {name}
                    </div>
                  )}
                </Menu.Item>
              ))
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
