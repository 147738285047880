import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';

import { ReactComponent as LogoSVG } from '../../assets/imgs/logo.svg';
import { getExtVersion } from '../../misc/ext';


export default function Menu() {
  const [isDrawerOpen, setDrawer] = useState(false);
  const navigate = useNavigate();

  return (
    <header className="container mx-auto z-10 top-0 pt-6 pb-6 w-full">
      <nav className="flex items-center justify-between flex-wrap">
        <div className="flex items-center md:gap-x-12">
          <Link to="/" aria-label="home">
            <LogoSVG style={{ height: 48 }} />
          </Link>
          <div className="hidden md:flex md:gap-x-6">
            <a
              className="rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900"
              href="/#features"
            >
              Features
            </a>
            <a
              className="rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900"
              href="/#testimonials"
            >
              Testimonials
            </a>
            <a
              className="rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900"
              href="/pricing"
            >
              Pricing
            </a>
            <Link
              to="/blog"
              className="rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900"
            >
              Blog
            </Link>
          </div>
        </div>
        <div className="flex items-center gap-x-5 md:gap-x-8">
          <Link
            to="/login"
            className="rounded-lg py-1 px-2 text-slate-700 hover:bg-slate-100 hover:text-slate-900 hidden md:block"
          >
            Log in
          </Link>
          {
            getExtVersion() ? (
              <button
                onClick={() => navigate('/meetings')}
                className="primary"
              >
                Open dashboard
              </button>
            ) : (
              <Link to="/signup" className="primary">
                Get started
              </Link>
            )
          }
          <div className="md:hidden">
            <button
              onClick={() => { setDrawer(!isDrawerOpen); }}
              className="flex items-center px-3 py-2 focus:outline-none focus:bg-transparent"
            >
              <Bars3Icon className="w-5 h-5" />
            </button>
          </div>
        </div>

        <Transition
          show={isDrawerOpen}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-75"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-75"
          leaveTo="opacity-0"
        >
          <div
            className="z-10 fixed inset-0 bg-black"
            onClick={() => { setDrawer(false) }}
          />
        </Transition>

        <aside
          className={`
            bg-white transform top-0 right-0 w-64 bg-white fixed h-full overflow-auto
            ease-in-out transition-all duration-500 z-30
            ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'}
          `}
          style={{visibility : isDrawerOpen ? 'visible': 'hidden'}}
        >
          <div className="flex justify-start mt-4 ml-4">
            <button onClick={() => {setDrawer(!isDrawerOpen);}}
                    className="text-2xl leading-none select-none focus:outline-none">
              &times;
            </button>
          </div>
          <ul className="list-reset flex flex-col items-center mt-12 list-none">
            <li className="mr-3 pb-6 lg:pb-0">
              <a href="/#features" onClick={() => { setDrawer(!isDrawerOpen) }} className="lg:mr-6 select-none">
                Features
              </a>
            </li>
            <li className="mr-3 pb-6 lg:pb-0">
              <a href="/#testimonials" onClick={() => { setDrawer(!isDrawerOpen) }} className="lg:mr-6 select-none">
                Testimonials
              </a>
            </li>
            <li className="mr-3 pb-6 lg:pb-0">
              <Link to="/pricing" className="lg:mr-6 select-none">
                Pricing
              </Link>
            </li>
            <li className="mr-3 pb-6 lg:pb-0">
              <a href="/#faq" onClick={() => { setDrawer(!isDrawerOpen) }} className="lg:mr-6 select-none">
                FAQ
              </a>
            </li>
            <li className="mr-3 pb-6 lg:pb-0">
              <Link to="/blog" className="lg:mr-6 select-none">
                Blog
              </Link>
            </li>
            <li className="mr-3 pb-6 lg:pb-0">
              <Link to="/terms-and-conditions" onClick={() => { setDrawer(!isDrawerOpen) }} className="lg:mr-6 select-none">
                Terms and Conditions
              </Link>
            </li>
            <li className="mr-3 pb-6 lg:pb-0">
              <Link to="/privacy-policy" onClick={() => { setDrawer(!isDrawerOpen) }} className="lg:mr-6 select-none">
                Privacy Policy
              </Link>
            </li>
            <li className="mr-3">
              {
                getExtVersion() ? (
                  <button
                    onClick={() => navigate('/meetings')}
                    className="primary select-none"
                  >
                    Open dashboard
                  </button>
                ) : (
                  <Link to="/signup" className="primary select-none">
                    Get started
                  </Link>
                )
              }
            </li>
          </ul>
        </aside>
      </nav>
    </header>
  );
};
