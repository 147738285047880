import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';


export default function BlogSidebar({ tagName, className }) {
  return (
    <div className={className}>
      <h1 className="text-5xl py-5">
        Read and learn.
      </h1>

      <p className="text-gray-500">
        Mastering meetings: insights, tips, and tutorials.
      </p>

      <div className="mt-5">
        <Link
          to="/blog"
          className={clsx(
            'btn-flat block my-1',
            !tagName || tagName === 'all' ? 'text-blue-500' : 'text-gray-500',
            'hover:text-blue-500'
          )}
        >
          Show all
        </Link>
        <Link
          to="/blog/tags/google-meet"
          className={clsx(
            'btn-flat block my-1',
            tagName === 'google-meet' ? 'text-blue-500' : 'text-gray-500 ',
            'hover:text-blue-500'
          )}
        >
          Google Meet
        </Link>
        <Link
          to="/blog/tags/chrome-extensions"
          className={clsx(
            'btn-flat block my-1',
            tagName === 'chrome-extensions' ? 'text-blue-500' : 'text-gray-500 ',
            'hover:text-blue-500'
          )}
        >
          Chrome Extensions
        </Link>
        <Link
          to="/blog/tags/indie-projects"
          className={clsx(
            'btn-flat block my-1',
            tagName === 'indie-projects' ? 'text-blue-500' : 'text-gray-500 ',
            'hover:text-blue-500'
          )}
        >
          Indie Projects
        </Link>
      </div>
    </div>
  )
}
