import React from 'react';

import PublicLayout from './common/PublicLayout';
import AttendanceSVG from '../assets/imgs/attendance-for-google-meet.svg';
import FeaturesPNG from '../assets/imgs/features.png';
import { PlayCircleIcon } from '@heroicons/react/24/outline';
import { StarIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import { GlobeEuropeAfricaIcon, VideoCameraIcon } from '@heroicons/react/24/solid';
import FAQ from '../components/FAQ';
import CTA from '../components/CTA';
import Testimonials from '../components/Testimonials';
import { Link } from 'react-router-dom';
import { CHROME_STORE_URL } from '../config';
import Pricing from '../components/Pricing';
import SEO from '../components/common/SEO';


export default function HomePage() {
  return (
    <PublicLayout className="mt-16">
      <SEO />

      <section className="container mx-auto flex items-center justify-between mt-10 px-3">
        <div className="max-w-xl">
          <h1 className="font-display text-5xl mb-5 leading-tight">
            Attendance <span className="whitespace-nowrap text-blue-1">made simple</span> for online meetings.
          </h1>
          <p className="leading-relaxed text-gray-500">
            Your hub for taking attendances professionally and efficiently of your meetings,
            eliminating the hassle of surveys or name-calling, so you can get back to work.
          </p>
          <div className="mt-10 flex items-center space-x-5">
            <Link to="/signup" className="primary px-10 py-3 text-lg">
              Get started
            </Link>
            <a
              className="flat text-lg flex items-center"
              href="https://www.youtube.com/watch?v=S872VY_mT4M"
              target="_blank"
              rel="noreferrer"
            >
              <PlayCircleIcon className="w-7 h-7 mr-2" aria-hidden="true" />
              Watch video
            </a>
          </div>
          <div className="mt-10">
            <div className="flex items-center text-yellow-300">
              <StarIcon className="w-5 h-5" aria-hidden="true" />
              <StarIcon className="w-5 h-5" aria-hidden="true" />
              <StarIcon className="w-5 h-5" aria-hidden="true" />
              <StarIcon className="w-5 h-5" aria-hidden="true" />
              <StarIcon className="w-5 h-5" aria-hidden="true" />
            </div>
            <div className="mt-2 text-gray-500">
              4.7/5 (1,600+ reviews on {' '}
              <a
                href={ CHROME_STORE_URL }
                target="_blank"
                rel="noreferrer"
                className="underline decoration-solid hover:opacity-75"
              >
                Chrome Web Store
              </a>)
            </div>
          </div>
        </div>
        <div className="mx-auto hidden md:block">
          <img
            src={AttendanceSVG}
            className="w-4/5 mx-auto"
            alt="Attendance for Google Meet"
            width="80%"
            height="100%"
          />
        </div>
      </section>

      <section className="bg-gray-100 py-10 px-3 mt-20">
        <div className="container mx-auto">
          <div className="flex space-x-16">
            <div className="w-1/2">
              <div className="text-blue-600 font-bold">
                Trusted Worldwide
              </div>
              <h2 className="text-2xl my-5">
                Trusted by over 350.000 people, that are creating over 200 thousand meetings per day, every day.
              </h2>
              <p className="text-gray-500">
                We continuously work to improve and add new features for you, so that you can focus on what matters
                during your meetings.
              </p>
            </div>
            <div className="w-1/2">
              <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                <div>
                  <UserGroupIcon className="w-10 h-10 text-blue-600" aria-hidden="true" />
                  <h3 className="mb-2 text-2xl font-bold">350k+ Users</h3>
                  <p className="text-gray-600">
                    Trusted by over 350 thousand users around the world.
                  </p>
                </div>
                <div>
                  <GlobeEuropeAfricaIcon className="w-10 h-10 text-blue-600" aria-hidden="true" />
                  <h3 className="mb-2 text-2xl font-bold">100+ countries</h3>
                  <p className="text-gray-600">
                    Have used Meet List to record their meetings attendance.
                  </p>
                </div>
                <div>
                  <VideoCameraIcon className="w-10 h-10 text-blue-600" aria-hidden="true" />
                  <h3 className="mb-2 text-2xl font-bold">200k+ Meetings</h3>
                  <p className="text-gray-600">
                    Recording attendance for over 200k meetings, per day, every day.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="container mx-auto py-16 px-3">
        <div className="items-center block sm:flex gap-8">
          <div>
            <img src={FeaturesPNG} className="w-full" alt="feature dashboard" />
          </div>
          <div>
            <h2 className="text-4xl mt-5 mb-5">
              Awesome features awaiting for you
            </h2>
            <p className="text-gray-500 mb-5">
              Our goal is to help you reach your full potential during the day. So, how can we help? By using Meet
              List you get a set of features which improve your experience during meetings.
            </p>

            <ul className="list-disc pl-4 text-blue-600">
              {
                [
                  {
                    name: 'Safe and synced',
                    description: 'We automatically save your meeting attendance online, so you never lose them.',
                  },
                  {
                    name: 'Access from anywhere',
                    description: `Tired of not having the right info handy when you need it? You can access
                      the details from all your devices.`,
                  },
                  {
                    name: 'Export for offline',
                    description: `No signal where you're going? No problem. You can always download the attendance,
                      so you have it with you even if you're nowhere near a Wi-Fi or mobile data signal.`,
                  }
                ].map((item) => (
                  <li
                    key={item.name}
                    className="my-6"
                  >
                    <p className="font-medium mb-1">
                      {item.name}
                    </p>
                    <p className="text-gray-500">
                      {item.description}
                    </p>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </section>

      <CTA />
      <Testimonials />
      <Pricing />
      <FAQ />

    </PublicLayout>
  );
}
