import React, { Fragment, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import SearchInput from './common/SearchInput';
import ProFeatureAction from './common/ProFeatureAction';


export default function ActionBar({
  // Search input
  searchPlaceholder,
  searchOnChange,

  // Meeting export buttons
  onExportCSV,
  onExportXLSX,
  onExportPDF,
  exportButtonVisible = false,

  // Meeting delete button
  onDelete,
  deleteButtonVisible = false,

  // Selected meetings count
  selectedMeetingsCount,
}) {
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState('');

  // make sure that if the URL changes, it is reflected in the search input value
  useEffect(() => {
    const searchParam = searchParams.get('search') || '';
    if (searchParam !== searchText) {
      setSearchText(searchParam);
    }
  }, [searchText, searchParams]);

  return (
    <div className="flex justify-between items-center gap-5 bg-gray-100 px-3 py-3 mb-5 border rounded print:hidden">
      <div className="flex-1">
        {
          searchOnChange ? (
            <SearchInput
              className="flex-auto max-w-md"
              placeholder={searchPlaceholder}
              onChange={searchOnChange}
            />
          ) : (
            <form className="flex items-strech justify-start">
              <SearchInput
                className="flex-auto max-w-md"
                placeholder={searchPlaceholder}
                defaultValue={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
              <ProFeatureAction
                as="button"
                className="primary ml-2"
              >
                <Trans i18nKey="meetings.search_all.cta_button">
                  Search
                </Trans>
                <ProFeatureAction.Badge />
              </ProFeatureAction>
            </form>
          )
        }
      </div>
      <div className="flex justify-end items-center gap-2">
        {
          selectedMeetingsCount > 0 && (
            <>
              <div className="text-sm text-gray-500 border-r-2 border-gray-200 py-1 pr-3 mr-3">
                <Trans i18nKey="meetings.selected_count">
                  {{selectedMeetingsCount}} selected
                </Trans>
              </div>
            </>
          )
        }
        <button
          onClick={onDelete}
          className={clsx('btn-danger', !deleteButtonVisible && 'hidden')}
        >
          <Trans i18nKey="common.delete"/>
        </button>
        <Menu as="div" className={clsx(`relative inline-block text-left`, !exportButtonVisible && 'hidden')}>
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md focus:outline-none primary">
              <ArrowDownTrayIcon className="mr-2 h-5 w-5" />
              <span>
                <Trans i18nKey="meetings.export.cta_button">
                  Export as
                </Trans>
              </span>
              <ChevronDownIcon
                className="ml-1 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={clsx(
                        `group flex w-full items-center rounded-md px-5 py-3 text-sm text-gray-900`,
                        active && 'hover:bg-gray-100',
                      )}
                      onClick={onExportCSV}
                    >
                      <Trans i18nKey="meetings.export.cta_button">Export as</Trans> .csv
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <ProFeatureAction
                      as="button"
                      className={clsx(
                        `group flex w-full items-center rounded-md px-5 py-3 text-sm text-gray-900`,
                        active && 'hover:bg-gray-100',
                      )}
                      onClick={onExportXLSX}
                    >
                      <Trans i18nKey="meetings.export.cta_button">Export as</Trans> .xlsx
                      <ProFeatureAction.Badge />
                    </ProFeatureAction>
                  )}
                </Menu.Item>
                {
                  onExportPDF &&
                  <Menu.Item>
                    {({active}) => (
                      <ProFeatureAction
                        as="button"
                        className={clsx(
                          `group flex w-full items-center rounded-md px-5 py-3 text-sm text-gray-900`,
                          active && 'hover:bg-gray-100',
                        )}
                        onClick={onExportPDF}
                      >
                        <Trans i18nKey="meetings.export.cta_button">Export as</Trans> .pdf
                        <ProFeatureAction.Badge />
                      </ProFeatureAction>
                    )}
                  </Menu.Item>
                }
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
