import React from 'react';
import { StarIcon } from '@heroicons/react/20/solid';

import LegardaPNG from '../assets/imgs/profile-legarda-s.png';
import AnilPNG from '../assets/imgs/profile-prof-anil-t.png';
import GarciaPNG from '../assets/imgs/profile-garcia-g.png';
import VernellePNG from '../assets/imgs/profile-vernelle-r.png';
import { CHROME_STORE_REVIEWS_URL } from '../config';


const testimonials = [
  {
    name: 'Legarda S.',
    title: `Excellent!`,
    picture: LegardaPNG,
    message: 'Excellent! So helpful. Thanks for sharing.',
    rating: 5,
  },
  {
    name: 'Prof Anil T.',
    picture: AnilPNG,
    title: `It's fantastic!`,
    message: `One of the fantastic extension for keeping the meet record I recommend to all those who use
      Google Meet and wish to keep exclusive record for the same, love it`,
    rating: 5,
  },
  {
    name: 'Gracia G.',
    picture: GarciaPNG,
    title: 'I love this extension',
    message: `It allows me, my boss and the parents to monitor my students
      attendance and punctuality in the most accurate and simplest way possible. Thanks to the
      developer. Highly recommended!!!`,
    rating: 5,
  },
  {
    name: 'Vernelle R.',
    picture: VernellePNG,
    title: `Quite helpful`,
    message: `Excellent extension, quite helpful to track students attendance for each class.`,
    rating: 5
  },
];

export default function Testimonials() {

  return (
    <section id="testimonials" aria-label="Testimonials" className="bg-gray-100 py-16 px-3">
      <div className="container mx-auto">
        <div className="text-blue-600 font-bold text-center">
          Testimonials
        </div>
        <h2 className="text-3xl text-center mt-2 mb-20">
          What our users are saying about us
        </h2>

        <div className="grid mb-8 rounded-lg md:mb-12 md:grid-cols-2 gap-8">
          {
            testimonials.map((item) => (
              <div
                key={`testimonial-${item.name}`}
                className="flex flex-col justify-center items-center p-8 text-center bg-white rounded border border-gray-200 shadow"
              >
                <blockquote className="mx-auto mb-4 max-w-2xl text-gray-500 lg:mb-8">
                  <h3 className="text-lg font-semibold text-gray-900">{ item.title }</h3>
                  <p className="my-4 font-light">{ item.message }</p>
                </blockquote>
                <div className="flex justify-center items-center space-x-3">
                  <img className="w-9 h-9 rounded-full"
                       src={item.picture}
                       alt={item.name} />
                  <div className="space-y-0.5 font-medium text-left">
                    <div>{ item.name }</div>
                    <div className="flex items-center text-yellow-300">
                      <StarIcon className="w-5 h-5" aria-hidden="true" />
                      <StarIcon className="w-5 h-5" aria-hidden="true" />
                      <StarIcon className="w-5 h-5" aria-hidden="true" />
                      <StarIcon className="w-5 h-5" aria-hidden="true" />
                      <StarIcon className="w-5 h-5" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <div className="text-center">
          <a
            className="secondary"
            href={CHROME_STORE_REVIEWS_URL}
            target="_blank"
            rel="noreferrer"
          >
            See more
          </a>
        </div>
      </div>
    </section>
  );
}
