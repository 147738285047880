import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Separator from '../components/common/Separator';
import { ReactComponent as LoaderSVG } from '../assets/imgs/loader.svg';
import { MeetingsSummaryChart } from '../components/analytics/MeetingsSummaryChart';
import { AvgDurationChart } from '../components/analytics/AvgDurationChart';
import { PunctualityChart } from '../components/analytics/PunctualityChart';
import { MeetingsBreakdownChart } from '../components/analytics/MeetingsBreakdownChart';
import { fetchDemoStats, fetchStats } from '../analytics/actions';
import SEO from '../components/common/SEO';


function AnalyticsPage({ dispatch, user, stats }) {
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const [isLoading, setLoading] = useState(false);

  // fetch meeting stats if they are not available
  useEffect(() => {
    (async () => {
      // Note: after adding the translations, this useEffect will be triggered twice
      // don't know exactly why, but I've added this check to avoid the second call
      if (isMounted.current || isLoading) {
        return;
      }

      isMounted.current = true;
      if (Object.keys(stats).length === 0) {
        setLoading(true);

        if (user?.is_paid) {
          await dispatch(fetchStats());
        } else {
          await dispatch(fetchDemoStats());
        }

        setLoading(false);
      }
    })();
  }, [isLoading, user, stats, setLoading, dispatch]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center mt-10">
        <LoaderSVG className="animate-spin h-10 w-10 text-blue-600" aria-hidden="true" />
      </div>
    )
  }

  return (
    <>
      <SEO
        title="Analytics - Explore your meeting patterns from the previous days | MeetList.io"
      />
      <div className="pb-10">
        <h1 className="mb-2">
          <Trans t={t} i18nKey="analytics.title">
            Analytics
          </Trans>
        </h1>
        <p className="text-gray-500">
          <Trans t={t} i18nKey="analytics.subtitle">
            Explore your meeting patterns from the last 7 days.
          </Trans>
        </p>

        <Separator/>

        <div className="flex gap-4">
          <div className="grow basis-1/2 overflow-hidden">
            <MeetingsSummaryChart stats={stats} />
          </div>
          <Separator vertical className="grow-0 h-auto"/>
          <div className="grow basis-1/2 overflow-hidden">
            <AvgDurationChart
              stats={stats}
              isDemo={!user.is_paid}
            />
          </div>
        </div>

        <Separator/>

        <div className="flex gap-4">
          <div className="grow basis-1/2 overflow-hidden">
            <PunctualityChart
              stats={stats}
              isDemo={!user.is_paid}
            />
          </div>
          <Separator vertical className="grow-0 h-auto"/>
          <div className="grow basis-1/2 overflow-hidden">
            <MeetingsBreakdownChart
              stats={stats}
              isDemo={!user.is_paid}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default connect((state) => ({
  user: state.userReducer.user,
  stats: state.analyticsReducer.stats,
}))(AnalyticsPage);
