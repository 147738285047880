import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { range } from '../../misc/utils';


export const DOTS = 'dots';

/**
 * https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-pagination/use-pagination.ts
 *
 * @param total
 * @param siblings
 * @param boundaries
 * @param activePage
 * @returns {{previousPage: number, nextPage: number, paginationRange: unknown}}
 */
function usePagination({
  // Total amount of pages
  total,

  // Siblings amount on left/right side of selected page, defaults to 1
  siblings = 1,

  // Amount of elements visible on left/right edges, defaults to 1
  boundaries = 1,

  // Page selected on initial render, defaults to 1
  activePage = 1,
}) {
  const nextPage = activePage + 1;
  const previousPage = activePage - 1;
  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblings * 2 + 3 + boundaries * 2;
    if (totalPageNumbers >= total) {
      return range(1, total);
    }

    const leftSiblingIndex = Math.max(activePage - siblings, boundaries);
    const rightSiblingIndex = Math.min(activePage + siblings, total - boundaries);

    const shouldShowLeftDots = leftSiblingIndex > boundaries + 2;
    const shouldShowRightDots = rightSiblingIndex < total - (boundaries + 1);

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = siblings * 2 + boundaries + 2;
      return [...range(1, leftItemCount), DOTS, ...range(total - (boundaries - 1), total)];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = boundaries + 1 + 2 * siblings;
      return [...range(1, boundaries), DOTS, ...range(total - rightItemCount, total)];
    }

    return [
      ...range(1, boundaries),
      DOTS,
      ...range(leftSiblingIndex, rightSiblingIndex),
      DOTS,
      ...range(total - boundaries + 1, total),
    ];
  }, [total, siblings, boundaries, activePage]);

  return {
    paginationRange,
    nextPage,
    previousPage,
  }
}


const FooterPagination = ({ className, pageSize, total, activePage, search = '' }) => {
  const totalPages = Math.ceil(total / pageSize);
  const { paginationRange, nextPage, previousPage } = usePagination({
    total: totalPages,
    activePage,
  });

  const startIndex = (activePage - 1) * pageSize;
  const endIndex = Math.min(activePage * pageSize, total);
  return (
    <div className={`flex items-center justify-between border-gray-200 bg-white px-4 py-3 sm:px-6 ${className}`}>
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            <Trans i18nKey="meetings.pagination">
              Showing <b>{{ startIndex }}</b> to <b>{{ endIndex }}</b> of <b>{{ total }}</b> results
            </Trans>
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <Link
              to={`?page=${Math.max(1, previousPage)}${search ? `&search=${search}` : ''}`}
              className={`
                relative inline-flex items-center rounded-l-md border border-gray-300 bg-white
                px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20
                ${previousPage <= 0 && 'bg-gray-100 cursor-not-allowed'}
              `}
              style={{ pointerEvents: previousPage <= 0 ? 'none' : '' }}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
            {
              paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                  return (
                    <span
                      key={index}
                      className={`
                        relative inline-flex items-center border border-gray-300 bg-white px-4 py-2
                        text-sm font-medium text-gray-700 select-none
                      `}
                    >
                      ...
                    </span>
                  );
                }

                return (
                  <Link
                    key={index}
                    to={`?page=${ pageNumber }${search ? `&search=${search}` : ''}`}
                    aria-current="page"
                    className={`
                      relative inline-flex items-center bg-white px-4 py-2 text-sm select-none
                      font-medium text-gray-500 border hover:bg-gray-50 z-10 focus:z-20
                      ${ activePage === pageNumber ? 'z-20 border-indigo-500 bg-indigo-50 text-indigo-600' : 'border-gray-300'}
                    `}
                  >
                    { pageNumber }
                  </Link>
                )
              })
            }

            <Link
              to={`?page=${Math.min(totalPages, nextPage)}${search ? `&search=${search}` : ''}`}
              style={{ pointerEvents: nextPage > totalPages ? 'none' : '' }}
              className={`
                relative inline-flex items-center rounded-r-md border border-gray-300 bg-white
                px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20
                ${nextPage > totalPages && 'bg-gray-100 cursor-not-allowed'}
              `}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default FooterPagination;
