import React from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  AcademicCapIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  MegaphoneIcon
} from '@heroicons/react/24/outline';

import { ReactComponent as LogoSVG } from '../../assets/imgs/logo.svg';
import { FEEDBACK_URL } from '../../config';
import { getExtVersion } from '../../misc/ext';
import AdSlot from '../AdSlot';


function LocationAwareLink({ children, to, className, ...props }) {
  const location = useLocation();
  const active = location.pathname.startsWith(to);

  return (
    <div>
      <Link
        to={to}
        className={clsx(
          'flex items-center px-7 py-3 rounded text-gray-300',
          'transition ease-in-out duration-300',
          'hover:bg-white/10',
          active ? 'bg-white/10' : '',
          className,
        )}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}

const Sidebar = () => {
  const hasExt = getExtVersion();
  return (
    <div
      className="h-full min-h-screen bg-meet-black text-white fixed left-0 top-0 px-3 py-5 z-20 print:hidden"
      style={{ width: 263 }}
    >
      <div className="text-center">
        <Link to="/meetings" className="cursor-pointer">
          <LogoSVG className="mx-auto" style={{ height: 48 }} />
        </Link>
      </div>
      <nav className="mt-16">
        <LocationAwareLink to="/meetings">
          <AcademicCapIcon className="w-5 h-5" aria-hidden="true" />
          <span className="font-bold ml-3">
            <Trans i18nKey="sidebar.meetings">
              Meetings
            </Trans>
          </span>
        </LocationAwareLink>
        <LocationAwareLink to="/stats" className="mt-2">
          <ChartBarIcon className="w-5 h-5" aria-hidden="true" />
          <span className="font-bold ml-3">
            <Trans i18nKey="sidebar.analytics">
              Analytics
            </Trans>
          </span>
          {/*<span className="relative flex h-2 w-2 ml-2 mb-2">*/}
          {/*  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"/>*/}
          {/*  <span className="relative inline-flex rounded-full h-2 w-2 bg-sky-500"/>*/}
          {/*</span>*/}
        </LocationAwareLink>
        <LocationAwareLink to="/billing" className="mt-2">
          <CreditCardIcon className="w-5 h-5" aria-hidden="true" />
          <span className="font-bold ml-3">
            <Trans i18nKey="sidebar.billing">
              Billing
            </Trans>
          </span>
        </LocationAwareLink>
        {
          hasExt ? (
            <LocationAwareLink to="/preferences" className="mt-2">
              <Cog6ToothIcon className="w-5 h-5" aria-hidden="true" />
              <span className="font-bold ml-3">
                <Trans i18nKey="sidebar.preferences">
                  Preferences
                </Trans>
              </span>
            </LocationAwareLink>
          ) : null
        }
        <a
          href={FEEDBACK_URL}
          target="_blank"
          rel="noreferrer"
          className={clsx(
            'flex items-center px-7 py-3 rounded text-gray-300',
            'transition ease-in-out duration-300',
            'hover:bg-white hover:bg-opacity-10 mt-2',
          )}
        >
          <MegaphoneIcon className="w-5 h-5" aria-hidden="true" />
          <span className="font-bold ml-3">
            <Trans i18nKey="sidebar.feedback">
              Feedback
            </Trans>
          </span>
        </a>
      </nav>

      <AdSlot
        zone="sidebar"
        className="bottom-5"
        imageClassName="w-16 h-16"
      />
    </div>
  );
}

export default Sidebar;
