import React from 'react';


/**
 *
 * @param {number} x - x position
 * @param {number} y - y position
 * @param {{ value: number }} payload - payload
 * @param {Function} formatter - Function to format the value
 * @returns {Element}
 * @constructor
 */
export const CustomAxisTick = ({ x, y, payload, formatter = null }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} textAnchor="start" fill="#747a88" fontSize="10">
        { formatter ? formatter(payload.value) : payload.value }
      </text>
    </g>
  );
};
