import React from 'react';
import { Trans } from 'react-i18next';
import { ChartHeader, CustomComposedChart } from './shared';
import { getTimeString } from '../../misc/utils';
import { tickFormatter } from './shared/helpers';
import AvgDurationChartPNG from '../../assets/imgs/stats-avg-duration-demo.png';


export function AvgDurationChart({ stats, isDemo = false }) {
  const chartDataset = stats?.avg_duration;
  const formatter = (value) => getTimeString(value * 1000);
  const labelFormatter = (label) => <Trans i18nKey={`analytics.charts.avg_duration.${label}`} />

  return (
    <>
      <ChartHeader>
        <ChartHeader.Title
          i18nKey="analytics.charts.avg_duration.title"
          defaultText="Avg. meeting duration and time in call"
        >
          <p className="text-xs">
            <Trans i18nKey="analytics.common.time_format">
              Time is shown as hours (hh), minutes (mm), and seconds (ss).
            </Trans>
          </p>
          <ul className="list-disc pl-3">
            <li className="mt-1">
              <b>
                <Trans i18nKey="analytics.charts.avg_duration.avg_duration" />
              </b> - <Trans i18nKey="analytics.charts.avg_duration.avg_duration_description" />
            </li>
            <li className="mt-1">
              <b>
                <Trans i18nKey="analytics.charts.avg_duration.avg_time_in_call" />
              </b> - <Trans i18nKey="analytics.charts.avg_duration.avg_time_in_call_description" />
            </li>
          </ul>
        </ChartHeader.Title>

        <ChartHeader.CurrentValue
          isDemo={isDemo}
          value={formatter(chartDataset?.avg_duration)}
          // growthPercentage={20}
        />
      </ChartHeader>

      <CustomComposedChart
        className="mt-3"
        chartDataset={chartDataset}
        formatter={formatter}
        labelFormatter={labelFormatter}
        tickFormatter={tickFormatter}
        showGrid={false}
        demoImageSrc={isDemo ? AvgDurationChartPNG : null}
      />
    </>
  );
}
