import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import ToggleButton from './ToggleButton';
import { DEFAULT_INTERVAL } from '../../config';
import * as storage from '../../misc/storage';


export default function PriceIntervalSelection({ onChange }) {
  const [interval, setInterval] = useState(DEFAULT_INTERVAL);

  useEffect(() => {
    // Reads the plan interval from cookie. If none, use default.
    const newInterval = storage.getItem('plan_interval') || DEFAULT_INTERVAL;
    setInterval(newInterval);

    // notify to update the Pro plan
    onChange?.(newInterval);
  }, [setInterval, onChange]);

  const changeInterval = (checked) => {
    const newInterval = checked ? 'yearly' : 'monthly';
    storage.setItem('plan_interval', newInterval);
    setInterval(newInterval);

    // notify to update the Pro plan
    onChange?.(newInterval);
  }

  return (
    <div className="flex items-center justify-center mb-10">
      <span className="mr-3">
        <Trans i18nKey="billing_plan.monthly">
          Monthly
        </Trans>
      </span>
      <ToggleButton
        checked={interval === 'yearly'}
        defaultClassName="bg-gray-500/20 ring-slate-300"
        onChange={changeInterval}
      />
      <span className="ml-3">
        <Trans i18nKey="billing_plan.yearly">
          Yearly
        </Trans>
        <span className="ml-3 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          <Trans i18nKey="billing_plan.yearly_discount">
            2 months OFF
          </Trans>
        </span>
      </span>
    </div>
  );
}
