import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';


export default function Separator({ vertical = false, className = '' }) {
  return (
    <div
      className={clsx(
        'bg-gray-200',
        vertical ? 'w-[1px] h-10 mx-5' : 'h-[1px] w-full my-5',
        className,
      )}
    />
  );
}

Separator.propTypes = {
  vertical: PropTypes.bool,
  className: PropTypes.string,
};
