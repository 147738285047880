import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { isProd } from '../config';
import userReducer from '../users/userReducer';
import meetingsReducer from '../meetings/meetingsReducer';
import analyticsReducer from '../analytics/analyticsReducer';


export const store = configureStore({
  middleware: (getDefaultMiddleware) => isProd ? getDefaultMiddleware() : getDefaultMiddleware().concat(logger),
  devTools: !isProd,
  reducer: {
    userReducer,
    meetingsReducer,
    analyticsReducer,
  },
});
