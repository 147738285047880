import { createReducer } from '@reduxjs/toolkit';
import { SET_STATS } from './actionTypes';

const initialState = {
  status: 'idle',
  stats: {},
};

const analyticsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_STATS, (state, action) => {
      state.stats = action.payload;
    });
});

export default analyticsReducer;
