import React from 'react';

import { ReactComponent as LoaderSVG } from '../../assets/imgs/loader.svg';

const Button = React.forwardRef(({ className, disabled = false, isProcessing = false, children, ...props }, ref) => {
  return (
    <button
      className={`flex items-center ${disabled || isProcessing ? 'opacity-75 cursor-not-allowed' : ''} ${className}`}
      disabled={disabled || isProcessing}
      {...props}
    >
      { isProcessing && <LoaderSVG className="animate-spin -ml-1 mr-3 h-5 w-5" aria-hidden="true" /> }
      { children }
    </button>
  );
});

export default Button;
