import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from "@sentry/react";

// import i18n (needs to be bundled ;))
import './i18n';

import App from './App';
import { store } from './store';
import { isProd, SENTRY_DSN } from './config';
import { ConfirmDialogProvider } from './components/common/ConfirmDialog';
import MiddleLoader from './components/common/MiddleLoader';


if (isProd) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [],

    // Configures the sample rate for error events, in the range of 0.0 to 1.0. The default is 1.0 which
    // means that 100% of error events are sent. If set to 0.1 only 10% of error events will be sent.
    // Events are picked randomly.
    sampleRate: 0.1,

    /**
     * This function will be called for every event before sending it to Sentry.
     * Docs: https://docs.sentry.io/platforms/javascript/configuration/filtering/#event-hints
     *
     * @param event - The error event to be sent to Sentry.
     * @param hint - May contain additional information about the original exception.
     * @returns {ErrorEvent|null}
     */
    beforeSend: (event, hint) => {
      const errorStack = hint?.originalException?.stack;

      // ignore errors from gtag
      if (/gtag/.test(errorStack)) {
        return null;
      }

      return event;
    },
  });
}


// Wrapped app rendering, so we can wait for the API to initialize
(async function() {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <React.Suspense fallback={<MiddleLoader />}>
        <Provider store={ store }>
          <HelmetProvider>
            <ConfirmDialogProvider>
              <App />
            </ConfirmDialogProvider>
          </HelmetProvider>
        </Provider>
      </React.Suspense>
    </React.StrictMode>
  );
})();
