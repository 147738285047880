import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PriceIntervalSelection from './common/PriceIntervalSelection';
import { FreeCard, PremiumCard } from './common/PricingCards';
import { CHROME_STORE_URL, DEFAULT_INTERVAL } from '../config';


export default function Pricing() {
  const [interval, setInterval] = useState(DEFAULT_INTERVAL);

  return (
    <section aria-label="Pricing" className="bg-slate-900 py-16">
      <div className="container mx-auto text-white">
        <h2 className="text-3xl text-center mt-5 mb-2">
          Simple pricing, for everyone
        </h2>
        <p className="text-center text-gray-300 mb-20">
          Choose a plan tailored to your needs.
        </p>

        <PriceIntervalSelection
          onChange={(newInterval) => setInterval(newInterval)}
        />

        <div className="flex flex-wrap-reverse lg:flex-wrap gap-10 justify-center">
          <FreeCard className="text-white">
            <a
              href={CHROME_STORE_URL}
              target="_blank"
              rel="noreferrer"
              className="primary w-full text-center inline-block px-10 py-3 border border-white bg-transparent text-white hover:bg-gray-700 text-lg mt-10"
            >
              Choose plan
            </a>
          </FreeCard>
          <PremiumCard
            className="bg-blue-600 text-white"
            interval={interval}
          >
            <Link
              to={`/signup?next=${encodeURIComponent('/billing')}`}
              className="primary w-full text-center inline-block px-10 py-3 bg-white text-blue-600 text-lg mt-10"
            >
              Choose plan
            </Link>
          </PremiumCard>
        </div>

        <div className="text-center text-gray-300 mt-5">
          * VAT may apply to the listed prices.
        </div>
      </div>
    </section>
  );
}
