import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AcademicCapIcon } from '@heroicons/react/16/solid';
import ProFeatureAction from './common/ProFeatureAction';


/**
 *
 * @param {number} totalMeetings - Total number of meetings ready for backup
 * @returns {Element}
 * @constructor
 */
export default function MeetingsBackupBanner({ totalMeetings }) {
  const navigate = useNavigate();

  return (
    <div className="flex gap-2 text-sm text-blue-800 bg-blue-50 rounded-lg p-4 mb-7">
      <AcademicCapIcon className="w-5 h-5" aria-hidden="true"/>
      <div>
        <span className="font-medium">
          <Trans i18nKey="banner.backup.title">
            Meetings ready for backup!
          </Trans>
        </span>

        <ul className="mt-1.5 list-disc list-inside">
          <li>
            <Trans i18nKey="banner.backup.line_1">
              We've identified <b>{{ totalMeetings }} meetings</b> stored in your Google Meet Attendance List
              browser extension.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="banner.backup.line_2">
              Secure your meeting records from potential loss by backing them up to the cloud.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="banner.backup.line_3">
              Understand that meetings not backed up <span className="text-red-500">may be lost</span>, if the
              extension or browser, is uninstalled.
            </Trans>
          </li>
        </ul>

        <ProFeatureAction
          as="button"
          className="btn-primary mt-5"
          onClick={() => navigate('/meetings/import')}
        >
          <Trans i18nKey="banner.backup.cta_button">Backup now!</Trans> <ProFeatureAction.Badge />
        </ProFeatureAction>
      </div>
    </div>
  );
}
