import React from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { UserIcon } from '@heroicons/react/24/solid';
import { getTimeString } from '../misc/utils';
import { getMeetingDisplayName } from '../meetings/actions';


const MeetingRow = ({ settings, meetingDetails, activePage, searchText, isSelected = false, onSelected }) => {
  const navigate = useNavigate();
  const meetingName = getMeetingDisplayName(meetingDetails);

  const endedAt = new Date(meetingDetails.ended_at || Date.now()).getTime();
  const createdAt = new Date(meetingDetails.created_at).getTime();
  const meetingDuration = endedAt - createdAt;

  return (
    <>
      <tr
        className={clsx(
          'cursor-pointer transition-colors duration-300 ease-in-out hover:bg-slate-100',
          isSelected && 'bg-slate-100',
        )}
        data-local-key={meetingDetails.local_key}
        onClick={() => {
          navigate(`/meetings/${meetingDetails.local_key}`, {
            state: {
              meetingsPageNumber: activePage,
              searchText: searchText,
            }
          });
        }}
      >
        <td
          className="cursor-default"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            type="checkbox"
            className="inline-block align-middle mx-2 my-2 w-4 h-4 rounded focus:ring-0 checked:bg-blue-500"
            checked={isSelected}
            onChange={(e) => {
              e.stopPropagation();
              onSelected(e.target.checked, meetingDetails.local_key);
            }}
          />
        </td>
        <td
          className="px-6 py-4 text-sm text-black w-1/3 max-w-xs">
          { meetingName }
          {
            !meetingDetails.is_auto &&
            <span
              className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-200 text-orange-800">
              <Trans i18nKey="meetings.tags.manual">
                Manual
              </Trans>
            </span>
          }
          {
            !meetingDetails.ended_at &&
            <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              <Trans i18nKey="meetings.tags.in_progress">
                In progress
              </Trans>
            </span>
          }
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            { moment(meetingDetails.created_at).format('ll') }
          </div>
          <div className="text-sm text-gray-500">
            { moment(meetingDetails.created_at).format(settings.time_format) }
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-gray-500">
          <UserIcon className="w-5 h-5 inline-block align-middle" aria-hidden="true" />
          <span className="ml-1">
            { meetingDetails.participants.length }
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-gray-500">
          { meetingDetails.is_auto ? getTimeString(meetingDuration) : '' }
        </td>
        <td
          className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <button className="btn-flat">
              <Trans i18nKey="meetings.view_participants">
                View participants
              </Trans>
            </button>
          </div>
        </td>
      </tr>
    </>
  )
};

export default MeetingRow;
